import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import { examinationsAtom } from '../recoil/examinations.recoil';
import useExamsData from '../hooks/useExamsData.hook';
import useStatusData from '../hooks/useStatusData.hook';
import useStoredResponses from '../hooks/useStoredResponses.hook';

const RESUBMIT_INTERVAL = 30000;

const ResponsesSubscriber = ({ examId }) => {
    const userToken = useRecoilValue(userTokenAtom);
    const { setExam } = useExamsData(examId);
    const { getStatus } = useStatusData(examId);
    const { getStoredResponses, removeStoredResponses } = useStoredResponses(examId);
    const responses = getStoredResponses();

    const resubmit = async interval => {
        const result = await setExam(JSON.parse(responses));
        if (result.success) {
            removeStoredResponses();
            clearInterval(interval);
            getStatus();
        }
    };
    
    useEffect(() => {
        let interval = null;
        if (userToken && responses)
            interval = setInterval(() => resubmit(interval), RESUBMIT_INTERVAL);
        return () => clearInterval(interval);
    }, [examId, userToken, responses]);
    
    return null;
};

const Subscribers = () => {
    return useRecoilValue(examinationsAtom).map(({ id }) => 
        <ResponsesSubscriber key={`ResponsesSubscriber/${id}`} examId={id} />);
};

export default Subscribers;