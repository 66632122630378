import React, { useReducer, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userDataAtom } from '../recoil/user.recoil';
import formReducer from '../reducers/form.reducer';
import useContact from '../hooks/useContact.hook';
import { Text, TextArea, TrainingProviderSelect } from './forms.component';
import { FormError } from '../styled/forms.styled';
import { ButtonNext } from './button.component';

const initialState = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    trainingProvider: '',
    candidateNumber: '',
    body: '',
    errors: {},
};
  
const ContactForm = () => {
    const [ state, dispatch ] = useReducer(formReducer, initialState);
    const [ section, setSection ] = useState('form');
    const userData = useRecoilValue(userDataAtom);
    const { contact } = useContact();
    const formStyle = { display: section === 'form' ? 'block' : 'none' };
    const successStyle = { display: section === 'success' ? 'block' : 'none' };
    const changeHandler = e => dispatch({ type: 'update', payload: e.target });
    const submitHandler = async e => {
        e.preventDefault();
        const { email, firstName, lastName, phoneNumber, trainingProvider, candidateNumber, body } = state;
        const errors = {};
        if (email === '')
            errors['email'] = 'Please enter your email address';
        if (firstName === '')
            errors['firstName'] = 'Please enter your first name';
        if (lastName === '')
            errors['lastName'] = 'Please enter your last name';
        if (trainingProvider === '')
            errors['trainingProvider'] = 'Please choose a training provider';
        if (body === '')
            errors['body'] = 'Please enter a message';
        if (Object.keys(errors).length) {
            dispatch({ type: 'setErrors', payload: errors });
        } else {
            const result = await contact({ email, firstName, lastName, phoneNumber, trainingProvider, candidateNumber, message: body });
            if (!result.success) {
                errors['message'] = result.message;
                dispatch({ type: 'setErrors', payload: errors });
            } else {
                dispatch({ type: 'reset', payload: initialState });
                setSection('success');
            }
        }
    };
    useEffect(() => {
        if (userData !== null) {
            const { email, firstName, lastName, phoneNumber, trainingProvider, candidateNumber } = userData;
            dispatch({ type: 'replace', payload: { ...initialState, email, firstName, lastName, phoneNumber, trainingProvider, candidateNumber } });
        }
        return () => dispatch({ type: 'reset', payload: initialState });
    }, [dispatch, userData]);
    return <>
        <form style={formStyle} onSubmit={submitHandler}>
            {state.errors.message && <FormError>{state.errors.message}</FormError>}
            <Text name='email' error={state.errors.email} value={state.email} type='email' label='Email' placeholder='Email address' onChange={changeHandler} />
            <Text name='firstName' error={state.errors.firstName} value={state.firstName} type='text' label='First name' placeholder='First name' onChange={changeHandler} />
            <Text name='lastName' error={state.errors.lastName} value={state.lastName} type='text' label='Last name' placeholder='Last name' onChange={changeHandler} />
            <Text name='phoneNumber' error={state.errors.phoneNumber} value={state.phoneNumber} type='text' label='Phone number' placeholder='Phone number' onChange={changeHandler} />
            <TrainingProviderSelect name='trainingProvider' error={state.errors.trainingProvider} value={state.trainingProvider} onChange={changeHandler} />
            <Text name='candidateNumber' error={state.errors.candidateNumber} value={state.candidateNumber} type='text' label='RSPH Candidate Number' placeholder='RSPH Candidate Number' onChange={changeHandler} />
            <TextArea name='body' error={state.errors.body} value={state.body} type='text' label='Message' placeholder='Message' onChange={changeHandler} />
            <ButtonNext type='submit'>Send Message</ButtonNext>
        </form>
        <div style={successStyle}>
            <p>Thanks for getting in touch. We will respond to your query as soon as possible.</p>
        </div>
    </>
};

export default ContactForm;