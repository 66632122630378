import { API_URL } from '../config/constants.config';

const useContact = data => {
    const contact = async data => {
        const ref = await fetch(`${API_URL}/contact/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify(data),
        });
        if (ref.status !== 200)
            return { message: 'There was a problem sending your message. Please try again.' };
        else
            return { success: true };
    };

    return { contact };
};

export default useContact;