import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Button from '../components/button.component';
import UnlockWithCode from '../components/unlockWithCode.component';
import UnlockWithStripe from '../components/unlockWithStripe.component';
import { getExaminationTitleSelectorFamily } from '../recoil/examinations.recoil';
import { Columns50_50 } from '../styled/global.styled';
import useStatusData from '../hooks/useStatusData.hook';

const CodeSuccess = () => {
    return <>
        <h3>Unlock Successful</h3>
        <p>Your unlock code was recognised and the examination has been unlocked.</p>
        <p><Button as={Link} to="/examinations">Continue</Button></p>
    </>
};
  
const StripeSuccess = () => {
    const { examId } = useParams();
    const title = useRecoilValue(getExaminationTitleSelectorFamily(examId));
    const { getStatus } = useStatusData(examId);

    useEffect(() => {
        getStatus();
    }, []);

    return <>
        <h2>Examination Unlock Successful</h2>
        <p>Your payment via Stripe was completed successfully. Thank you for your purchase.</p>
        <p>The {title} examination is now unlocked.</p>
        <p><Button as={Link} to="/examinations">Continue</Button></p>
    </>
};

const StripeCancel = () => {
    return <>
        <h2>Examination Unlock Cancelled</h2>
        <p>Your transaction was cancelled.</p>
        <p><Button as={Link} to={`/examinations`}>Continue</Button></p>
    </>
};

const IsHome = ({ children }) => {
    const { unlockType } = useParams();
    if (!unlockType)
        return children;
    else
        return null;
}

const IsCodeResponse = ({ children }) => {
    const { unlockType } = useParams();
    if (unlockType === 'code')
        return children;
    else
        return null;
}

const IsStripeResponse = ({ children }) => {
    const { unlockType } = useParams();
    if (unlockType === 'stripe')
        return children;
    else
        return null;
}

const IsStripeSuccess = ({ children }) => {
    const { status } = useParams();
    if (status === 'success')
        return children;
    else
        return null;
}

const IsStripeCancel = ({ children }) => {
    const { status } = useParams();
    if (status === 'cancel')
        return children;
    else
        return null;
}

const Unlock = () => {
    const { examId } = useParams();
    const title = useRecoilValue(getExaminationTitleSelectorFamily(examId));
    return <>
        <IsHome>
            <h2>Unlock {title}</h2>
            <Columns50_50>
                <UnlockWithCode />
                <UnlockWithStripe />
            </Columns50_50>
        </IsHome>
        <IsCodeResponse>
            <CodeSuccess />
        </IsCodeResponse>
        <IsStripeResponse>
            <IsStripeSuccess>
                <StripeSuccess />
            </IsStripeSuccess>
            <IsStripeCancel>
                <StripeCancel />
            </IsStripeCancel>
        </IsStripeResponse>
    </>
};

export default Unlock;