import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../styled/global.styled';
  
const LoadingPlaceholder = () => {
    return <Loading>Loading...</Loading>
};

export const LoadingIcon = ({ align = 'left', children }) => {
    return <>{align === 'left' && <FontAwesomeIcon spin icon={faCircleNotch} />} {children} {align === 'right' && <FontAwesomeIcon spin icon={faCircleNotch} />}</>
};

export const LoadingMain = () => {
    return <p><LoadingIcon>Loading main...</LoadingIcon></p>
};

export const LoadingAssessments = () => {
    return <p><LoadingIcon>Loading assessments...</LoadingIcon></p>
};

export const LoadingExaminations = () => {
    return <p><LoadingIcon>Loading examinations...</LoadingIcon></p>
};

export default LoadingPlaceholder;