import { useParams } from 'react-router-dom';

const useExamId = examId => {
    const params = useParams();
    let id = examId;
    if (!id) {
        const { examId: matchExamId } = params;
        id = matchExamId;
    }
    return id;
};

export default useExamId;