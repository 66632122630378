import React from 'react';
import { Switch } from 'react-router-dom';
import { Header, Footer, Main, Navigation } from './components/layout.component';
import { LoadingMain } from './components/loading.component';
import Routes from './components/routes.component';
import UserSubscriber from './subscribers/user.subscriber';
import AssessmentsSubscriber from './subscribers/assessments.subscriber';
import SectionsSubscriber from './subscribers/sections.subscriber';
import ExaminationsSubscriber from './subscribers/examinations.subscriber';
import ProgressSubscribers from './subscribers/progress.subscriber';
import StatusSubscribers from './subscribers/status.subscriber';
import ResponsesSubscribers from './subscribers/responses.subscriber';
import { IsAuthenticated, IsUserLoading, IsNotUserLoading} from './components/conditionals.component';

import './App.scss';

const App = () => {
  return <>
    <UserSubscriber />
    <Header />
    <Navigation />
    <Main>
      <IsUserLoading>
        <LoadingMain />
      </IsUserLoading>
      <IsNotUserLoading>
        <IsAuthenticated>
          <AssessmentsSubscriber />
          <SectionsSubscriber />
          <ProgressSubscribers />
          <ExaminationsSubscriber />
          <StatusSubscribers />
          <ResponsesSubscribers />
        </IsAuthenticated>
        <Switch>
          <Routes />
        </Switch>
      </IsNotUserLoading>
    </Main>
    <Footer />
  </>
};

export default App;