import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyBX3uppkSo3L9VvN1AkZUsuh_fp80FW8tI",
    authDomain: "saveface-8defb.firebaseapp.com",
    databaseURL: "https://saveface-8defb.firebaseio.com",
    projectId: "saveface-8defb",
    storageBucket: "saveface-8defb.appspot.com",
    messagingSenderId: "126575710198",
    appId: "1:126575710198:web:d2a2337c06d47eb07681d7",
    measurementId: "G-48MTPNX24Q",
};

export const createUserProfileDocument = async( userAuth, additionalData ) => {
    if (!userAuth) return;

    const userRef = firestore.doc(`userProfiles/${userAuth.uid}`);

    const snapShot = await userRef.get();

    if (!snapShot.exists) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData,
            });
        } catch(error) {
            console.error( "error creating user", error.message );
        }
    }

    return userRef;
};

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth);
        }, reject);
    });
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

// const provider = new firebase.auth.GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });
// export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;