import styled from 'styled-components';
import { SECTION_IMAGE_1, SECTION_IMAGE_2, SECTION_IMAGE_3, COLORS } from '../config/constants.config';

export const defaultTheme = {
    container: `
    color: rgb(${COLORS.text.r}, ${COLORS.text.g}, ${COLORS.text.b});
    background-color: rgba(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b}, 0.4);
    background-image: url(${SECTION_IMAGE_3});
    background-size: stretch;
    background-repeat: no-repeat;`,
    a: `
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
        color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
        background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
    }`,
    h1: `
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b}); background-color: rgb(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b});`,
    div: `
    background-color: rgba(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b}, 0.8);
    background: linear-gradient(180deg, 
        rgba(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b},0.8) 0px, 
        rgba(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b},0.8) 400px, 
        rgba(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b},1) 500px, 
        rgba(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b},1) 100%);`,
    list: ``,
    dates: ``,
    datesHover: ``,
    additional: ``,
};

export const assessmentTheme = {
    container: `
    color: rgb(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b});
    background-color: rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b}, 0.8);
    background-image: url(${SECTION_IMAGE_1});
    background-size: stretch;
    background-repeat: no-repeat;`,
    a: `
    color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
        color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
        background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
    }`,
    h1: `
    color: white;
    background-color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});`,
    div: `
    background-color: rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b}, 0.8);
    background: linear-gradient(180deg, 
        rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b},0.8) 0px, 
        rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b},0.8) 400px, 
        rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b},1) 500px, 
        rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b},1) 100%);`,
    list: `
    color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
    background-color: rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b}, 0.8);
    &:hover,
    &:focus,
    &:active {
        color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
        background-color: rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b}, 0.8);
    }
    `,
    dates: `color: #ccc;`,
    datesHover: `color: #444;`,
    additional: `
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: rgba(255,255,255,0.8);
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
        color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
        background-color: rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b}, 0.8);
    }
    `,
};

export const examinationTheme = {
    container: `
    color: rgb(${COLORS.text.r}, ${COLORS.text.g}, ${COLORS.text.b});
    background-color: rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b}, 0.4);
    background-image: url(${SECTION_IMAGE_2});
    background-size: stretch;
    background-repeat: no-repeat;`,
    a: `
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
        color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
        background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
    }`,
    h1: `
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});`,
    div: `
    background-color: rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b}, 0.6);
    background: linear-gradient(180deg, 
        rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b},0.6) 0px, 
        rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b},0.6) 400px, 
        rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b},1) 500px, 
        rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b},1) 100%);`,
    list: `
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b}, 0.9);
    &:hover,
    &:focus,
    &:active {
        color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
        background-color: rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b}, 0.8);
    }
    `,
    dates: `color: #444;`,
    datesHover: `color: #ccc;`,
    additional: `
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: rgba(255,255,255,0.4);
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
        color: rgb(${COLORS.primaryContrast.r}, ${COLORS.primaryContrast.g}, ${COLORS.primaryContrast.b});
        background-color: rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b}, 0.8);
    }
    `,
};

export const Container = styled.div`
margin-bottom: 20px;
${props => props.theme.container}
a {
    ${props => props.theme.a}
}
> h1 {
    ${props => props.theme.h1}
}
> div {
    ${props => props.theme.div}
}
`;