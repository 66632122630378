import React from 'react';
import { useRecoilValue } from 'recoil';
import { LoadingIcon } from '../components/loading.component';
import { IsStatusLoading, IsNotStatusLoading, IsNotExaminationUnavailable } from '../components/conditionals.component';
import { getSubmittedExaminationsSelector, statusAtomFamily, getExaminationSelectorFamily } from '../recoil/examinations.recoil';
import { Table } from '../styled/global.styled';
import { formatDate } from '../utils/date.utils';


export const ResultsTable = () => {
    const examinations = useRecoilValue(getSubmittedExaminationsSelector);
    return <Table>
      <tbody>
        {examinations.map(({ id }) => 
          <TableRow key={`SubmissionsTable/${id}`} examId={id} />)}
      </tbody>
    </Table>
  };

const TableRow = ({ examId }) => {
    const examination = useRecoilValue(getExaminationSelectorFamily(examId));
    const title = examination.qualificationTitle ? examination.qualificationTitle : examination.title;
    return <IsNotExaminationUnavailable examId={examId}>
      <tr>
          <th>{title}</th>
          <td>
            <IsStatusLoading examId={examId}>
              <LoadingIcon>fetching...</LoadingIcon>
            </IsStatusLoading>
            <IsNotStatusLoading examId={examId}>
              <RowStatus examId={examId} />
            </IsNotStatusLoading>
          </td>
      </tr>
    </IsNotExaminationUnavailable>
  };

const RowStatus = ({ examId }) => {
    const status = useRecoilValue(statusAtomFamily(examId));
    if (status && status.statusText)
      return <>
        Submitted: {formatDate(status.passedDate)}
      </>
    else
      return null;
  };