import React, { useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';
import formReducer from '../reducers/form.reducer';
import useAuthentication from '../hooks/useAuthentication.hook';
import Button from './button.component';
import { Text } from './forms.component';
import { PageHeading } from '../styled/global.styled';
import { FormError } from '../styled/forms.styled';

const initialState = {
    email: '',
    password: '',
    errors: {},
};
  
const SignInForm = () => {
    const [state, dispatch] = useReducer(formReducer, initialState);
    const { emailSignIn } = useAuthentication();
    const changeHandler = e => dispatch({ type: 'update', payload: e.target });
    const submitHandler = async e => {
      e.preventDefault();
      const { email, password } = state;
      const errors = {};
      if (email === '')
        errors['email'] = 'Please enter your email address';
      if (password === '')
        errors['password'] = 'Please enter a password';
      if (Object.keys(errors).length) {
        dispatch({ type: 'setErrors', payload: errors });
      } else {
        const result = await emailSignIn({ email, password });
        if (!result.success) {
          errors['message'] = result.message;
          dispatch({ type: 'setErrors', payload: errors });
        }
      }
    };
    useEffect(() => () => dispatch({ type: 'reset', payload: initialState }), [dispatch]);
    return <>
      <PageHeading>Sign In or Sign Up</PageHeading>
      <form onSubmit={submitHandler}>
        {state.errors.message && <FormError>{state.errors.message}</FormError>}
        <Text name='email' error={state.errors.email} value={state.email} type='email' label='Email' placeholder='Email address' onChange={changeHandler} />
        <Text name='password' error={state.errors.password} value={state.password} type='password' label='Password' placeholder='Password' onChange={changeHandler} />
        <Button type='submit'>Sign In</Button>
        <p><Link to="/reset-password">I can't remember my password</Link></p>
        <p><Link to="/sign-up">I do not have an account</Link></p>
      </form>
    </>
};

export default SignInForm;