import React, { useReducer, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import formReducer from '../reducers/form.reducer';
import useAuthentication from '../hooks/useAuthentication.hook';
import { getUserCandidateNumberSelector } from '../recoil/user.recoil';
import Button from './button.component';
import { Text, Checkbox } from './forms.component';
import { FormError } from '../styled/forms.styled';
import { LoadingIcon } from '../components/loading.component';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { USER_UPDATING, EXAM_FETCHING } from '../config/loading.config';
import { IsCandidateNumber, IsNotCandidateNumber } from './conditionals.component';
import useExamsData from '../hooks/useExamsData.hook';

const initialState = {
    candidateNumber: '',
    acceptExamTerms: false,
    errors: {},
};
  
const CandidateNumberForm = () => {
  const history = useHistory();
  const { examId } = useParams();
  const [state, dispatch] = useReducer(formReducer, initialState);
  const candidateNumber = useRecoilValue(getUserCandidateNumberSelector);
  const userUpdating = useRecoilValue(loadingAtomFamily(USER_UPDATING));
  const examLoading = useRecoilValue(loadingAtomFamily(EXAM_FETCHING));
  const { setCandidateNumber } = useAuthentication();
  const { getExam } = useExamsData();
  const disabled = userUpdating || examLoading ? true : false;
  const changeHandler = e => dispatch({ type: 'update', payload: e.target });
  const submitHandler = async e => {
    e.preventDefault();
    const { candidateNumber, acceptExamTerms } = state;
    const errors = {};
    if (candidateNumber === '')
      errors['candidateNumber'] = 'Please enter your RSPH Candidate Number';
    else if (candidateNumber.length < 7 || candidateNumber.length > 7)
      errors['candidateNumber'] = 'RSPH Candidate Numbers are 7 digits long - please check you have entered your number correctly';
    else if (!candidateNumber.match(/^\d+$/g))
      errors['candidateNumber'] = 'RSPH Candidate Numbers only contain numbers - please check you have entered your number correctly';
    if (acceptExamTerms === false)
      errors['acceptExamTerms'] = 'Please indicate that you accept the examination terms';
    if (Object.keys(errors).length) {
      dispatch({ type: 'setErrors', payload: errors });
    } else {
      const result = await setCandidateNumber(candidateNumber);
      if (!result.success) {
        errors['message'] = result.message;
        dispatch({ type: 'setErrors', payload: errors });
      } else {
        const examResult = await getExam();
        if (!examResult.success) {
          errors['message'] = examResult.message;
          dispatch({ type: 'setErrors', payload: errors });
        } else {
          window.scrollTo(0, 0);
          history.push(`/examinations/exam/${examId}/1`);
        }
      }
    }
  };
  useEffect(() => () => dispatch({ type: 'reset', payload: initialState }), [dispatch]);
  useEffect(() => {
    dispatch({ type: 'update', payload: { type: 'text', name: 'candidateNumber', value: candidateNumber } });
  }, [candidateNumber]);
  return <>
    <form onSubmit={submitHandler}>
      {state.errors.message && <FormError>{state.errors.message}</FormError>}
      <IsCandidateNumber>
        <Text name='candidateNumber' disabled={true} error={state.errors.candidateNumber} value={state.candidateNumber} type='text' label='RSPH Candidate Number' placeholder='RSPH Candidate Number' />
      </IsCandidateNumber>
      <IsNotCandidateNumber>
        <h3>Please enter your RSPH Candidate Number</h3>
        <Text name='candidateNumber' error={state.errors.candidateNumber} value={state.candidateNumber} type='text' label={<>RSPH Candidate Number&nbsp;<small><a style={{ color: 'gray', textDecoration: 'none' }} target='_blank' rel='noopener noreferrer' href='https://qccp.org.uk/registration'>get number <FontAwesomeIcon icon={faExternalLinkAlt} /></a></small></>} placeholder='RSPH Candidate Number' onChange={changeHandler} />
      </IsNotCandidateNumber>
      <Checkbox name='acceptExamTerms' error={state.errors.acceptExamTerms} checked={state.acceptExamTerms} label='Please confirm that you accept the examination terms' onChange={changeHandler} />
      <p><Button disabled={disabled} type='submit'>
        {!disabled ? 'Continue' : <LoadingIcon align='right'>Continue</LoadingIcon>}
      </Button></p>
    </form>
  </>
};

export default CandidateNumberForm;