import React, { useState, useEffect } from 'react';
import { useLocation, useParams, Prompt } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { examAtomFamily, resetExamSelectorFamily, getExamResponseSelectorFamily } from '../recoil/exam.recoil';
import useExamsData from '../hooks/useExamsData.hook';

const UrlChangeObserver = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const { examId, sectionId = 0 } = useParams();
    const exam = useRecoilValue(examAtomFamily(examId));
    const [ isExamSession, setIsExamSession ] = useState(false);
    const regex = /\/examinations\/exam\/([A-z0-9].*)\/([0-9].*)/gi;
    const message = 'If you leave the exam you will automatically fail. Are you sure you want to proceed?';
    const responses = useRecoilValue(getExamResponseSelectorFamily(examId));
    const resetExam = useSetRecoilState(resetExamSelectorFamily(examId));
    const { setExam } = useExamsData();

    const tabCloseHandler = e => {
        e.returnValue = message;
        return message;
    };

    useEffect(() => {
        if (isExamSession)
            window.addEventListener('beforeunload', tabCloseHandler);
        return () => {
            const isNotExam = !pathname.match(regex);
            if (isExamSession && isNotExam) {
                window.removeEventListener('beforeunload', tabCloseHandler);
                // setExam(responses);
                // resetExam();

                console.log('%cUrlChangeObserver', 'color: yellow; background-color: red;', responses);

                setIsExamSession(false);
            }
        };
    }, [isExamSession, pathname, regex, responses]);

    useEffect(() => {
        if (pathname.match(regex))
            setIsExamSession(true);
    }, [pathname]);
    
    return <Prompt message={location => {
        if (parseInt(sectionId) === 0) return true;
        if (parseInt(sectionId) === 5) return true;
        const pathname = location.pathname;
        const isNotExam = !pathname.match(regex);
        const isLeavingExam = isExamSession && isNotExam;
        if (isLeavingExam)
            return message;
        else
            return true;
    }} />
};

export default UrlChangeObserver;