import React from 'react';
import { Redirect } from 'react-router-dom';
import { IsExaminationsLoading, IsNotExaminationsLoading, IsExamination } from '../components/conditionals.component';
import { IsAuthenticated } from '../components/conditionals.component';
import { ExaminationsSection } from '../components/sections.component';
import { LoadingIcon } from '../components/loading.component';
import Registered from '../content/unlock.content';

const UnlockPage = () => {
    return <ExaminationsSection>
        <IsAuthenticated>
            <IsExaminationsLoading>
                <p><LoadingIcon>Loading examination...</LoadingIcon></p>
            </IsExaminationsLoading>
            <IsNotExaminationsLoading>
                <IsExamination>
                    <Registered />
                </IsExamination>
            </IsNotExaminationsLoading>
        </IsAuthenticated>
    </ExaminationsSection>
};

const Unregistered = () => {
    return <Redirect to='/examinations' />
};

export default UnlockPage;