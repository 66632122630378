import { useRecoilState, useSetRecoilState } from 'recoil';
import { useParams, useHistory } from 'react-router-dom';
import { userIdAtom, userTokenAtom, userDataAtom } from '../recoil/user.recoil';
import { auth, firestore, getCurrentUser, createUserProfileDocument } from '../utils/firebase.utils';
import { API_URL } from '../config/constants.config';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { DELAY_RESPONSE, USER_UPDATING } from '../config/loading.config';
import useStatusData from '../hooks/useStatusData.hook';

const useAuthentication = () => {
  const history = useHistory();
  const { examId } = useParams();
  const [ updating, setUpdating ] = useRecoilState(loadingAtomFamily(USER_UPDATING));
  const setUserId = useSetRecoilState(userIdAtom);
  const [ userToken, setUserToken ] = useRecoilState(userTokenAtom);
  const [ userData, setUserData ] = useRecoilState(userDataAtom);
  const { getStatus } = useStatusData(examId);

  const getSnapshotFromUserAuth = async (user, data = {}) => {
    const userRef = await createUserProfileDocument(user, data);
    const token = await user.getIdToken();
    const snapShot = await userRef.get();
    const id = snapShot.id;
    const snapShotData = snapShot.data();
    return { id, token, userData: snapShotData };
  };

  const updateAuthState = ({ id, token, userData }) => {
    setUserId(id);
    setUserToken(token);
    setUserData(userData);
  };
  
  const emailSignUp = async data => {
    try {
      const { email, password, ...otherData } = data;
      const { user } = await auth.createUserWithEmailAndPassword(email, password);
      const snapshot = await getSnapshotFromUserAuth(user, otherData);
      updateAuthState(snapshot);
      await fetch(`${API_URL}/users/new-user-registered`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${snapshot.token}`,
          'Content-Type': 'application/json'
        },
      });
      return { success: true };
    }
    catch(err) {
      return { success: false, message: err.message };
    }
  };

  const emailSignIn = async data => {
    try {
      const { email, password } = data;
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      updateAuthState(await getSnapshotFromUserAuth(user));
      return { success: true };
    }
    catch(err) {
      return { success: false, message: err.message };
    }
  };

  const signOut = async () => {
    await auth.signOut();
    setUserId(null);
    setUserToken(null);
    setUserData(null);
    history.push('/');
  };

  const resetPassword = async email => {
    try {
      await auth.sendPasswordResetEmail(email);
      return { success: true, message: 'Please check your email for further instructions on resetting your password.' };
    }
    catch(err) {
      return { success: false, message: err.message };
    }
  };

  const unlockExam = async code => {
    try {
      const ref = await fetch(`${API_URL}/providers/redeem-unlock-code`, {
        method: 'post',
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({ examId, code }),
      });
      const json = await ref.json();
      if (ref.status !== 200) {
        return { status: ref.status, message: json.errorMessage };
      } else {
        getStatus();
        return { success: true };
      }
    } catch(err) {
      return { status: 'error', message: err.message };
    }
  };

  const setCandidateNumber = async candidateNumber => {
    if (updating) return;
    setUpdating(true);
    const user = await getCurrentUser();
    if (!user) {
      DELAY_RESPONSE ? setTimeout(() => setUpdating(false), 2000) : setUpdating(false);
      return;
    };
    try {
      const userRef = firestore.doc(`userProfiles/${user.uid}`);
      const snapShot = await userRef.get();
      const userData = snapShot.data();
      const updatedUserData = { ...userData, candidateNumber };
      await userRef.update({ candidateNumber });
      setUserData(updatedUserData);
      DELAY_RESPONSE ? setTimeout(() => setUpdating(false), 2000) : setUpdating(false);
      return { success: true };
    }
    catch(err) {
      console.log(`%cerror: setCandidateNumber() : ${candidateNumber}`, 'color: yellow; background-color: red;', err);
      DELAY_RESPONSE ? setTimeout(() => setUpdating(false), 2000) : setUpdating(false);
      return { success: false, message: err.message };
    }
  };

  return { emailSignUp, emailSignIn, signOut, resetPassword, unlockExam, setCandidateNumber };
};

export default useAuthentication;