import React, { useState, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';
import formReducer from '../reducers/form.reducer';
import useAuthentication from '../hooks/useAuthentication.hook';
import { ButtonNext, ButtonPrev } from './button.component';
import { Text, Checkbox, ProfessionSelect, TrainingProviderSelect } from './forms.component';
import { FormError } from '../styled/forms.styled';
import { Eula } from '../pages/eula.page';
import { PrivacyPolicy } from '../pages/privacyPolicy.page';

const initialState = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    profession: '',
    professionOther: '',
    trainingProvider: '',
    candidateNumber: '',
    eula: false,
    privacy: false,
    errors: {},
};
  
const SignUpForm = () => {
    const [ state, dispatch ] = useReducer(formReducer, initialState);
    const [ section, setSection ] = useState('form');
    const { emailSignUp } = useAuthentication();
    const formStyle = { display: section === 'form' ? 'block' : 'none' };
    const eulaStyle = { display: section === 'eula' ? 'block' : 'none' };
    const privacyPolicyStyle = { display: section === 'privacy-policy' ? 'block' : 'none' };
    const changeHandler = e => dispatch({ type: 'update', payload: e.target });
    const submitHandler = async e => {
      e.preventDefault();
      const { email, password, confirmPassword, firstName, lastName, phoneNumber, profession, professionOther, trainingProvider, candidateNumber, eula, privacy } = state;
      const errors = {};
      if (email === '')
        errors['email'] = 'Please enter your email address';
      if (password === '')
        errors['password'] = 'Please enter a password';
      if (confirmPassword === '')
        errors['confirmPassword'] = 'Please confirm your password';
      if (password !== confirmPassword)
        errors['password'] = 'Passwords do not match';
      if (firstName === '')
        errors['firstName'] = 'Please enter your first name';
      if (lastName === '')
        errors['lastName'] = 'Please enter your last name';
      if (phoneNumber === '')
        errors['phoneNumber'] = 'Please enter your phone number';
      if (profession === '')
        errors['profession'] = 'Please choose a profession';
      if (profession === 'other' && professionOther === '')
        errors['professionOther'] = 'Please enter detail';
      if (trainingProvider === '')
        errors['trainingProvider'] = 'Please choose a training provider';
      if (eula === false)
        errors['eula'] = 'Please indicate that you have read the EULA';
      if (privacy === false)
        errors['privacy'] = 'Please indicate that you agree with the privacy policy';
      if (Object.keys(errors).length) {
        dispatch({ type: 'setErrors', payload: errors });
      } else {
        const result = await emailSignUp({ email, password, firstName, lastName, displayName: `${state.firstName} ${state.lastName}`, phoneNumber, profession, professionOther, trainingProvider, candidateNumber, eula, privacy });
        if (!result.success) {
          errors['message'] = result.message;
          dispatch({ type: 'setErrors', payload: errors });
        }
      }
    };
    const formClickHandler = e => {
      e.preventDefault();
      setSection('form');
    };
    const formChangeHandler = e => {
      e.preventDefault();
      changeHandler(e);
      formClickHandler(e);
    };
    const eulaClickHandler = e => {
      e.preventDefault();
      setSection('eula');
    };
    const privacyPolicyClickHandler = e => {
      e.preventDefault();
      setSection('privacy-policy');
    };
    useEffect(() => () => dispatch({ type: 'reset', payload: initialState }), [dispatch]);
    return <>
      <form style={formStyle} onSubmit={submitHandler}>
        {state.errors.message && <FormError>{state.errors.message}</FormError>}
        <Text name='email' error={state.errors.email} value={state.email} type='email' label='Email' placeholder='Email address' onChange={changeHandler} />
        <Text name='password' error={state.errors.password} value={state.password} type='password' label='Password' placeholder='Password' onChange={changeHandler} />
        <Text name='confirmPassword' error={state.errors.confirmPassword} value={state.confirmPassword} type='password' label='Confirm password' placeholder='Re-enter password' onChange={changeHandler} />
        <Text name='firstName' error={state.errors.firstName} value={state.firstName} type='text' label='First name' placeholder='First name' onChange={changeHandler} />
        <Text name='lastName' error={state.errors.lastName} value={state.lastName} type='text' label='Last name' placeholder='Last name' onChange={changeHandler} />
        <Text name='phoneNumber' error={state.errors.phoneNumber} value={state.phoneNumber} type='text' label='Phone number' placeholder='Phone number' onChange={changeHandler} />
        <ProfessionSelect name='profession' error={state.errors.profession} value={state.profession} otherError={state.errors.professionOther} otherValue={state.professionOther} onChange={changeHandler} />
        <TrainingProviderSelect name='trainingProvider' error={state.errors.trainingProvider} value={state.trainingProvider} onChange={changeHandler} />
        {/* <Text name='candidateNumber' error={state.errors.candidateNumber} value={state.candidateNumber} type='text' label='RSPH Candidate Number' placeholder='RSPH Candidate Number' onChange={changeHandler} /> */}
        <Checkbox name='eula' error={state.errors.eula} checked={state.eula} label={<>Please confirm that you accept the terms of the&nbsp;<Link to='/eula' onClick={eulaClickHandler}>EULA</Link></>} onChange={changeHandler} />
        <Checkbox name='privacy' error={state.errors.privacy} checked={state.privacy} label={<>Please confirm that you accept the&nbsp;<Link to='/privacy-policy' onClick={privacyPolicyClickHandler}>Privacy Policy</Link></>} onChange={changeHandler} />
        <ButtonNext type='submit'>Sign Up</ButtonNext>
        <p><Link to="/">I already have an account</Link></p>
      </form>
      {section === 'eula' && 
        <div style={eulaStyle}>
          <Eula />
          <Checkbox name='eula' error={state.errors.eula} checked={state.eula} label='Please confirm that you accept the terms of the EULA' onChange={formChangeHandler} />
          <p><ButtonPrev onClick={formClickHandler}>Back</ButtonPrev></p>
        </div>}
      {section === 'privacy-policy' && 
        <div style={privacyPolicyStyle}>
          <PrivacyPolicy />
          <Checkbox name='privacy' error={state.errors.privacy} checked={state.privacy} label='Please confirm that you accept the Privacy Policy' onChange={formChangeHandler} />
          <p><ButtonPrev onClick={formClickHandler}>Back</ButtonPrev></p>
        </div>}
    </>
};

export default SignUpForm;