import React, { useRef, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { IsAssessmentQuestion, IsAssessmentPreamble, IsNotAssessmentPreamble, IsAssessmentOutro, IsNotAssessmentOutro, IsAssessmentIncomplete, IsAssessmentFailed, IsAssessmentPassed, IsProfessionLocked, IsNotProfessionLocked, IsNotAssessmentAttempted } from '../components/conditionals.component';
import { isPanelOpenAtom, getAssessmentTitleSelectorFamily, getAssessmentPreambleSelectorFamily, getSectionsFlattenedSelectorFamily, progressAtomFamily } from '../recoil/assessments.recoil';
import { ProfessionLocked } from '../content/selfAssessments.content';
import Question from '../content/question.content';
import { Buttons } from '../styled/global.styled';
import { AssessmentHeading } from '../styled/assessment.styled';
import { AssessmentLink, AssessmentBackDrop, AssessmentPanel, AssessmentPanelQuestionNum, AssessmentGuideContainer } from '../styled/assessment.styled';
import { ButtonPrev, ButtonNext, ButtonOpen } from '../components/button.component';
import Portal from '../components/portal.component';
import { ASSESSMENT_GUIDE_SCREENSHOTS, COMPETENCY_PDFS } from '../config/constants.config';

const Assessment = () => {
  return <>
    <IsAssessmentPreamble>
      <Preamble />
    </IsAssessmentPreamble>
    <IsAssessmentQuestion>
      <Question />
    </IsAssessmentQuestion>
    <IsAssessmentOutro>
      <Outro />
    </IsAssessmentOutro>
    <Navigation />
    <Portal id="panel">
      <BackDrop />
      <Panel />
    </Portal>
  </>
};

const Preamble = () => {
  const { assessmentId } = useParams();
  const title = useRecoilValue(getAssessmentTitleSelectorFamily(assessmentId));
  const preamble = useRecoilValue(getAssessmentPreambleSelectorFamily(assessmentId));
  const ref = useRef();
  const [ open, setOpen ] = useState(false);
  const clickHandler = e => {
    e.preventDefault();
    ref.current.blur();
    setOpen(open => !open);
  };
  return <>
      <AssessmentHeading as='h1'>{title} <ButtonOpen ref={ref} onClick={clickHandler}>Need help?</ButtonOpen></AssessmentHeading>
      <GuideContent open={open} clickHandler={clickHandler} />
      <div dangerouslySetInnerHTML={{ __html: preamble }}></div>
  </>
};

const GuideContent = ({ open, clickHandler }) => {
  if (open)
    return <AssessmentGuideContainer>
      <h2>Assessment Guide</h2>
      <p>This assessment consists of multiple questions which all have two possible answers: <strong>I have a learning need</strong> and <strong>Yes - I meet the competency criteria</strong>. The answer you choose will be marked with a tick, the answer you do not choose will be marked with a cross. You can alter your answers at any time and you do not have to complete the assessment in one session.</p>
      <p><img src={ASSESSMENT_GUIDE_SCREENSHOTS[6]} alt='' /></p>

      <h3>Navigating the Assessment</h3>
      <p>The <strong>Prev</strong> and <strong>Next</strong> buttons allow you to progress to the next question without updating the current answer.</p>
      <p>The <strong>Competency standard</strong> link will allow you to view a PDF of the competency standard for this assessment.</p>
      <p>The <strong>Learning needs</strong> link will display a list of any questions you have answered with <strong>I have a learning need</strong> (further details below).</p>
      <img src={ASSESSMENT_GUIDE_SCREENSHOTS[2]} alt='' />
      <p>At the top of each question page there is a progress bar that will show you how far through the assessment you are.</p>
      <img src={ASSESSMENT_GUIDE_SCREENSHOTS[3]} alt='' />
      <p>Click on the <FontAwesomeIcon icon={faInfoCircle} /> icon to open the assessment contents menu. This provides an overview of all the sections of the assessment. Click on a number to visit the page for that question.</p>
      <img src={ASSESSMENT_GUIDE_SCREENSHOTS[4]} alt='' />
      <p>The question you are currently viewing is outlined with a thick border.<br />
      Grey questions are ones you have not yet provided an answer for.<br />
      Orange questions are ones you have identified as having a learning need.<br />
      Green questions are ones you have indicated that you meet the criteria for.</p>

      <h3>Completing the Assessment</h3>
      <p>When you have provided an answer for every question you will be awarded a percentage mark indicating your progress. If you have identified any learning needs you will be able to view them by clicking on the <strong>View learning needs</strong> link (further details below).</p>
      <p><img src={ASSESSMENT_GUIDE_SCREENSHOTS[1]} alt='' /></p>
      <p>In order to pass the assessment you must answer all questions with <strong>Yes - I meet the competency criteria</strong>. Don't worry if you don't pass first time, you can retake the assessment as many times as you like.</p>
      
      <h3>Learning Needs</h3>
      <p>After completing the assessment, and if you have answered any questions with <strong>I have a learning need</strong>, you will be able to view a list of all the areas you need to review. You can view this list by clicking on the <strong>Learning needs</strong> link at the bottom of the assessment page or the link on the self-assessments index page. Click on a question to visit the corresponding question page.</p>
      <p><img src={ASSESSMENT_GUIDE_SCREENSHOTS[5]} alt='' /></p>

      <h3>Passing the Assessment</h3>
      <p>Once you have answered <strong>Yes - I meet the competency criteria</strong> to all questions you will achieve 100% progress and the assessment will be locked. You will still be able to view the assessment but you will not be able to change your answers.</p>
      <p>The assessment will expire three years from the pass date. You will be able to re-take the assessment 30 days before it expires.</p>
      <p><Link to='#' onClick={clickHandler}>Close guide</Link></p>
    </AssessmentGuideContainer>
  else
    return null;
};

const Outro = () => {
  const { assessmentId } = useParams();
  const title = useRecoilValue(getAssessmentTitleSelectorFamily(assessmentId));
  return <>
    <IsNotAssessmentAttempted assessmentId={assessmentId}>
      <h2>Self-Assessment Incomplete</h2>
      <p>You did not answer all of the questions. Please go back through the assessment and complete any answers you missed out.</p>
    </IsNotAssessmentAttempted>
    <IsAssessmentIncomplete assessmentId={assessmentId}>
        <h2>Self-Assessment Incomplete</h2>
        <p>You did not answer all of the questions. Please go back through the assessment and complete any answers you missed out.</p>
    </IsAssessmentIncomplete>
    <IsAssessmentFailed assessmentId={assessmentId}>
        <h2>Learning Needs Identified</h2>
        <p>You finished the self-assessment but have identified some learning needs. Please review them before returning to complete the assessment.</p>
    </IsAssessmentFailed>
    <IsAssessmentPassed assessmentId={assessmentId}>
        <h2>Self-Assessment Completed</h2>
        <IsNotProfessionLocked>
            <p>Congratulations! You passed the {title} self-assessment. Please review the competency before proceeding to the examination.</p>
        </IsNotProfessionLocked>
        <IsProfessionLocked>
            <p>Congratulations! You passed the {title} self-assessment. Please review the competency.</p>
            <ProfessionLocked />
        </IsProfessionLocked>
    </IsAssessmentPassed>
  </>
};

const Navigation = () => {
  const { assessmentId, questionId = 0 } = useParams();
  return <Buttons>
      <p>
        <IsNotAssessmentPreamble>
          <ButtonPrev as={Link} to={`/self-assessments/assessment/${assessmentId}/${parseInt(questionId) - 1}`}>Prev</ButtonPrev>
        </IsNotAssessmentPreamble>
      </p>
      <p>
        <IsAssessmentFailed>
          <AssessmentLink to={`/self-assessments/learning-needs/${assessmentId}`}>Learning needs</AssessmentLink>
        </IsAssessmentFailed>
        <AssessmentLink as='a' target='_blank' rel='noopener noreferrer' href={COMPETENCY_PDFS[assessmentId]}>Competency standard</AssessmentLink>
      </p>
      <p>
          <IsNotAssessmentOutro>
            <ButtonNext as={Link} to={`/self-assessments/assessment/${assessmentId}/${parseInt(questionId) + 1}`}>
              <IsAssessmentPreamble>Begin</IsAssessmentPreamble>
              <IsNotAssessmentPreamble>Next</IsNotAssessmentPreamble>
            </ButtonNext>
          </IsNotAssessmentOutro>
          <IsAssessmentOutro>
            <IsAssessmentPassed>
              <ButtonNext as={Link} to='/examinations'>Continue</ButtonNext>
            </IsAssessmentPassed>
          </IsAssessmentOutro>
      </p>
  </Buttons>
};

const BackDrop = () => {
  const [ isPanelOpen, setIsPanelOpen ] = useRecoilState(isPanelOpenAtom);
  const clickHandler = () => setIsPanelOpen(false);
  return <AssessmentBackDrop visible={isPanelOpen} onClick={clickHandler}></AssessmentBackDrop>
};

const Panel = () => {
  const [ isPanelOpen, setIsPanelOpen ] = useRecoilState(isPanelOpenAtom);
  const { assessmentId, questionId = 0 } = useParams();
  const sections = useRecoilValue(getSectionsFlattenedSelectorFamily(assessmentId));
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  const [ content, setContent ] = useState([]);
  const clickHandler = () => setIsPanelOpen(false);

  useEffect(() => {
    let prevSection = null;
    let prevSubSection = null;
    let i = 1;
    setContent(sections.reduce((arr, section, idx) => {
      const response = progress.responses ? progress.responses[idx] : null;
      const learningNeed = progress.responses && response === 'learningNeedIdentified' ? true : false;
      const yes = progress.responses && response === 'yes' ? true : false;
      if (!section.subsection) {
        if (section.section !== prevSection) {
          arr.push(<h2 key={`Panel/Sections/${i}`}>{section.section}. <Link to={`/self-assessments/assessment/${assessmentId}/${idx + 1}`} onClick={clickHandler}>{section.title}</Link></h2>);
          i++;
        }
        arr.push(<AssessmentPanelQuestionNum current={idx === parseInt(questionId - 1) ? true : false} key={`Panel/Sections/${i}`} learningNeed={learningNeed} yes={yes} to={`/self-assessments/assessment/${assessmentId}/${idx + 1}`} onClick={clickHandler}>{section.questionNum}</AssessmentPanelQuestionNum>);
        i++;
        prevSection = section.section;
      } else {
        if (section.section !== prevSection) {
          arr.push(<h2 key={`Panel/Sections/${i}`}>{section.section}. <Link to={`/self-assessments/assessment/${assessmentId}/${idx + 1}`} onClick={clickHandler}>{section.title}</Link></h2>);
          i++;
        }
        if (section.subsection !== prevSubSection) {
          arr.push(<h3 key={`Panel/Sections/${i}`}>{section.subsection}. <Link to={`/self-assessments/assessment/${assessmentId}/${idx + 1}`} onClick={clickHandler}>{section.subtitle}</Link></h3>);
          i++;
        }
        arr.push(<AssessmentPanelQuestionNum current={idx === parseInt(questionId - 1) ? true : false} key={`Panel/Sections/${i}`} learningNeed={learningNeed} yes={yes} to={`/self-assessments/assessment/${assessmentId}/${idx + 1}`} onClick={clickHandler}>{section.questionNum}</AssessmentPanelQuestionNum>);
        i++;
        prevSection = section.section;
        prevSubSection = section.subsection;
      }
      return arr;
    }, []));
  }, [sections, progress, questionId]);

  return <AssessmentPanel visible={isPanelOpen}>
    <h1>Contents</h1>
    {content}
  </AssessmentPanel>
};

export default Assessment;