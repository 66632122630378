import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IsAssessmentsLoading, IsNotAssessmentsLoading, IsAtLeastOneAssessmentAttempted, IsNotAtLeastOneAssessmentPassed, IsNotProgressLoading, IsNotAtLeastOneAssessmentAttempted, IsNotAtLeastOneExaminationUnlocked, IsAtLeastOneExaminationUnlocked, IsAtLeastOneAssessmentPassed, IsNoAssessmentsAttempted } from '../components/conditionals.component';
import { IsHome, IsNotHome } from '../components/conditionals.component';
import { IsAuthenticated, IsNotAuthenticated } from '../components/conditionals.component';
import { getUserDisplayNameSelector } from '../recoil/user.recoil';
import { LoadingAssessments } from '../components/loading.component';
import { SectionHeading } from '../styled/global.styled';
import { ButtonNext } from '../components/button.component';
import { AssessmentsSection } from '../components/sections.component';
import { ResultsTable, ResultsList } from '../content/selfAssessments.content';

export const AssessmentsPage = () => {
  return <AssessmentsSection>
    <IsNotAuthenticated>
      <Unregistered />
    </IsNotAuthenticated>
    <IsAuthenticated>
      <IsAssessmentsLoading>
          <LoadingAssessments />
      </IsAssessmentsLoading>
      <IsNotAssessmentsLoading>
        <IsNotProgressLoading>
          <Registered />
        </IsNotProgressLoading>
      </IsNotAssessmentsLoading>
    </IsAuthenticated>
  </AssessmentsSection>
};

const Unregistered = () => {
  return <>
    <p><strong><Link to="/">Sign in</Link> to take the self-assessment</strong></p>
    <p><Link to="/sign-up">I don’t have an account</Link></p>
  </>
};

const Registered = () => {
  return <>
    <IsHome>
      <Greeting />
    </IsHome>
    <Intro />
    <IsHome>
      <IsNoAssessmentsAttempted>
        <Button>Begin</Button>
      </IsNoAssessmentsAttempted>
      <IsAtLeastOneAssessmentAttempted>
        <ResultsTable />
        <IsNotAtLeastOneAssessmentPassed>
          <Button>Continue</Button>
        </IsNotAtLeastOneAssessmentPassed>
        <IsAtLeastOneAssessmentPassed>
          <ViewLink />
        </IsAtLeastOneAssessmentPassed>
      </IsAtLeastOneAssessmentAttempted>
    </IsHome>
    <IsNotHome>
      <ResultsList />
    </IsNotHome>
  </>
};

const Greeting = () => {
  const displayName = useRecoilValue(getUserDisplayNameSelector);
  return <SectionHeading>Welcome, {displayName}</SectionHeading>
};

const Intro = () => {
  return <>
    <p><strong>Please complete these self-assessments of your knowledge and skills.</strong></p>
    <p>The information you enter will help identify your learning and development needs.</p>
  </>
};

const Button = ({ children }) => {
  return <p><ButtonNext as={Link} to="/self-assessments">{children}</ButtonNext></p>
};

const ViewLink = () => {
  return <p><Link to='/self-assessments'>View self-assessments &raquo;</Link></p>
};

export const ProfessionLocked = () => {
  return <p><strong>You cannot take the examination because your profession is not in the list of allowed types.</strong></p>
};

export default AssessmentsPage;