import styled from 'styled-components';
import { COLORS } from '../config/constants.config';
import { Link } from 'react-router-dom';

export const LayoutOuter = styled.div`
color: ${props => props.color ? `rgb(${props.color.r}, ${props.color.g}, ${props.color.b})` : `rgb(${COLORS.text.r}, ${COLORS.text.g}, ${COLORS.text.b})`};
background-color: ${props => props.backgroundColor ? `rgb(${props.backgroundColor.r}, ${props.backgroundColor.g}, ${props.backgroundColor.b})` : `rgb(${COLORS.backgroundColor.r}, ${COLORS.backgroundColor.g}, ${COLORS.backgroundColor.b})`};
display: flex;
justify-content: center;
flex: 0 0 auto;
${props => props.flexGrow ? `flex-grow: ${props.flexGrow}` : ''};
`;

export const LayoutInner = styled.div`
width: calc(100% - 40px);
@media (min-width: 976px) {
  width: 800px;
}
`;

export const LayoutHeader = styled.header`
padding: 20px 0;
display: flex;
justify-content: space-between;
align-items: center;
img {
  max-width: 200px;
}
a {
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover,
  &:focus,
  &:active {
    background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
  }
}
`;

export const LayoutFooter = styled.footer`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
@media (min-width: 768px) {
  flex-direction: row;
}
ul {
  flex: 1 1 0px;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a {
    padding: 3px 10px;
  }
}
p {
  flex: 1 1 0px;
  &:nth-child(2) {
    text-align: center;
    a:hover,
    a:focus,
    a:active {
      background-color: transparent;
    }
  }
}
img {
  max-width: 200px;
}
`;

export const LayoutNavigation = styled.nav`
min-height: 40px;
display: flex;
justify-content: space-between;
align-items: center;
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
`;

export const LayoutNavigationLink = styled(Link)`
padding: 10px;
color: ${props => {
  return (props.location === '/' && props.to === '/') ||
         (props.to !== '/' && props.location.substring(1).startsWith(props.to.substring(1))) 
         ? `rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b})` 
         : `rgb(${COLORS.primaryContrast.r}, ${COLORS.primaryContrast.g}, ${COLORS.primaryContrast.b})`;
}};
background-color: transparent;
font-weight: bold;
text-decoration: none;
text-transform: uppercase;
display: block;
cursor: pointer;
&:hover,
&:focus,
&:active {
  color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
  background-color: transparent;
}


font-size: 2.4rem;
span {
  display: none;
}

@media (min-width: 768px) {
  font-size: 0.9rem;
  span {
    display: inline;
  }
}
`;

export const LayoutMain = styled.main`
`;