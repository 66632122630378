const formReducer = (state, action) => {
    const resetErrors = () => Object.entries(state.errors).reduce((obj, [prop, value]) => {
      if (prop !== action.payload.name)
        obj[prop] = value;
      return obj;
    }, {});
    switch (action.type) {
      case 'update':
        if (action.payload.type === 'checkbox')
          return { ...state, [action.payload.name]: action.payload.checked, errors: resetErrors() };
        else
          return { ...state, [action.payload.name]: action.payload.value, errors: resetErrors() };
      case 'replace':
        return action.payload;
      case 'setErrors':
        return { ...state, errors: action.payload };
      case 'reset':
        return action.payload;
      default:
        throw new Error();
    }
};
  
export default formReducer;