import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS } from '../config/constants.config';

export const QuestionContainer = styled.div`
margin: 10px 0;
padding-left: 1rem;
display: flex;
`;

export const QuestionNum = styled.span`
width: 1.5rem;
`;

export const QuestionText = styled(Link)`
width: calc(100% - 30px);
color: rgb(${COLORS.primaryContrast.r}, ${COLORS.primaryContrast.g}, ${COLORS.primaryContrast.b});
&:hover,
&:focus,
&:active {
    color: rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b}, 1);
    background-color: transparent;
    text-decoration: underline;
}
p {
    margin-top: 0;
}
ul, ol {
    margin: 10px 0 0 0;
    padding-left: 1rem;
}
`;