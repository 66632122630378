import React from 'react';
import { IsExaminationsLoading, IsNotExaminationsLoading, IsNotStatusLoading, IsAtLeastOneExaminationSubmitted, IsNotAtLeastOneExaminationSubmitted } from '../components/conditionals.component';
import { IsAuthenticated, IsNotAuthenticated } from '../components/conditionals.component';
import { LoadingExaminations } from '../components/loading.component';
import { SubmissionsSection } from '../components/sections.component';
import { ResultsTable } from '../content/submissions.content';

const SubmissionsPage = () => {
  return <SubmissionsSection>
    <IsNotAuthenticated>
      <Unregistered />
    </IsNotAuthenticated>
    <IsAuthenticated>
      <IsExaminationsLoading>
        <LoadingExaminations />
      </IsExaminationsLoading>
      <IsNotExaminationsLoading>
        <IsNotStatusLoading>
          <Registered />
        </IsNotStatusLoading>
      </IsNotExaminationsLoading>
    </IsAuthenticated>
  </SubmissionsSection>
};

const Unregistered = () => {
  return <p>View your submissions</p>
};

const Registered = () => {
  return <>
    <IsNotAtLeastOneExaminationSubmitted>
      <p>You must pass a self-assessment and its corresponding examination before you can submit your results</p>
    </IsNotAtLeastOneExaminationSubmitted>
    <IsAtLeastOneExaminationSubmitted>
      <p>Listed below are your examination results that have been submitted to the regulator RSPH for moderation to ensure your registration with your statutory body is active without any restrictions to practice.</p>
      <p>You will be notified of the examination outcome once the moderation process is complete.</p>
      <ResultsTable />
    </IsAtLeastOneExaminationSubmitted>
  </>
};

export default SubmissionsPage;