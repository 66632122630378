import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTachometerAlt, faUser, faList, faAward, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import useAuthentication from '../hooks/useAuthentication.hook';
import { LayoutOuter, LayoutInner, LayoutHeader, LayoutFooter, LayoutNavigation, LayoutNavigationLink, LayoutMain } from '../styled/layout.styled';
import { TITLE, QCCP_LOGO, RSPH_LOGO, COPYRIGHT, COLORS } from '../config/constants.config';
import { IsAuthenticated } from '../components/conditionals.component';
import { loggingAtom } from '../recoil/logging.recoil';
import { resetSelector } from '../recoil/user.recoil';

const Limits = ({ children, ...props }) => {
  return <LayoutOuter {...props}>
    <LayoutInner>
      {children}
    </LayoutInner>
  </LayoutOuter>
};

export const Header = ({ children }) => {
  const setLogging = useSetRecoilState(loggingAtom);
  const clickHandler = e => {
    // if (e.detail === 3)
    //   setLogging(logging => !logging);
  };
  return <Limits>
    <LayoutHeader>
      <img onClick={clickHandler} src={QCCP_LOGO} alt={TITLE} />
      <a href='https://qccp.org.uk/' title='Return to QCCP'>
        <FontAwesomeIcon icon={faHome} size='3x' />
      </a>
    </LayoutHeader>
  </Limits>
};

export const Footer = ({ children }) => {
  return <Limits color={COLORS.text} backgroundColor={COLORS.backgroundColorAlt}>
    <LayoutFooter>
      <p>{COPYRIGHT}</p>
      <p>
        <a href='https://www.rsph.org.uk/' target='_blank' rel='noopener noreferrer'>
          <img src={RSPH_LOGO} alt='Royal Society for Public Health' />
        </a>
      </p>
      <ul>
        <li><Link to='/contact'>Contact</Link></li>
        <li><Link to='/eula'>EULA</Link></li>
        <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
      </ul>
    </LayoutFooter>
  </Limits>
};

export const Main = ({ children }) => {
  return <Limits flexGrow={1}>
    <LayoutMain>
      {children}
    </LayoutMain>
  </Limits>
};

export const Navigation = () => {
  const location = useLocation();
  const reset = useSetRecoilState(resetSelector);
  const { signOut } = useAuthentication();
  const signOutHandler = async e => {
    e.preventDefault();
    await signOut();
    reset();
  };
  return <Limits color={COLORS.primaryContrast} backgroundColor={COLORS.primary}>
    <LayoutNavigation location={location.pathname}>
      <ul>
        <li><LayoutNavigationLink location={location.pathname} to='/'><FontAwesomeIcon icon={faTachometerAlt} /> <span>Dashboard</span></LayoutNavigationLink></li>
        <IsAuthenticated>
          <li><LayoutNavigationLink location={location.pathname} to='/self-assessments'><FontAwesomeIcon icon={faUser} /> <span>Self-Assessments</span></LayoutNavigationLink></li>
          <li><LayoutNavigationLink location={location.pathname} to='/examinations'><FontAwesomeIcon icon={faList} /> <span>Examinations</span></LayoutNavigationLink></li>
          <li><LayoutNavigationLink location={location.pathname} to='/submissions'><FontAwesomeIcon icon={faAward} /> <span>Submissions</span></LayoutNavigationLink></li>
        </IsAuthenticated>
      </ul>
      <ul>
        <IsAuthenticated>
          <li><LayoutNavigationLink location={location.pathname} to='/sign-out' href='#' onClick={signOutHandler}><span>Sign Out</span> <FontAwesomeIcon icon={faSignOutAlt} /></LayoutNavigationLink></li>
        </IsAuthenticated>
      </ul>
    </LayoutNavigation>
  </Limits>
};