import { useRecoilValue, useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { userTokenAtom } from '../recoil/user.recoil';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { API_URL } from '../config/constants.config';
import useStatusData from '../hooks/useStatusData.hook';
import { DELAY_RESPONSE, EXAM_UNLOCKING_CODE, EXAM_UNLOCKING_STRIPE } from '../config/loading.config';

const useExamUnlock = () => {
  const { examId } = useParams();
  const userToken = useRecoilValue(userTokenAtom);
  const [ unlockingCode, setUnlockingCode ] = useRecoilState(loadingAtomFamily(`${EXAM_UNLOCKING_CODE}:${examId}`));
  const [ unlockingStripe, setUnlockingStripe ] = useRecoilState(loadingAtomFamily(`${EXAM_UNLOCKING_STRIPE}:${examId}`));
  const { getStatus } = useStatusData(examId);

  const unlockExamWithCode = async code => {
    if (unlockingCode) return;
    setUnlockingCode(true);
    let response = {};
    try {
      const ref = await fetch(`${API_URL}/providers/redeem-unlock-code`, {
        method: 'post',
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({ examId, code }),
      });
      const json = await ref.json();
      if (ref.status !== 200) {
        response = { status: ref.status, message: json.errorMessage };
      } else {
        getStatus();
        response = { success: true };
      }
    } catch(err) {
      response = { status: 'error', message: err.message };
    }
    DELAY_RESPONSE ? setTimeout(() => setUnlockingCode(false), 3000) : setUnlockingCode(false);
    return response;
  };

  const unlockExamWithStripe = async cfg => {
    if (unlockingStripe) return;
    setUnlockingStripe(true);
    let response = {};
    try {
        const ref = await fetch(`${API_URL}/exams/${examId}/create-checkout-session`, {
            method: 'post',
            headers: {
              'Authorization': `Bearer ${userToken}`,
              'Content-Type': `application/json`,
            },
            body: JSON.stringify(cfg),
        });
        const json = await ref.json();
        if (ref.status !== 200) {
            response = { success: false, message: json.message };
        } else {
            response = { success: true, response: json };
        }
    } catch(err) {
      response = { success: false, message: err.message };
    }
    DELAY_RESPONSE ? setTimeout(() => setUnlockingStripe(false), 3000) : setUnlockingStripe(false);
    return response;
  };

  return { unlockExamWithCode, unlockExamWithStripe };
};

export default useExamUnlock;