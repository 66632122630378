import React, { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { IsAssessmentProgress } from '../components/conditionals.component';
import { LoadingIcon } from '../components/loading.component';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { isPanelOpenAtom, progressAtomFamily, getAssessmentSelectorFamily, getSectionsFlattenedSelectorFamily } from '../recoil/assessments.recoil';
import useProgressData from '../hooks/useProgressData.hook';
import { PROGRESS_UPDATING } from '../config/loading.config';
import { Progress } from '../styled/global.styled';
import { AssessmentHeading, AssessmentQuestion, AssessmentQuestionNum, AssessmentQuestionContent, AssessmentResponses, AssessmentButton } from '../styled/assessment.styled';

const Question = () => {
  const [ isPanelOpen, setIsPanelOpen ] = useRecoilState(isPanelOpenAtom);
  const { assessmentId, questionId = 0 } = useParams();
  const section = useRecoilValue(getSectionsFlattenedSelectorFamily(assessmentId));
  const question = section[questionId - 1];
  const ref = useRef();
  const clickHandler = e => {
    e.preventDefault();
    ref.current.blur();
    setIsPanelOpen(true);
  };
  return <>
      <ProgressBar />
      <AssessmentHeading>
        <span>{question.section}. {question.title}</span>
        <Link ref={ref} onClick={clickHandler} to='#'><FontAwesomeIcon icon={faInfoCircle} /></Link>
      </AssessmentHeading>
      {question.subtitle && <h3>{question.subsection}. {question.subtitle}</h3>}
      {question.preamble && <div dangerouslySetInnerHTML={{ __html: question.preamble }} />}
      <AssessmentQuestion>
        <AssessmentQuestionNum>
          <h4>{question.questionNum}.</h4>
        </AssessmentQuestionNum>
        <AssessmentQuestionContent dangerouslySetInnerHTML={{ __html: question.question }} />
      </AssessmentQuestion>
      <Responses />
  </>
};

const ProgressBar = () => {
  const { assessmentId, questionId = 0 } = useParams();
  const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
  const indicatorStyle = { width: `${parseInt(questionId) / parseInt(assessment.questionCount) * 100}%` };
  return <Progress>
    <div style={indicatorStyle} />
    <span>{questionId} of {assessment.questionCount}</span>
  </Progress>
};

const Responses = () => {
  return <AssessmentResponses>
      <ResponseLearningNeed />
      <ResponseYes />
  </AssessmentResponses>
};

const ResponseLearningNeed = () => {
  const { assessmentId, questionId = 0 } = useParams();
  const buttonRef = useRef();
  const updating = useRecoilValue(loadingAtomFamily(`${PROGRESS_UPDATING}:${assessmentId}`));
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  const response = progress.responses ? progress.responses[parseInt(questionId) - 1] : null;
  const selected = progress.responses && response === 'learningNeedIdentified' ? true : false;
  const locked = progress.status === 'passed';
  const { setProgress } = useProgressData(assessmentId);
  const clickHandler = e => {
      e.preventDefault();
      if (progress.status !== 'passed') {
          if (!updating) {
              setProgress([
                  ...progress.responses.slice(0, questionId - 1),
                  'learningNeedIdentified',
                  ...progress.responses.slice(questionId),
              ], `/self-assessments/assessment/${assessmentId}/${parseInt(questionId) + 1}`);
          }
      }
      buttonRef.current.blur();
  };
  return <AssessmentButton ref={buttonRef} as={Link} selected={selected} locked={locked} onClick={clickHandler} to={`/self-assessments/assessment/${assessmentId}/${parseInt(questionId) + 1}`}>
      I have a learning need {updating && <LoadingIcon />} {!updating && <ResponseStatus value='learningNeedIdentified' />}
  </AssessmentButton>
};

const ResponseYes = () => {
  const { assessmentId, questionId = 0 } = useParams();
  const buttonRef = useRef();
  const updating = useRecoilValue(loadingAtomFamily(`${PROGRESS_UPDATING}:${assessmentId}`));
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  const response = progress.responses ? progress.responses[parseInt(questionId) - 1] : null;
  const selected = progress.responses && response === 'yes' ? true : false;
  const locked = progress.status === 'passed';
  const { setProgress } = useProgressData(assessmentId);
  const clickHandler = e => {
      e.preventDefault();
      if (progress.status !== 'passed') {
          if (!updating) {
              setProgress([
                  ...progress.responses.slice(0, questionId - 1),
                  'yes',
                  ...progress.responses.slice(questionId),
              ], `/self-assessments/assessment/${assessmentId}/${parseInt(questionId) + 1}`);
          }
      }
      buttonRef.current.blur();
  };
  return <AssessmentButton ref={buttonRef} as={Link} selected={selected} locked={locked} onClick={clickHandler} to={`/self-assessments/assessment/${assessmentId}/${parseInt(questionId) + 1}`}>
      Yes - I meet the competency criteria {updating && <LoadingIcon />} {!updating && <ResponseStatus value='yes' />}
  </AssessmentButton>
};

const ResponseStatus = ({ value }) => {
  const { assessmentId, questionId = 0 } = useParams();
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  const response = progress.responses ? progress.responses[parseInt(questionId) - 1] : null;
  const selected = progress.responses && response === value ? true : false;
  return <IsAssessmentProgress>
      {response && <>
          {selected ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />}
      </>}
  </IsAssessmentProgress>
};

export default Question;