import styled from 'styled-components';
import { COLORS } from '../config/constants.config';
import { Container } from '../styled/sections.styled';

export const Columns50_50 = styled.div`
display: flex;
> div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
> div:first-child {
  padding-right: 15px;
}
> div:last-child {
  padding-left: 15px;
}
`;

export const PageHeading = styled.h1`
margin: 20px 0 0 0;
padding: 5px 20px;
color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
background-color: rgb(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b});
font-size: 1.8em;
text-transform: uppercase;
`;

export const PageContent = styled.div`
padding: 5px 20px;
`;

export const SectionHeading = styled.h2`
color: ${props => 
  props.dark ? 
    `rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b})` : 
    `rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b})`}
`;

export const Table = styled.table`
width: 100%;
margin: 15px 0;
border-collapse: collapse;
color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
background-color: rgb(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b});
th {
  width: 66.666666667%;
  padding: 5px;
  border: 1px solid rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
  text-align: left;
  vertical-align: top;
}
td {
  width: 33.333333333%;
  padding: 5px;
  border: 1px solid rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
  text-align: right;
  vertical-align: top;
  strong {
    display: none;
  }
}
`;

export const Loading = styled.p`
`;

export const Progress = styled.div`
position: relative;
width: 100%;
margin: 15px 0;
padding: 5px 0;
background-color: gray;
display: flex;
justify-content: center;
align-itemst: center;
> div {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(${COLORS.secondary.r - 10},${COLORS.secondary.g - 10},${COLORS.secondary.b - 10});
}
> span {
  position: relative;
  z-index: 2;
  color: rgb(${COLORS.primary.r},${COLORS.primary.g},${COLORS.primary.b});
}
`;

export const Buttons = styled.div`
margin-left: 0;
padding-left: 0;
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;
text-align: center;
p {
  width: 33.3333333333%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    width: auto;
  }
  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }
}
`;

export const Button = styled.button`
box-sizing: border-box;
width: 100%;
margin: 0;
padding: 2px;
border: 1px solid white;
border-radius: calc(20px + 1.2rem);
font-size: 1.2rem;
text-decoration: none;
display: flex;
justify-content: space-between;
align-items: center;
transition: color 0.2s, background-color 0.2s;
cursor: pointer;
svg {
  font-size: 2rem;
}
span {
  padding: 0 10px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5px;
    font-size: 1.2rem;
  }
}
color: white;
background-color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
&:hover,
&:focus,
&:active {
  color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
  background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
}
${Container} & {
  color: white;
  background-color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
  &:hover,
  &:focus,
  &:active {
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
  }
}
${props => props.disabled ? `
${Container} & {
  border: 1px solid white;
  color: white;
  background-color: #666;
  cursor: default;
  pointer-events: none;
  &:hover,
  &:focus,
  &:active {
    color: white;
    background-color: #666;
  }
}` : ``}
`;

export const StripeButton = styled(Button)`
padding: 0;
color: white;
justify-content: center;
align-items: center;
svg {
  color: white;
  font-size: 2.3rem;
}
svg:nth-child(2) {
  font-size: 1.2rem;
}
${Container} & {
${props => props.disabled ? `
  border: 1px solid white;
  color: white;
  background-color: #666;
  cursor: default;
  pointer-events: none;
  &:hover,
  &:focus,
  &:active {
  color: white;
    background-color: #666;
  }
}
` : ``}
`;