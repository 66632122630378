import React from 'react';
import { useRecoilValue } from 'recoil';
import { Container, Label, Prop, Value, InputText, InputTextArea, InputSelect, InputCheckbox, FormError } from '../styled/forms.styled';
import { professionsAtom, trainingProvidersAtom } from '../recoil/user.recoil';

export const Control = ({ label, children }) => {
  return <Container>
    <Label>
      <Prop>{label}</Prop>
      <Value>{children}</Value>
    </Label>
  </Container>
};

export const McqControl = ({ label, children }) => {
  return <Container>
    <Label>
      <Value>{children}</Value> 
      <Prop>{label}</Prop>
    </Label>
  </Container>
};

export const Text = ({ error, ...props }) => {
  return <>
    {error && <FormError>{error}</FormError>}
    <Control {...props}>
      <InputText {...props} />
    </Control>
  </>
};

export const TextArea = ({ error, ...props }) => {
  return <>
    {error && <FormError>{error}</FormError>}
    <Control {...props}>
      <InputTextArea {...props} />
    </Control>
  </>
};

export const Checkbox = ({ error, ...props }) => {
    return <>
      {error && <FormError>{error}</FormError>}
      <Control {...props}>
        <InputCheckbox type='checkbox' {...props} />
      </Control>
    </>
};

export const ProfessionSelect = ({ error, otherValue, otherError, ...props }) => {
    const professions = useRecoilValue(professionsAtom);
    return <>
      {error && <FormError>{error}</FormError>}
      <Control label='Profession'>
        <InputSelect {...props}>
          <option value=''>Choose a profession...</option>
          {professions.map(_ => 
            <option key={`Professions/${_.value}`} value={_.value}>{_.label}</option>)}
        </InputSelect>
      </Control>
      {props.value === 'other' && <>
        {otherError && <FormError>{otherError}</FormError>}
        <Text name='professionOther' value={otherValue} type='text' label='Other profession details' placeholder='Other profession details' onChange={props.onChange} />
      </>}
    </>
};

export const TrainingProviderSelect = ({ error, ...props }) => {
  const trainingProviders = useRecoilValue(trainingProvidersAtom);
  const providers = trainingProviders.filter(provider => provider.details?.internal !== true);
  // const providers = trainingProviders;
  return <>
    {error && <FormError>{error}</FormError>}
    <Control label='Training provider'>
      <InputSelect {...props}>
        <option value=''>Choose a training provider...</option>
        {providers.map((_, idx) => 
          <option key={`TrainingProvider/${idx}`} value={_.id}>{_.details.name}</option>
        )}
      </InputSelect>
    </Control>
  </>
};