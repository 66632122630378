export const DELAY_RESPONSE         = false;

export const USER_FETCHING          = 0;
export const USER_UPDATING          = 1;
export const ASSESSMENTS_FETCHING   = 2;
export const EXAMINATIONS_FETCHING  = 3;
export const SECTIONS_FETCHING      = 4;
export const PROGRESS_FETCHING      = 5;
export const PROGRESS_UPDATING      = 6;
export const STATUS_FETCHING        = 7;
export const STATUS_UPDATING        = 8;
export const EXAM_FETCHING          = 9;
export const EXAM_UPDATING          = 10;
export const EXAM_UNLOCKING_CODE    = 11;
export const EXAM_UNLOCKING_STRIPE  = 12;