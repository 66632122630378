import { atom, atomFamily, selectorFamily } from 'recoil';

export const examAtomFamily = atomFamily({
    key: 'exam',
    default: null,
});

export const examHelpAtom = atom({
    key: 'examHelp',
    default: false,
});

export const timerAtomFamily = atomFamily({
    key: 'timer',
    default: () => Date.now(),
});

export const selectedWordAtomFamily = atomFamily({
    key: 'selectedWord',
    default: null,
});

export const searchWordAtomFamily = atomFamily({
    key: 'searchWord',
    default: '',
});

export const placeholderResponsesAtomFamily = atomFamily({
    key: 'placeholderResponses',
    default: {},
});

export const questionsResponsesAtomFamily = atomFamily({
    key: 'questionsResponses',
    default: {},
});

export const resetExamSelectorFamily = selectorFamily({
    key: 'resetExam',
    set: id => ({ get, set }) => {
        set(examHelpAtom, false);
        const exam = get(examAtomFamily(id));
        if (exam) {
            for (let i = 0; i < exam.contents.length; i++) {
                set(selectedWordAtomFamily(`${id}:${i + 1}`), null);
                set(placeholderResponsesAtomFamily(`${id}:${i + 1}`), {});
            }
            set(questionsResponsesAtomFamily(id), {});
            set(examAtomFamily(id), null);
        };
    },
});

// get
export const getEssayTotalSelectorFamily = selectorFamily({
    key: 'getEssayTotal',
    get: ({ examId, sectionId }) => ({ get }) => {
        const exam = get(examAtomFamily(examId));
        if (exam && exam.placeholderCounts)
            return exam.placeholderCounts[sectionId - 1];
        return 0;
    },
});

export const getEssayCompletionSelectorFamily = selectorFamily({
    key: 'getEssayCompletion',
    get: ({ examId, sectionId }) => ({ get }) => {
        const responses = get(placeholderResponsesAtomFamily(`${examId}:${sectionId}`));
        return !Object.keys(responses).length ? 0 : Object.values(responses).filter(_ => _ !== null).length;
    },
});

export const getEssayWordsSelectorFamily = selectorFamily({
    key: 'getEssayWords',
    get: ({ examId, sectionId }) => ({ get }) => {
        const exam = get(examAtomFamily(examId));
        if (exam && exam.placeholderSelections)
            return exam.placeholderSelections[sectionId - 1];
        return [];
    },
});

export const getMcqTotalSelectorFamily = selectorFamily({
    key: 'getMcqTotal',
    get: id => ({ get }) => {
        const exam = get(examAtomFamily(id));
        if (exam && exam.questionSections)
            return Object.values(exam.questionSections).reduce((num, obj) => num + Object.keys(obj).length, 0)
        return 0;
    },
});

export const getMcqCompletionSelectorFamily = selectorFamily({
    key: 'getMcqCompletion',
    get: id => ({ get }) => {
        const responses = get(questionsResponsesAtomFamily(id));
        if (Object.keys(responses).length)
            return Object.values(responses).reduce((num, obj) => {
                return num + Object.values(obj).reduce((num, arr) => {
                    return arr.length ? num + 1 : num;
                }, 0);
            }, 0);
        return 0;
    },
});

export const getExamResponseSelectorFamily = selectorFamily({
    key: 'getExamResponse',
    get: id => ({ get }) => {
        const exam = get(examAtomFamily(id));
        let essayId = '';
        const placeholderResponses = [];
        let questionResponses = {};
        if (exam) {
            essayId = exam.essayId;
            for (let i = 0; i < exam.contents.length; i++) {
                placeholderResponses.push(get(placeholderResponsesAtomFamily(`${id}:${i + 1}`)));
            }
            questionResponses = get(questionsResponsesAtomFamily(id));
        }
        return { essayId, placeholderResponses, questionResponses };
    },
});

// conditionals
export const isTimerExpiredSelectorFamily = selectorFamily({
    key: 'isTimerExpired',
    get: id => ({ get }) => {
        const timer = get(timerAtomFamily(id));
        const allowedTime = 2 * 60 * 60 * 1000;
        const elapsedTime = Date.now() - timer;
        if (elapsedTime > allowedTime)
            return true;
        return false;
    },
});