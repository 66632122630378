import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageHeading } from '../styled/global.styled';
import SignUpForm from '../components/signUp.component';
import TrainingProviderSubscriber from '../subscribers/trainingProviders.subscriber';
import { IsAuthenticated, IsNotAuthenticated } from '../components/conditionals.component';

const SignUpPage = () => {
  return <>
    <IsAuthenticated>
      <Redirect to='/' />
    </IsAuthenticated>
    <IsNotAuthenticated>
      <TrainingProviderSubscriber />
      <PageHeading>Sign Up</PageHeading>
      <SignUpForm />
    </IsNotAuthenticated>
  </>
};

export default SignUpPage;