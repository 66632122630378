import styled from 'styled-components';
import { COLORS } from '../config/constants.config';
import { Container } from '../styled/sections.styled';
import { Link } from 'react-router-dom';

export const AssessmentHeading = styled.h2`
display: flex;
justify-content: space-between;
align-items: flex-start;
a {
  width: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: transparent;
  &:hover,
  &:focus,
  &:active {
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: transparent;
  }
  svg {
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    filter: drop-shadow(1px 1px 0px white) 
            drop-shadow(-1px 1px 0px white) 
            drop-shadow(1px -1px 0px white) 
            drop-shadow(-1px -1px 0px white)
  }
  &:hover,
  &:focus,
  &:active {
    svg {
      color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
    }
  }
}
button {
  width: auto;
}
`;

export const AssessmentQuestion = styled.div`
margin-top: 15px;
padding-top: 15px;
border-top: 1px solid rgba(255,255,255,0.3);
display: flex;
`;

export const AssessmentQuestionNum = styled.div`
min-width: 5vw;
max-width: 10wv;
h4 {
  margin-top: 0;
}
`;

export const AssessmentQuestionContent = styled.div`
flex-grow: 1;
ol, ul, p {
  margin-top: 0;
}
`;

export const AssessmentResponses = styled.div`
margin: 30px 0 15px 0;
padding-left: 0;
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;
`;

export const AssessmentButton = styled.a.withConfig({
  shouldForwardProp: prop => prop !== 'locked',
})`
width: calc(50% - 10px);
margin: 0;
padding: 10px;
border: 0;
font-size: 1.1rem;
text-align: center;
text-decoration: none;
color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
${Container} & {
  color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
  background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
  &:hover,
  &:focus,
  &:active {
    color: rgba(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
    background-color: rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    outline: none;
  }
}
${props => !props.locked ? `
cursor: pointer;
transition: 0.2s color, 0.2s background-color;
` : `
`}
${props => props.locked ? `
pointer-events: none;
` : `
`}
${props => props.locked && !props.selected ? `
${Container} & {
  color: #999;
  background-color: rgba(255,255,255,0.2);
}
` : `
`}
`;

export const AssessmentBackDrop = styled.div`
position: fixed;
z-index: 100;
top: 0;
right: 0;
bottom: 0;
left: 0;
background-color: rgba(0,0,0,0.5);
transition: opacity 0.3s;
${props => props.visible ? `
opacity: 1;
display: block;
` : `
opacity: 0;
display: none;
`}
`;

export const AssessmentPanel = styled.section`
position: fixed;
z-index: 101;
top: 0;
right: 25%;
bottom: 0;
left: 0;
padding: 0 5% 5% 5%;
overflow-y: auto;
transition: transform 0.3s;
background-color: rgba(${COLORS.backgroundColorAlt.r},${COLORS.backgroundColorAlt.g},${COLORS.backgroundColorAlt.b});
box-shadow: 10px 0 10px 10px rgba(0,0,0,0.1);
h1 {
  font-size: 1.4rem;
}
h2 {
  font-size: 1.2rem;
}
h3 {
  font-size: 1rem;
}
h2 + a,
h3 + a {
  margin-left: 1.4rem;
}
${props => props.visible ? `
transform: translateX(0);
` : `
transform: translateX(-100%);
`}
`;

export const AssessmentPanelQuestionNum = styled(Link).withConfig({
  shouldForwardProp: prop => prop !== 'learningNeed' && prop !== 'yes' && prop !== 'current',
})`
min-width: 30px;
margin: 3px;
margin: 3px;
padding: 5px;
color: rgb(${COLORS.primary.r},${COLORS.primary.g},${COLORS.primary.b});
background-color: #ccc;
text-decoration: none;
text-align: center;
display: inline-block;
&:hover,
&:focus,
&:active {
  color: rgb(${COLORS.primaryContrast.r},${COLORS.primaryContrast.g},${COLORS.primaryContrast.b});
  background-color: rgb(${COLORS.primary.r},${COLORS.primary.g},${COLORS.primary.b});
}
${props => props.learningNeed ? `
color: rgb(${COLORS.primary.r},${COLORS.primary.g},${COLORS.primary.b});
background-color: #f90;
` : ``}
${props => props.yes ? `
color: rgb(${COLORS.primary.r},${COLORS.primary.g},${COLORS.primary.b});
background-color: rgb(${COLORS.secondary.r},${COLORS.secondary.g},${COLORS.secondary.b});
` : ``}
${props => props.current ? `
outline: 3px solid rgb(${COLORS.primary.r},${COLORS.primary.g},${COLORS.primary.b});
` : ``}
`;

export const AssessmentLink = styled(Link)`
padding: 10px 20px;
white-space: nowrap;
`;

export const AssessmentGuideContainer = styled.div`
padding: 15px;
color: white;
background-color: rgba(0,0,0,0.3);
img {
  max-width: 100%;
  height: auto;
}
`;