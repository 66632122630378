import { atom, selector } from 'recoil';
import { assessmentsAtom, sectionsAtomFamily, progressAtomFamily } from './assessments.recoil';
import { examinationsAtom, statusAtomFamily } from './examinations.recoil';
import { examAtomFamily } from './exam.recoil';

export const userIdAtom = atom({
    key: 'userId',
    default: null,
});
  
export const userTokenAtom = atom({
    key: 'userToken',
    default: null,
});
  
export const userDataAtom = atom({
    key: 'userData',
    default: null,
});

export const professionsAtom = atom({
    key: 'professions',
    default: [
      { value: 'doctor',                label: 'Doctor' },
      { value: 'prescribingNurse',      label: 'Prescribing Nurse' },
      { value: 'prescribingPharmacist', label: 'Prescribing Pharmacist' },
      { value: 'midwife',               label: 'Prescribing Midwife' },
      { value: 'dentist',               label: 'Dentist' },
      { value: 'other',                 label: 'Other' },
    ],
});

export const trainingProvidersAtom = atom({
    key: 'trainingProviders',
    default: [],
});

export const resetSelector = selector({
    key: 'reset',
    set: ({ get, set }) => {
        const assessments = get(assessmentsAtom);
        const examinations = get(examinationsAtom);
        for (let assessment of assessments) {
            set(sectionsAtomFamily(assessment.id), []);
            set(progressAtomFamily(assessment.id), {});
        }
        for (let examination of examinations) {
            set(statusAtomFamily(examination.id), {});
            set(examAtomFamily(examination.id), null);
        }
        set(assessmentsAtom, []);
        set(examinationsAtom, []);
    },
});

// get
export const getUserSelector = selector({
    key: 'getUser',
    get: ({ get }) => {
        const userId = get(userIdAtom);
        const userToken = get(userTokenAtom);
        const userData = get(userDataAtom);
        return { userId, userToken, userData };
    },
});
  
export const getUserDisplayNameSelector = selector({
    key: 'getUserDisplayName',
    get: ({ get }) => {
        const userData = get(userDataAtom);
        if (!userData || !userData.displayName) return '';
        return userData.displayName;
    },
});
  
export const getUserCandidateNumberSelector = selector({
    key: 'getUserCandidateNumber',
    get: ({ get }) => {
        const userData = get(userDataAtom);
        if (!userData || !userData.candidateNumber) return '';
        return userData.candidateNumber;
    },
});

// conditionals
export const isAuthenticatedSelector = selector({
    key: 'isAuthenticated',
    get: ({ get }) => get(userIdAtom) !== null && get(userTokenAtom) !== null && get(userDataAtom) !== null,
});

export const isProfessionLockedSelector = selector({
    key: 'isProfessionLocked',
    get: ({ get }) => {
        const userData = get(userDataAtom);
        if (!userData || !userData.profession) return false;
        if (userData.profession === 'other')
            return true;
        else
            return false;
    },
});