import { useParams, useHistory } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { API_URL } from '../config/constants.config';
import { getExaminationSelectorFamily, statusAtomFamily } from '../recoil/examinations.recoil';
import { examAtomFamily, timerAtomFamily, resetExamSelectorFamily } from '../recoil/exam.recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { EXAM_FETCHING, EXAM_UPDATING } from '../config/loading.config';
import useExamId from './useExamId.hook';
import useStoredResponses from './useStoredResponses.hook';

const fetchRetry = async (url, options = {}, tries = 3) => {
    for (let i = 0; i < tries; i++) {
        try {
            const ref = await fetch(url, options);
            if (ref.status !== 200)
                throw new Error(ref.message);
            return ref;
        } catch(err) {
            if (tries <= 1)
                throw err;
            await new Promise(resolve => setTimeout(resolve, 2000));
            return fetchRetry(url, options, tries - 1);
        }
    }
};

export const useExamsData = examId => {
    const history = useHistory();
    const id = useExamId(examId);
    const [ loading, setLoading ] = useRecoilState(loadingAtomFamily(EXAM_FETCHING));
    const [ updating, setUpdating ] = useRecoilState(loadingAtomFamily(EXAM_UPDATING));
    const userToken = useRecoilValue(userTokenAtom);
    const examination = useRecoilValue(getExaminationSelectorFamily(id));
    const setStatus = useSetRecoilState(statusAtomFamily(id));
    const setExam = useSetRecoilState(examAtomFamily(id));
    const setTimer = useSetRecoilState(timerAtomFamily(id));
    const resetExam = useSetRecoilState(resetExamSelectorFamily(id));
    const { setStoredResponses } = useStoredResponses(id);
    
    const get = async () => {
        if (loading) return { success: false, message: 'already loading' };
        setLoading(true);
        if (!userToken || !examination) {
            setLoading(false);
            return { success: false, message: 'no userToken or no examination' };
        }
        const ref = await fetch(`${API_URL}/exams/${id}/get`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        if (ref.status === 200) {
            const json = await ref.json();
            resetExam();
            setExam(json);
            setTimer(Date.now());
            setLoading(false);
            return { success: true };
        } else if (ref.status === 402) {
            setLoading(false);
            return { success: false, message: 'Payment required' };
        } else {
            setLoading(false);
            return { success: false, message: ref.message };
        }
    };

    const set = async (responses, redirect = '') => {
        if (updating) return { success: false, message: 'already updating' };
        setUpdating(true);
        if (!userToken || !examination) {
            setUpdating(false);
            return { success: false, message: 'no userToken or no examination' };
        }
        try {
            const ref = await fetchRetry(`${API_URL}/exams/${id}/set-responses`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(responses),
            });
            const json = await ref.json();
            setStatus(json);
            resetExam();
            setUpdating(false);
            if (redirect) {
                window.scrollTo(0, 0);
                history.push(redirect);
            }
            return { success: true };
        } catch(err) {
            setStoredResponses(responses);
            resetExam();
            setUpdating(false);
            if (redirect) {
                window.scrollTo(0, 0);
                history.push(redirect);
            }
            return { success: false, message: 'Failure to update exam. Responses stored locally and will resubmit on restored connection.' };
        }
    };

    return { getExam: get, setExam: set };
};

export default useExamsData;