import { useRecoilValue } from 'recoil';
import { userIdAtom } from '../recoil/user.recoil';
import useExamId from './useExamId.hook';

const useStoredResponses = examId => {
    const userId = useRecoilValue(userIdAtom);
    const id = useExamId(examId);

    const get = () => localStorage.getItem(`${userId}:${id}`);

    const set = responses => localStorage.setItem(`${userId}:${id}`, JSON.stringify(responses));

    const remove = () => localStorage.removeItem(`${userId}:${id}`);

    const isStoredResponses = () => get() !== null;

    return { isStoredResponses, getStoredResponses: get, setStoredResponses: set, removeStoredResponses: remove };
};

export default useStoredResponses;