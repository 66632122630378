import { useSetRecoilState } from 'recoil';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { userIdAtom, userTokenAtom, userDataAtom } from '../recoil/user.recoil';
import { createUserProfileDocument, getCurrentUser } from '../utils/firebase.utils';
import { USER_FETCHING } from '../config/loading.config';

const useUserData = () => {
    const setLoading = useSetRecoilState(loadingAtomFamily(USER_FETCHING));
    const setUserId = useSetRecoilState(userIdAtom);
    const setUserToken = useSetRecoilState(userTokenAtom);
    const setUserData = useSetRecoilState(userDataAtom);

    const get = async () => {
        setLoading(true);
        const user = await getCurrentUser();
        if (!user) {
            setLoading(false);
            return;
        }
        const userRef = await createUserProfileDocument(user);
        const token = await user.getIdToken();
        const snapShot = await userRef.get();
        const id = snapShot.id;
        const userData = snapShot.data();
        setUserId(id);
        setUserToken(token);
        setUserData(userData);
        setLoading(false);
    };

    const refresh = async () => {
        const user = await getCurrentUser();
        if (!user) return;
        const userRef = await createUserProfileDocument(user);
        const token = await user.getIdToken();
        const snapShot = await userRef.get();
        const id = snapShot.id;
        const userData = snapShot.data();
        setUserId(id);
        setUserToken(token);
        setUserData(userData);
    };

    return { getUserData: get, refreshUserData: refresh };
};

export default useUserData;