import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageHeading } from '../styled/global.styled';
import ResetPasswordForm from '../components/resetPassword.component';
import { IsAuthenticated, IsNotAuthenticated } from '../components/conditionals.component';

const ResetPasswordPage = () => {
  return <>
    <IsAuthenticated>
      <Redirect to='/' />
    </IsAuthenticated>
    <IsNotAuthenticated>
      <PageHeading>Reset Password</PageHeading>
      <ResetPasswordForm />
    </IsNotAuthenticated>
  </>
};

export default ResetPasswordPage;