import React from 'react';
import { PageHeading } from '../styled/global.styled';
import ContactForm from '../components/contact.component';
import TrainingProviderSubscriber from '../subscribers/trainingProviders.subscriber';

const ContactPage = () => {
    return <>
        <TrainingProviderSubscriber />
        <PageHeading>Contact</PageHeading>
        <ContactForm />
    </>
};

export default ContactPage;