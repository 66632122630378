import styled from 'styled-components';
import { COLORS } from '../config/constants.config';

export const McqContent = styled.div`
margin-bottom: 20px;
padding: 5px 0;
background-color: rgba(${COLORS.backgroundColor.r}, ${COLORS.backgroundColor.g}, ${COLORS.backgroundColor.b}, 0.5);
`;

export const McqSection = styled.div`
margin-top: 0;
padding-top: 0;
border-bottom: 1px solid rgba(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b}, 0.2);
&:last-child {
    border-bottom: 0;
}
`;

export const McqQuestion = styled.div`
margin-top: 0;
padding-top: 0;
`;

export const McqQuestionNum = styled.h4`
`;

export const McqQuestionText = styled.p`
`;

export const McqResponses = styled.ul`
margin-left: 0;
padding-left: 0;
list-style: none;
`;

export const McqResponse = styled.li`
padding-right: 10px;
padding-left: 10px;
&:hover,
&:focus,
&:active {
    background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
}
span,
span:hover,
span:focus,
span:active {
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: transparent;
}
span:first-child {
    width: 30px;
}
span:last-child {
    width: calc(100% - 30px);
    text-align: left;
}
${props => props.checked ? `
background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
` : ``}
`;