import styled from 'styled-components';
import { COLORS } from '../config/constants.config';

export const Container = styled.div`
padding: 10px 0;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
`;

export const Label = styled.label`
width: 100%;
display: flex;
align-items: flex-start;
`;

export const Prop = styled.span`
width: 50%;
font-weight: bold;
display: inline-flex;
align-items: center;
cursor: pointer;
`;

export const Value = styled.span`
width: 50%;
display: inline-flex;
justify-content: space-between;
align-items: center;
`;

export const Input = styled.input`
box-sizing: border-box;
width: 100%;
margin: 0;
padding: 5px 10px;
border: 1px solid rgb(${COLORS.text.r}, ${COLORS.text.g}, ${COLORS.text.b});
font-family: 'Open Sans', sans-serif;
`;

export const InputText = styled(Input)`
`;

export const InputTextArea = styled.textarea`
box-sizing: border-box;
width: 100%;
min-height: 100px;
margin: 0;
padding: 5px 10px;
border: 1px solid rgb(${COLORS.text.r}, ${COLORS.text.g}, ${COLORS.text.b});
font-family: 'Open Sans', sans-serif;
`;

export const InputSelect = styled.select`
width: 100%;
margin: 0;
padding: 5px 10px;
border: 1px solid rgb(${COLORS.text.r}, ${COLORS.text.g}, ${COLORS.text.b});
font-family: 'Open Sans', sans-serif;
cursor: pointer;
`;

export const InputCheckbox = styled.input`
margin: 5px 0;
`;

export const InputRadioButton = styled.input`
margin: 5px 0;
`;

export const FormError = styled.div`
margin-bottom: -10px;
padding: 5px;
color: white;
background-color: red;
`;