import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import StickyBox from 'react-sticky-box';
import { IsExaminationNotAttempted, IsExaminationFailed, IsExaminationPassed, IsExaminationSuspended, IsExamPreamble, IsExamOutro, IsExamEssay, IsExamMcq, IsExaminationExpired, IsNotExaminationStoredResponses, IsExaminationStoredResponses } from '../components/conditionals.component';
import { timerAtomFamily } from '../recoil/exam.recoil';
import { getExaminationTitleSelectorFamily, getCorrespondingAssessmentSelectorFamily, getExaminationAttemptsRemainingSelectorFamily } from '../recoil/examinations.recoil';
import { ExamBoxOut, ExamContainer, ExamArticle, ExamNavigation, ExamProgress, ExamButtons } from '../styled/exam.styled';
import CandidateNumberForm from '../components/candidateNumber.component';
import { ButtonNext } from '../components/button.component';
import Essay from '../content/essay.content';
import Mcq from '../content/mcq.content';
import { convertMilliseconds } from '../utils/date.utils';
import UrlChangeObserver from '../components/urlChangeObserver.component';
import { COMPETENCY_PDFS, EXAMINATION_PASS_PERCENTAGE } from '../config/constants.config';

const Exam = () => {
    return <>
        <UrlChangeObserver />
        <IsExamPreamble>
            <Preamble />
        </IsExamPreamble>
        <IsExamEssay>
            <Essay />
        </IsExamEssay>
        <IsExamMcq>
            <Mcq />
        </IsExamMcq>
        <IsExamOutro>
            <Outro />
        </IsExamOutro>
    </>
};

const Preamble = () => {
    const { examId } = useParams();
    const title = useRecoilValue(getExaminationTitleSelectorFamily(examId));
    return <>
        <h2>{title}</h2>
        <p><strong>This examination takes the form of three redacted essays and a multiple choice questionnaire.<br />
           You must score {EXAMINATION_PASS_PERCENTAGE * 100}% or above in each section to pass the examination.</strong></p>
        <h3>Essay</h3>
        <p>Each essay will have a number of words replaced with placeholders. You will be given a pool of words (including some red herrings) which you can click on. Click on a placeholder to assign the selected word to it. Click on a filled placeholder to return its word to the pool, or select another word and click on a filled placeholder to swap it. Fill all placeholders with words to finish the section.</p>
        <h3>MCQ</h3>
        <p>The multiple choice questionnaire is comprised of both single answer and multiple answer questions. Complete all questions to finish the section.</p>
        <p><strong>You must complete the examination in a single sitting. If you close the browser or navigate away from the exam page, you will fail the examination.</strong></p>
        <ExamBoxOut>
            <h3>Examination Terms</h3>
            <p>By continuing with this examination I hereby confirm that:</p>
            <ul>
                <li>I am the named person on this account and I am not completing this exam on behalf of another person.</li>
                <li>I will not be receiving any assistance from another person, printed material or online resource whilst undertaking this examination.</li>
                <li>I will complete this examination in a single sitting and understand that I will need to sit the entire examination again if I run out of time.</li>
                <li>I will not make any screenshots, printouts or videos of the examination, nor will I share any of the examination contents with any third party under any circumstances.</li>
                <li>I agree to be held liable without recourse for a minimum fine of £50,000 if I am in breach of any of the above conditions.</li>
                <li>I understand that my interaction with the examination, which includes but is not limited to keystrokes, mouse clicks and timings, will be monitored and recorded solely for exam analysis purposes and that I have granted consent to the QCCP to use this data for this purpose without restriction.</li>
            </ul>
            <CandidateNumberForm />
        </ExamBoxOut>
    </>
};

const Outro = () => {
    return <>
        <IsExaminationStoredResponses>
            <NotSubmitted />
        </IsExaminationStoredResponses>
        <IsNotExaminationStoredResponses>
            <IsExaminationNotAttempted>
                <NotAttempted />
            </IsExaminationNotAttempted>
            <IsExaminationExpired>
                <Expired />
            </IsExaminationExpired>
            <IsExaminationFailed>
                <Failed />
            </IsExaminationFailed>
            <IsExaminationPassed>
                <Completed />
            </IsExaminationPassed>
            <IsExaminationSuspended>
                <Suspended />
            </IsExaminationSuspended>
        </IsNotExaminationStoredResponses>
    </>
};

const NotSubmitted = () => {
    return <>
        <h2>Submission Pending</h2>
        <p>There was a problem submitting your results. This could be due to your connection or the server not responding.</p>
        <p>Your exam responses have been stored locally and will be re-submitted when the connection is re-established.</p>
        <BackButton />
    </>
};

const NotAttempted = () => {
    const { examId } = useParams();
    const title = useRecoilValue(getExaminationTitleSelectorFamily(examId));
    return <>
        <h2>{title} Not Attempted</h2>
        <p>You have not yet attempted this examination.</p>
        <BackButton />
    </>
};

const Expired = () => {
    const { examId } = useParams();
    const title = useRecoilValue(getExaminationTitleSelectorFamily(examId));
    return <>
        <h2>{title} Expired</h2>
        <p>You exceeded the amount of time allowed for the examination.</p>
        <BackButton />
    </>
};

const Failed = () => {
    const { examId } = useParams();
    const title = useRecoilValue(getExaminationTitleSelectorFamily(examId));
    const assessment = useRecoilValue(getCorrespondingAssessmentSelectorFamily(examId));
    const attemptsRemaining = useRecoilValue(getExaminationAttemptsRemainingSelectorFamily(examId));
    return <>
        <h2>{title} Failed</h2>
        <p>You failed to complete the examination on this attempt. You have <strong>{attemptsRemaining}</strong> attempt{attemptsRemaining > 1 ? 's' : ''} remaining.</p>
        <p>Please review the <Link to={`/self-assessments/assessment/${assessment.id}`}>self-assessment</Link> and the <a target='_blank' rel='noopener noreferrer' href={COMPETENCY_PDFS[assessment.id]}>competency standard</a> before trying again.</p>
        <BackButton />
    </>
};

const Completed = () => {
    const { examId } = useParams();
    const title = useRecoilValue(getExaminationTitleSelectorFamily(examId));
    return <>
        <h2>{title} Completed</h2>
        <p>Congratulations - you completed the examination.</p>
        <p>Your examination result will now be submitted to the regulator RSPH for moderation to ensure your registration with your statutory body is active without any restrictions to practice.</p>
        <p>You will be notified of the examination outcome once the moderation process is complete.</p>
        <BackButton />
    </>
};

const Suspended = () => {
    const { examId } = useParams();
    const title = useRecoilValue(getExaminationTitleSelectorFamily(examId));
    return <>
        <h2>{title} Suspended</h2>
        <p>The exam has been suspended because you failed it three times.</p>
        <p>Contact your training provider to review your case.</p>
    </>
};

export const Container = ({ children }) => {
    return <ExamContainer>
        {children}
    </ExamContainer>
};

export const Article = ({ children }) => {
    return <ExamArticle>
        {children}
    </ExamArticle>
};

export const Navigation = ({ children }) => {
    return <ExamNavigation as={StickyBox} offsetTop={20} offsetBottom={20}>
        {children}
    </ExamNavigation>
};

export const Buttons = ({ children }) => {
    return <ExamButtons>
        {children}
    </ExamButtons>
};

export const Timer = () => {
    const history = useHistory();
    const { examId } = useParams();
    const [ minutes, setMinutes ] = useState(0);
    const timer = useRecoilValue(timerAtomFamily(examId));
    const allowedTime = 2 * 60 * 60 * 1000;
    const elapsedTime = Date.now() - timer;
    const time = convertMilliseconds(timer + allowedTime - Date.now() + 60 * 1000);
    const timeRemaining = `${time.hours > 0 ? `${time.hours} hour${time.hours !== 1 ? `s` : ``} ` : ``} ${time.minutes} min${time.minutes !== 1 ? `s` : ``} remaining`;
    const indicatorStyle = { width: `${Math.round((allowedTime - elapsedTime) / allowedTime * 100)}%` };

    useEffect(() => {
        if (elapsedTime > allowedTime) {
            window.scrollTo(0, 0);
            history.push(`/examinations/exam/${examId}/5`);
        }
    }, [allowedTime, elapsedTime]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setMinutes(secs => secs + 1);
        }, 60 * 1000);
        return () => clearTimeout(timeout);
    }, [minutes]);

    return <ExamProgress>
        <div style={indicatorStyle} />
        <span>{timeRemaining}</span>
    </ExamProgress>
};

export const ProgressBar = ({ value, total }) => {
    const indicatorStyle = { width: `${value / total * 100}%` };
    return <ExamProgress>
        <div style={indicatorStyle} />
        <span>{value} / {total} completed</span>
    </ExamProgress>
};

const BackButton = () => {
    return <p><ButtonNext as={Link} to={`/examinations`}>Continue</ButtonNext></p>
};

export default Exam;