import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import formReducer from '../reducers/form.reducer';
import useAuthentication from '../hooks/useAuthentication.hook';
import Button from './button.component';
import { Text } from './forms.component';
import { FormError } from '../styled/forms.styled';

const initialState = {
    email: '',
    errors: {},
};
  
const ResetPasswordForm = () => {
    const [state, dispatch] = useReducer(formReducer, initialState);
    const { resetPassword } = useAuthentication();
    const changeHandler = e => dispatch({ type: 'update', payload: e.target });
    const submitHandler = async e => {
      e.preventDefault();
      const { email } = state;
      const errors = {};
      if (email === '')
        errors['email'] = 'Please enter your email address';
      if (Object.keys(errors).length) {
        dispatch({ type: 'setErrors', payload: errors });
      } else {
        const result = await resetPassword(email);
        if (result.success) {
          errors['message'] = result.message;
          dispatch({ type: 'update', payload: { name: 'email', value: '' } });
          dispatch({ type: 'setErrors', payload: errors });
        } else {
          errors['message'] = result.message;
          dispatch({ type: 'setErrors', payload: errors });
        }
      }
    };
    return <>
      <p>Enter your email address to send yourself a password reset email.</p>
      <form onSubmit={submitHandler}>
        {state.errors.message && <FormError>{state.errors.message}</FormError>}
        <Text name='email' error={state.errors.email} value={state.email} type='email' label='Email' placeholder='Email address' onChange={changeHandler} />
        <Button type='submit'>Reset Password</Button>
        <p><Link to="/">Sign in to your account</Link></p>
        <p><Link to="/sign-up">I do not have an account</Link></p>
      </form>
    </>
};

export default ResetPasswordForm;