import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, StripeButton } from '../styled/global.styled';

export const ButtonPrev = ({ children, ...props }) => {
    return <Button {...props}>
        <FontAwesomeIcon icon={faArrowCircleLeft} />
        <span>{children}</span>
    </Button>
};

export const ButtonNext = ({ children, ...props }) => {
    return <Button {...props}>
        <span>{children}</span>
        <FontAwesomeIcon icon={faArrowCircleRight} />
    </Button>
};

export const ButtonOpen = forwardRef(({ children, ...props }, ref) => {
    return <Button ref={ref} {...props}>
        <FontAwesomeIcon icon={faInfoCircle} />
        <span>{children}</span>
    </Button>
});

export const ButtonStripe = forwardRef(({ children, ...props }, ref) => {
    return <StripeButton ref={ref} {...props}>
        {children}
    </StripeButton>
});

const CustomButton = ({ children, ...props }) => {
    return <Button {...props}>
        <FontAwesomeIcon icon={faArrowCircleRight} />
        <span>{children}</span>
    </Button>
};

export default CustomButton;