import { atom, atomFamily, selector, selectorFamily } from 'recoil';

export const assessmentsAtom = atom({
  key: 'assessments',
  default: [],
});

export const sectionsAtomFamily = atomFamily({
  key: 'sections',
  default: [],
});

export const progressAtomFamily = atomFamily({
  key: 'progress',
  default: {},
});

export const isPanelOpenAtom = atom({
  key: 'isPanelOpen',
  default: false,
});

// get
export const getAssessmentSelectorFamily = selectorFamily({
  key: 'assessment',
  get: id => ({ get }) => get(assessmentsAtom).find(_ => _.id === id),
});

export const getSectionsFlattenedSelectorFamily = selectorFamily({
  key: 'sectionsFlattened',
  get: id => ({ get }) => {
    const sections = get(sectionsAtomFamily(id));
    const flattenend = [];
    for (let [sectionIdx, section] of sections.entries()) {
      if (section.subsections) {
        for (let [subSectionIdx, subSection] of section.subsections.entries()) {
          for (let [questionIdx, question] of subSection.questions.entries()) {
            flattenend.push({
              title: section.title,
              subtitle: subSection.title,
              section: sectionIdx + 1,
              subsection: subSectionIdx + 1,
              preamble: subSection.preamble,
              question: question.questionText,
              questionNum: questionIdx + 1,
            });
          }
        }
      } else {
        for (let [questionIdx, question] of section.questions.entries()) {
          flattenend.push({
            title: section.title,
            section: sectionIdx + 1,
            preamble: section.preamble,
            question: question.questionText,
            questionNum: questionIdx + 1,
          });
        }
      }
    }
    return flattenend;
  },
});

export const getProgressStatusSelectorFamily = selectorFamily({
  key: 'getProgressStatus',
  get: id => ({ get }) => {
    const progress = get(progressAtomFamily(id));
    if (Object.keys(progress).length !== 0 && progress.status)
      return progress.status;
    return '';
  },
});

export const getAssessmentByExamIdSelectorFamily = selectorFamily({
  key: 'getAssessmentByExamId',
  get: examId => ({ get }) => get(assessmentsAtom).find(_ => _.examId === examId),
});

export const getAssessmentTitleSelectorFamily = selectorFamily({
  key: 'getAssessmentTitle',
  get: id => ({ get }) => {
    const assessment = get(getAssessmentSelectorFamily(id));
    if (assessment && assessment.title)
      return assessment.title;
    return '';
  },
});

export const getAssessmentPreambleSelectorFamily = selectorFamily({
  key: 'getAssessmentPreamble',
  get: id => ({ get }) => {
    const assessment = get(getAssessmentSelectorFamily(id));
    if (assessment && assessment.preamble)
      return assessment.preamble;
    return '';
  },
});

export const getCompetencyStandardSelectorFamily = selectorFamily({
  key: 'getCompetencyStandard',
  get: id => ({ get }) => {
    const assessment = get(getAssessmentSelectorFamily(id));
    if (assessment && assessment.overview)
      return assessment.overview;
    return '';
  },
});

export const getLearningNeedsSelectorFamily = selectorFamily({
  key: 'getLearningNeeds',
  get: id => ({ get }) => {
    const sections = get(sectionsAtomFamily(id));
    const { responses } = get(progressAtomFamily(id));
    let questionIdx = 0;
    let sectionIdx = 0;
    let content = [];
    if (sections.length) {
      for (let section of sections) {
        let sectionCount = 0;
        const sectionContent = [];
        if (section.subsections && section.subsections.length) {
          sectionContent.push({ type: 'sectionTitle', value: `${sectionIdx + 1}. ${section.title}`});
          sectionIdx++;
          let subSectionIdx = 0;
          let subSectionCount = 0;
          let subSectionTitle = '';
          const subSectionContent = [];
          for (let subSection of section.subsections) {
            subSectionIdx++;
            subSectionTitle = { type: 'subSectionTitle', value: `${subSectionIdx}. ${subSection.title}`};
            let questionNum = 1;
            let questionsCount = 0;
            const questionContent = [];
            for (let question of subSection.questions) {
              if (responses[questionIdx] === "learningNeedIdentified") {
                questionContent.push({ type: 'question', value: { idx: questionIdx, num: questionNum, text: question.questionText } });
                subSectionCount++;
                questionsCount++;
              }
              questionIdx++;
              questionNum++;
            }
            if (questionsCount) {
              subSectionContent.push(subSectionTitle);
              subSectionContent.push(...questionContent);
            }
          }
          if (subSectionCount) {
            content.push(...sectionContent);
            content.push(...subSectionContent);
          }
        } else {
          sectionContent.push({ type: 'sectionTitle', value: `${sectionIdx + 1}. ${section.title}`});
          sectionIdx++;
          let questionNum = 1;
          for (let question of section.questions) {
            if (responses[questionIdx] === "learningNeedIdentified") {
              sectionContent.push({ type: 'question', value: { idx: questionIdx + 1, num: questionNum, text: question.questionText } });
              sectionCount++;
            }
            questionIdx++;
            questionNum++;
          }
          if (sectionCount)
            content.push(...sectionContent);
        }
      }
    }
    return content;
  },
});

// conditionals
export const isNoAssessmentsAttemptedSelector = selector({
  key: 'isNoAssessmentsAttempted',
  get: ({ get }) => {
    let found = true;
    const assessments = get(assessmentsAtom);
    for (let assessment of assessments) {
      const progress = get(progressAtomFamily(assessment.id));
      if (progress.status !== 'notAttempted') {
        found = false;
        break;
      }
    }
    return found;
  },
});

export const isAtLeastOneAssessmentAttemptedSelector = selector({
  key: 'isAtLeastOneAssessmentAttempted',
  get: ({ get }) => {
    let found = false;
    const assessments = get(assessmentsAtom);
    for (let assessment of assessments) {
      const progress = get(progressAtomFamily(assessment.id));
      if (Object.keys(progress).length !== 0 && progress.status !== 'notAttempted') {
        found = true;
        break;
      }
    }
    return found;
  },
});

export const isAtLeastOneAssessmentPassedSelector = selector({
  key: 'isAtLeastOneAssessmentPassed',
  get: ({ get }) => {
    let found = false;
    const assessments = get(assessmentsAtom);
    for (let assessment of assessments) {
      const progress = get(progressAtomFamily(assessment.id));
      if (Object.keys(progress).length !== 0 && progress.status === 'passed') {
        found = true;
        break;
      }
    }
    return found;
  },
});

export const isAssessmentSelectorFamily = selectorFamily({
  key: 'isAssessment',
  get: id => ({ get }) => {
    if (get(getAssessmentSelectorFamily(id)))
      return true;
    return false;
  },
});

export const isAssessmentSectionsSelectorFamily = selectorFamily({
  key: 'isAssessmentSections',
  get: id => ({ get }) => {
    const sections = get(sectionsAtomFamily(id));
    if (sections && sections.length !== 0)
      return true;
    return false;
  },
});

export const isAssessmentProgressSelectorFamily = selectorFamily({
  key: 'isAssessmentProgress',
  get: id => ({ get }) => {
    const progress = get(progressAtomFamily(id));
    if (progress && Object.keys(progress).length !== 0)
      return true;
    return false;
  },
});

export const isAssessmentNotAttemptedSelectorFamily = selectorFamily({
  key: 'isAssessmentNotAttempted',
  get: id => ({ get }) => get(getProgressStatusSelectorFamily(id)) === 'notAttempted' ? true : false,
});

export const isAssessmentIncompleteSelectorFamily = selectorFamily({
  key: 'isAssessmentIncomplete',
  get: id => ({ get }) => get(getProgressStatusSelectorFamily(id)) === 'incomplete' ? true : false,
});

export const isAssessmentFailedSelectorFamily = selectorFamily({
  key: 'isAssessmentFailed',
  get: id => ({ get }) => get(getProgressStatusSelectorFamily(id)) === 'failed' ? true : false,
});

export const isAssessmentPassedSelectorFamily = selectorFamily({
  key: 'isAssessmentPassed',
  get: id => ({ get }) => get(getProgressStatusSelectorFamily(id)) === 'passed' ? true : false,
});