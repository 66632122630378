import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { API_URL } from '../config/constants.config';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import { getExaminationSelectorFamily, statusAtomFamily } from '../recoil/examinations.recoil';
import useExamId from '../hooks/useExamId.hook';
import { DELAY_RESPONSE, STATUS_FETCHING, STATUS_UPDATING } from '../config/loading.config';

const useStatusData = examId => {
    const id = useExamId(examId);
    const [ loading, setLoading ] = useRecoilState(loadingAtomFamily(`${STATUS_FETCHING}:${id}`));
    const [ updating, setUpdating ] = useRecoilState(loadingAtomFamily(`${STATUS_UPDATING}:${id}`));
    const userToken = useRecoilValue(userTokenAtom);
    const examination = useRecoilValue(getExaminationSelectorFamily(examId));
    const setStatus = useSetRecoilState(statusAtomFamily(examId));

    const get = async () => {
        if (loading) return;
        setLoading(true);
        if (!userToken || !examination) {
            DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
            return;
        };
        const ref = await fetch(`${API_URL}/exams/${examId}/status`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        if (ref.status !== 200) {
            setStatus({
                examId,
                status: "error",
            });
        } else {
            const json = await ref.json();
            setStatus(json);
        }
        DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
    };

    const set = async () => {
        setUpdating(true);

        console.log('useStatusData.setStatus()');

        DELAY_RESPONSE ? setTimeout(() => setUpdating(false), 1000) : setUpdating(false);
    };

    return { getStatus: get, setStatus: set };
};

export default useStatusData;