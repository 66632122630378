import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getLearningNeedsSelectorFamily } from '../recoil/assessments.recoil';
import { QuestionContainer, QuestionNum, QuestionText } from '../styled/learningNeeds.styled';

const SectionTitle = ({ children }) => {
  return <h3>{children}</h3>
};

const SubSectionTitle = ({ children }) => {
  return <p><strong>{children}</strong></p>
};

const Question = props => {
  const { assessmentId } = useParams();
  return <QuestionContainer>
    <Num>{props.num}</Num>
    <Text to={`/self-assessments/assessment/${assessmentId}/${props.idx}`}>{props.text}</Text>
  </QuestionContainer>
};

const Num = ({ children }) => {
  return <QuestionNum>{children}.</QuestionNum>
};

const Text = ({ children, ...props }) => {
  return <QuestionText dangerouslySetInnerHTML={{ __html: children }} {...props} />
};

export const LearningNeeds = () => {
  const { assessmentId } = useParams();
  const learningNeeds = useRecoilValue(getLearningNeedsSelectorFamily(assessmentId));
  return <>
    {learningNeeds.map(({ type, value }, idx) => {
      switch(type) {
        case 'sectionTitle':
          return <SectionTitle key={`LearningNeeds/${assessmentId}/${idx}`}>{value}</SectionTitle>
        case 'subSectionTitle':
          return <SubSectionTitle key={`LearningNeeds/${assessmentId}/${idx}`}>{value}</SubSectionTitle>
        case 'question':
          return <Question key={`LearningNeeds/${assessmentId}/${idx}`} {...value} />
        default:
            return null;
      }
    })}
  </>
};