import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { isAuthenticatedSelector, isProfessionLockedSelector, getUserCandidateNumberSelector } from '../recoil/user.recoil';
import { getAssessmentSelectorFamily, isAtLeastOneAssessmentAttemptedSelector, isAtLeastOneAssessmentPassedSelector, isAssessmentProgressSelectorFamily, isAssessmentNotAttemptedSelectorFamily, isAssessmentIncompleteSelectorFamily, isAssessmentFailedSelectorFamily, isAssessmentPassedSelectorFamily, isAssessmentSectionsSelectorFamily, isAssessmentSelectorFamily, isNoAssessmentsAttemptedSelector } from '../recoil/assessments.recoil';
import { isAtLeastOneExaminationUnlockedSelector, statusAtomFamily, isExaminationSelectorFamily, isCorrespondingAssessmentSelectorFamily, isExaminationStatusSelectorFamily, isAtLeastOneExaminationSubmittedSelector } from '../recoil/examinations.recoil';
import { isTimerExpiredSelectorFamily } from '../recoil/exam.recoil';
import useAssessmentId from '../hooks/useAssessmentId.hook';
import useExamId from '../hooks/useExamId.hook';
import useStoredResponses from '../hooks/useStoredResponses.hook';
import { USER_FETCHING, ASSESSMENTS_FETCHING, SECTIONS_FETCHING, PROGRESS_FETCHING, PROGRESS_UPDATING, EXAMINATIONS_FETCHING, STATUS_FETCHING, STATUS_UPDATING } from '../config/loading.config';

// pages
export const IsHome = ({ children }) => {
    const location = useLocation();
    if (location.pathname === '/')
      return children;
    else
      return null;
};

export const IsNotHome = ({ children }) => {
    const location = useLocation();
    if (location.pathname !== '/')
      return children;
    else
      return null;
};

// loading
export const IsUserLoading = ({ children }) => {
  if (useRecoilValue(loadingAtomFamily(USER_FETCHING)))
    return children;
  else
    return null;
};

export const IsNotUserLoading = ({ children }) => {
  if (!useRecoilValue(loadingAtomFamily(USER_FETCHING)))
    return children;
  else
    return null;
};

export const IsAssessmentsLoading = ({ children }) => {
  if (useRecoilValue(loadingAtomFamily(ASSESSMENTS_FETCHING)))
      return children;
  else
      return null;
};

export const IsNotAssessmentsLoading = ({ children }) => {
  if (!useRecoilValue(loadingAtomFamily(ASSESSMENTS_FETCHING)))
      return children;
  else
      return null;
};

export const IsSectionsLoading = ({ assessmentId, children }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(loadingAtomFamily(`${SECTIONS_FETCHING}:${id}`)))
      return children;
  else
      return null;
};

export const IsNotSectionsLoading = ({ assessmentId, children }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(loadingAtomFamily(`${SECTIONS_FETCHING}:${id}`)))
      return children;
  else
      return null;
};

export const IsProgressLoading = ({ assessmentId, children }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(loadingAtomFamily(`${PROGRESS_FETCHING}:${id}`)))
      return children;
  else
      return null;
};

export const IsNotProgressLoading = ({ assessmentId, children }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(loadingAtomFamily(`${PROGRESS_FETCHING}:${id}`)))
      return children;
  else
      return null;
};

export const IsProgressUpdating = ({ assessmentId, children }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(loadingAtomFamily(`${PROGRESS_UPDATING}:${id}`)))
      return children;
  else
      return null;
};

export const IsNotProgressUpdating = ({ assessmentId, children }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(loadingAtomFamily(`${PROGRESS_UPDATING}:${id}`)))
      return children;
  else
      return null;
};

export const IsExaminationsLoading = ({ children }) => {
  if (useRecoilValue(loadingAtomFamily(EXAMINATIONS_FETCHING)))
      return children;
  else
      return null;
};

export const IsNotExaminationsLoading = ({ children }) => {
  if (!useRecoilValue(loadingAtomFamily(EXAMINATIONS_FETCHING)))
      return children;
  else
      return null;
};

export const IsStatusLoading = ({ examId, children }) => {
  const id = useExamId(examId);
  if (useRecoilValue(loadingAtomFamily(`${STATUS_FETCHING}:${id}`)))
      return children;
  else
      return null;
};

export const IsNotStatusLoading = ({ examId, children }) => {
  const id = useExamId(examId);
  if (!useRecoilValue(loadingAtomFamily(`${STATUS_FETCHING}:${id}`)))
      return children;
  else
      return null;
};

export const IsStatusUpdating = ({ examId, children }) => {
  const id = useExamId(examId);
  if (useRecoilValue(loadingAtomFamily(`${STATUS_UPDATING}:${id}`)))
      return children;
  else
      return null;
};

export const IsNotStatusUpdating = ({ examId, children }) => {
  const id = useExamId(examId);
  if (!useRecoilValue(loadingAtomFamily(`${STATUS_UPDATING}:${id}`)))
      return children;
  else
      return null;
};

// global
export const IsNoAssessmentsAttempted = ({ children }) => {
  if (useRecoilValue(isNoAssessmentsAttemptedSelector))
    return children;
  else
    return null;
};

export const IsAtLeastOneAssessmentAttempted = ({ children }) => {
  if (useRecoilValue(isAtLeastOneAssessmentAttemptedSelector))
    return children;
  else
    return null;
};

export const IsNotAtLeastOneAssessmentAttempted = ({ children }) => {
    if (!useRecoilValue(isAtLeastOneAssessmentAttemptedSelector))
      return children;
    else
      return null;
};

export const IsAtLeastOneAssessmentPassed = ({ children }) => {
    if (useRecoilValue(isAtLeastOneAssessmentPassedSelector))
      return children;
    else
      return null;
};

export const IsNotAtLeastOneAssessmentPassed = ({ children }) => {
    if (!useRecoilValue(isAtLeastOneAssessmentPassedSelector))
      return children;
    else
      return null;
};

export const IsAtLeastOneExaminationUnlocked = ({ children }) => {
    if (useRecoilValue(isAtLeastOneExaminationUnlockedSelector))
      return children;
    else
      return null;
};

export const IsNotAtLeastOneExaminationUnlocked = ({ children }) => {
    if (!useRecoilValue(isAtLeastOneExaminationUnlockedSelector))
      return children;
    else
      return null;
};

export const IsAtLeastOneExaminationSubmitted = ({ children }) => {
  if (useRecoilValue(isAtLeastOneExaminationSubmittedSelector))
    return children;
  else
    return null;
};

export const IsNotAtLeastOneExaminationSubmitted = ({ children }) => {
  if (!useRecoilValue(isAtLeastOneExaminationSubmittedSelector))
    return children;
  else
    return null;
};

// user
export const IsAuthenticated = ({ children }) => {
  if (useRecoilValue(isAuthenticatedSelector))
      return children;
  else
      return null;
};

export const IsNotAuthenticated = ({ children }) => {
  if (!useRecoilValue(isAuthenticatedSelector))
      return children;
  else
      return null;
};

export const IsProfessionLocked = ({ children }) => {
  if (useRecoilValue(isProfessionLockedSelector))
      return children;
  else
      return null;
};

export const IsNotProfessionLocked = ({ children }) => {
  if (!useRecoilValue(isProfessionLockedSelector))
      return children;
  else
      return null;
};

export const IsCandidateNumber = ({ children }) => {
  if (useRecoilValue(getUserCandidateNumberSelector))
    return children;
  else
    return null;
};

export const IsNotCandidateNumber = ({ children }) => {
  if (!useRecoilValue(getUserCandidateNumberSelector))
    return children;
  else
    return null;
};

// assessments
export const IsAssessment = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(isAssessmentSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNotAssessment = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(isAssessmentSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsAssessmentSections = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(isAssessmentSectionsSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNotAssessmentSections = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(isAssessmentSectionsSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsAssessmentProgress = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(isAssessmentProgressSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNotAssessmentProgress = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(isAssessmentProgressSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsAssessmentAttempted = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(isAssessmentNotAttemptedSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNotAssessmentAttempted = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(isAssessmentNotAttemptedSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsAssessmentIncomplete = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(isAssessmentIncompleteSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNotAssessmentIncomplete = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(isAssessmentIncompleteSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsAssessmentFailed = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(isAssessmentFailedSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNotAssessmentFailed = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(isAssessmentFailedSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsAssessmentPassed = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (useRecoilValue(isAssessmentPassedSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNotAssessmentPassed = ({ children, assessmentId }) => {
  const id = useAssessmentId(assessmentId);
  if (!useRecoilValue(isAssessmentPassedSelectorFamily(id)))
    return children;
  else
    return null;
};

// examinations
export const IsExamination = ({ children, examId }) => {
  const id = useExamId(examId);
  if (useRecoilValue(isExaminationSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNotExamination = ({ children, examId }) => {
  const id = useExamId(examId);
  if (!useRecoilValue(isExaminationSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsExaminationStatus = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(isExaminationStatusSelectorFamily(id));
  if (status)
    return children;
  else
    return null;
};

export const IsNotExaminationStatus = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(isExaminationStatusSelectorFamily(id));
  if (!status)
    return children;
  else
    return null;
};

export const IsExaminationUnavailable = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (status && status.statusText === 'unavailable')
    return children;
  else
    return null;
};

export const IsNotExaminationUnavailable = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (!(status && status.statusText === 'unavailable'))
    return children;
  else
    return null;
};

export const IsExaminationLocked = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (status && status.statusText === 'locked')
    return children;
  else
    return null;
};

export const IsNotExaminationLocked = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (!(status && status.statusText === 'locked'))
    return children;
  else
    return null;
};

export const IsExaminationFailed = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (status && status.statusText === 'failed')
    return children;
  else
    return null;
};

export const IsNotExaminationFailed = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (!(status && status.statusText === 'failed'))
    return children;
  else
    return null;
};

export const IsExaminationStoredResponses = ({ children, examId }) => {
  const id = useExamId(examId);
  const { isStoredResponses } = useStoredResponses(id);
  if (isStoredResponses())
    return children;
  else
    return null;
};

export const IsNotExaminationStoredResponses = ({ children, examId }) => {
  const id = useExamId(examId);
  const { isStoredResponses } = useStoredResponses(id);
  if (!isStoredResponses())
    return children;
  else
    return null;
};

export const IsExaminationNotAttempted = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (status && status.statusText === 'notAttempted')
    return children;
  else
    return null;
};

export const IsNotExaminationNotAttempted = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (!(status && status.statusText === 'notAttempted'))
    return children;
  else
    return null;
};

export const IsExaminationPassed = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (status && status.statusText === 'passed')
    return children;
  else
    return null;
};

export const IsNotExaminationPassed = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (!(status && status.statusText === 'passed'))
    return children;
  else
    return null;
};

export const IsExaminationSuspended = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (status && status.statusText === 'suspended')
    return children;
  else
    return null;
};

export const IsNotExaminationSuspended = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (!(status && status.statusText === 'suspended'))
    return children;
  else
    return null;
};

export const IsExaminationError = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (status && status.statusText === 'error')
    return children;
  else
    return null;
};

export const IsNotExaminationError = ({ children, examId }) => {
  const id = useExamId(examId);
  const status = useRecoilValue(statusAtomFamily(id));
  if (!(status && status.statusText === 'error'))
    return children;
  else
    return null;
};

export const IsExaminationExpired = ({ children, examId }) => {
  const id = useExamId(examId);
  if (useRecoilValue(isTimerExpiredSelectorFamily(id)))
    return children;
  else
    return null;
}

export const IsNotExaminationExpired = ({ children, examId }) => {
  const id = useExamId(examId);
  if (!(useRecoilValue(isTimerExpiredSelectorFamily(id))))
    return children;
  else
    return null;
}

// assessment
export const IsAssessmentPreamble = ({ children }) => {
  const { questionId = 0 } = useParams();
  if (parseInt(questionId) === 0)
      return children;
  else
      return null;
};

export const IsNotAssessmentPreamble = ({ children }) => {
  const { questionId = 0 } = useParams();
  if (parseInt(questionId) !== 0)
      return children;
  else
      return null;
};

export const IsAssessmentOutro = ({ children }) => {
  const { assessmentId, questionId = 0 } = useParams();
  const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
  if (parseInt(questionId) !== 0 && assessment && parseInt(questionId) > assessment.questionCount)
      return children;
  else
      return null;
};

export const IsNotAssessmentOutro = ({ children }) => {
  const { assessmentId, questionId = 0 } = useParams();
  const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
  if (!(parseInt(questionId) !== 0 && assessment && parseInt(questionId) > assessment.questionCount))
      return children;
  else
      return null;
};

export const IsAssessmentQuestion = ({ children }) => {
  return <IsNotAssessmentPreamble>
      <IsNotAssessmentOutro>
          {children}
      </IsNotAssessmentOutro>
  </IsNotAssessmentPreamble>
};

// exam
export const IsCorrespondingAssessment = ({ children, examId }) => {
  const id = useExamId(examId);
  if (useRecoilValue(isCorrespondingAssessmentSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsNoCorrespondingAssessment = ({ children, examId }) => {
  const id = useExamId(examId);
  if (!useRecoilValue(isCorrespondingAssessmentSelectorFamily(id)))
    return children;
  else
    return null;
};

export const IsExamPreamble = ({ children }) => {
  const { sectionId = 0 } = useParams();
  if (parseInt(sectionId) === 0)
    return children;
  else
    return null;
};

export const IsNotExamPreamble = ({ children }) => {
  const { sectionId = 0 } = useParams();
  if (!(parseInt(sectionId) === 0))
    return children;
  else
    return null;
};

export const IsExamOutro = ({ children }) => {
  const { sectionId = 0 } = useParams();
  if (parseInt(sectionId) === 5)
    return children;
  else
    return null;
};

export const IsNotExamOutro = ({ children }) => {
  const { sectionId = 0 } = useParams();
  if (!(parseInt(sectionId) === 5))
    return children;
  else
    return null;
};

export const IsExamEssay = ({ children }) => {
  const { sectionId = 0 } = useParams();
  if (parseInt(sectionId) >= 1 && parseInt(sectionId) <= 3)
    return children;
  else
    return null;
};

export const IsNotExamEssay = ({ children }) => {
  const { sectionId = 0 } = useParams();
  if (!(parseInt(sectionId) >= 1 && parseInt(sectionId) <= 3))
    return children;
  else
    return null;
};

export const IsExamMcq = ({ children }) => {
  const { sectionId = 0 } = useParams();
  if (parseInt(sectionId) === 4)
    return children;
  else
    return null;
};

export const IsNotExamMcq = ({ children }) => {
  const { sectionId = 0 } = useParams();
  if (!(parseInt(sectionId) === 4))
    return children;
  else
    return null;
};