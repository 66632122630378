import React, { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { ButtonStripe } from './button.component';
import useExamUnlock from '../hooks/useExamUnlock.hook';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { STRIPE_KEY } from '../config/constants.config';
import { EXAM_UNLOCKING_CODE, EXAM_UNLOCKING_STRIPE } from '../config/loading.config';
import { FormError } from '../styled/forms.styled';

const stripePromise = loadStripe(STRIPE_KEY);

const UnlockWithStripe = () => {
    const { examId } = useParams();
    const loadingCode = useRecoilValue(loadingAtomFamily(`${EXAM_UNLOCKING_CODE}:${examId}`));
    const loadingStripe = useRecoilValue(loadingAtomFamily(`${EXAM_UNLOCKING_STRIPE}:${examId}`));
    const disabled = loadingCode || loadingStripe ? true : false;
    const [ message, setMessage ] = useState(null);
    const { unlockExamWithStripe } = useExamUnlock();
    const ref = useRef();
    const clickHandler = async e => {
        e.preventDefault();
        ref.current.blur();
        setMessage(null);
        const origin = document.location.origin;
        const path = `/examinations/unlock/${examId}`;
        const result = await unlockExamWithStripe({
            successUrl: `${origin}${path}/stripe/success`,
            cancelUrl: `${origin}${path}/stripe/cancel`,
        });
        if (!result.success) {
            setMessage(result.message);
        } else {
            const stripe = await stripePromise;
            const stripeResult = await stripe.redirectToCheckout({
                sessionId: result.response.id,
            });
        }
    };
    return <div>
        <div>
            <h3>Unlock With Stripe</h3>
            <p>If you do not have an unlock code, you can unlock the examination by secure online payment via Stripe.</p>
            {message && <FormError>{message}</FormError>}
        </div>
        <p>
            <ButtonStripe ref={ref} disabled={disabled} as={Link} onClick={clickHandler} to={`#`}>
                {!loadingStripe ? <FontAwesomeIcon icon={faStripe} /> : <><FontAwesomeIcon icon={faStripe} />&nbsp;<FontAwesomeIcon spin icon={faCircleNotch} /></>}
            </ButtonStripe>
        </p>
    </div>
};

export default UnlockWithStripe;