import styled from 'styled-components';
import { COLORS } from '../config/constants.config';
import { Progress } from './global.styled';
import { Button } from '../styled/global.styled';

export const ExamBoxOut = styled.div`
margin-top: 30px;
margin-bottom: 15px;
padding: 0 10px;
border: 1px solid white;
color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
background-color: rgb(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b});
`;

export const ExamContainer = styled.div`
display: flex;
align-items: flex-start;
`;

export const ExamArticle = styled.div`
width: 70%;
margin: 15px 0;
padding: 5px 15px;
background-color: rgb(${COLORS.backgroundColor.r}, ${COLORS.backgroundColor.g}, ${COLORS.backgroundColor.b});
line-height: 2;
flex-grow: 1;
h1 {
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: transparent;
    font-size: 1.1rem;
}
`;

export const ExamNavigation = styled.div`
width: 30%;
margin: 15px 0;
padding: 0 0 5px 20px;
display: flex;
flex-wrap: wrap;
align-content: flex-start;
`;

export const ExamProgress = styled(Progress)`
margin: 0 0 15px 0;
text-align: center;
`;

export const ExamButtons = styled.p`
display: flex;
flex-direction: column;
justify-content: stretch;
align-items: stretch;
`;

export const ExamSubmitButton = styled(Button)`
display: flex;
justify-content: center;
align-items: center;
${props => props.disabled ? `
border: 1px solid white;
color: rgb(${COLORS.text.r}, ${COLORS.text.g}, ${COLORS.text.b});
background-color: rgb(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b});
cursor: default;
pointer-events: none;
` : ``}
`;