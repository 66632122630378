import React from 'react';
import { PageHeading } from '../styled/global.styled';

const PrivacyPolicyPage = () => {
    return <>
        <PageHeading>Privacy Policy</PageHeading>
        <PrivacyPolicy />
    </>
};

export const PrivacyPolicy = () => {
    return <>
        <p><strong>This page explains our privacy policy and how we will use and protect any information about you that you give to us or that we collate when you visit this website or participate in any <strong>The Qualification Council for Cosmetic Procedures</strong> (hereafter ‘QCCP’) related training, education and/or development (hereafter ‘training’).</strong></p>

        <p>This privacy notice is intended to provide transparency regarding what personal data QCCP may hold about you, how it will be processed and stored, how long it will be retained and who may have access to your data.</p>
        <p>Personal data is any information relating to an identified or identifiable living person (the data subject). An identifiable person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number or factors specific to the physical, genetic or mental identity of that person, for example.</p>

        <h2>What this privacy statement covers</h2>
        <p>This privacy statement only covers the processing of personal data by QCCP that QCCP has obtained from data subjects accessing QCCP’s websites and from its provision of services and exercise of functions. It does not cover the processing of data by any sites that can be linked to or from QCCP’s websites, so you should always be aware when you are moving to another site and read the privacy statement on that website.</p>
        <p>When providing QCCP with any of your personal data for the first time, for example, when you take up an appointment with QCCP or when you enroll on any QCCP related training, you will be asked to confirm that you have read and accepted the terms of this privacy statement. A copy of your acknowledgement will be retained for reference.</p>
        <p>If our privacy policy changes in any way, we will place an updated version on this page. Regularly reviewing the page ensures you are always aware of what information we collect, how we use it and under what circumstances, if any, we will share it with other parties.</p>

        <h2>Why QCCP collects your personal data</h2>
        <p>Your personal data is collected and processed for the purposes of and in connection with the functions that QCCP performs with regard to website operation and delivery of online education. The collection and processing of such data is necessary for the purposes of those functions. A full copy of our notification to the Information Commissioner setting out all of the types of processing that we undertake can be found on the Commissioner’s website. For further information please refer to QCCP’s registration number: ZA166155.  In connection with training, QCCP collects and uses your personal information for the following purposes:</p>
        <ol>
            <li>To manage your training</li>
            <li>To quality assure training and ensure that standards are maintained</li>
            <li>To maintain patient safety through the management of performance concerns</li>
            <li>To comply with legal and regulatory responsibilities including revalidation</li>
            <li>To contact you about training opportunities, events, surveys and information that may be of interest to you</li>
        </ol>
        <p>We also collect and use personal information from you so that we can to promote our services, to monitor our own accounts and records, to monitor our work, to report on progress made, and to let us fulfil our statutory obligations and statutory returns as set by the law.</p>

        <h2>Collection and use of data from website users</h2>
        <p>When you access QCCP’s website, small amounts of information are sometimes placed on your device, including small files known as cookies. These pieces of information are used to improve services for you. For example, we use a series of cookies to monitor website speed and usage, as well as to ensure that any preferences you have selected previously are the same when you return to our website.</p>
        <p>Google Analytics for example stores information about what pages you visit, how long you are on the site, how you got here and what you click on. Personal information (e.g. your name or address) is not however collected or stored so this information cannot be used to identify who you are. We do not allow Google to use or share our analytics data.</p>
        <p>Full details on the cookies set by Google Analytics are published on the Google website. Google also publishes a browser add-on to allow you to choose that information about your website visit is not sent to Google Analytics.</p>
        <p>On a number of pages we use ‘plug ins’ or embedded media. For example, we might embed YouTube videos in pages. The suppliers of these services may also set cookies on your device when you visit the pages where we have used this type of content. These are known as ‘third-party’ cookies. To opt-out of third-parties collecting any data regarding your interaction on our website, please refer to their websites for further information.</p>
        <p>We will not use cookies to collect personal data about you. However, if you wish to restrict or block the cookies which are set by our websites, or indeed any other website, you can do this through your browser settings. The ‘Help’ function within your browser should tell you how. Alternatively, you may wish to visit www.aboutcookies.org which contains comprehensive information on how to do this on a wide variety of browsers. You will also find details on how to delete cookies from your machine as well as more general information about cookies. Please be aware that restricting cookies may impact on the functionality of our website.</p>

        <h2>QCCP as data controller</h2>
        <p>QCCP is the data controller in respect of any personal data it holds individuals accessing QCCP’s website.</p>

        <h2>Legal basis for processing</h2>
        <p>The GDPR requires that data controllers and organisations that process personal data demonstrate compliance with its provisions. This involves publishing our basis for lawful processing.</p>
        <p>As personal data is processed for the purposes of QCCP’s statutory functions, QCCP’s legal bases for the processing of personal data as listed in Article 6 of the GDPR are as follows:</p>
        <ul>
            <li>6(1)(a) – Consent of the data subject</li>
            <li>6(1)(b) – Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract</li>
            <li>6(1)(c) – Processing is necessary for compliance with a legal obligation</li>
            <li>6(1)(e) – Processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller</li>
            <li>Where QCCP processes special categories of personal data, its additional legal bases for processing such data as listed in Article 9 of the GDPR are as follows:</li>
            <li>9(2)(a) – Explicit consent of the data subject</li>
            <li>9(2)(b) – Processing is necessary for the purposes of carrying out the obligations and exercising specific rights of the controller or of the data subject in the field of employment and social security and social protection law</li>
            <li>9(2)(f) – Processing is necessary for the establishment, exercise or defence of legal claims or whenever courts are acting in their judicial capacity</li>
            <li>9(2)(g) – Processing is necessary for reasons of substantial public interest</li>
            <li>9(2)(h) – Processing is necessary for the purposes of occupational medicine, for the assessment of the working capacity of the employee, or the management of health and social care systems and services</li>
            <li>9(2)(j) – Processing is necessary for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes</li>
        </ul>
        <p>Special categories of personal data include data relating to racial or ethnic origin, political opinions, religious beliefs, sexual orientation and data concerning health.</p>
        <p>Please note that not all of the above legal bases will apply for each type of processing activity that QCCP may undertake. However, when processing any personal data for any particular purpose, one or more of the above legal bases will apply.</p>
        <p>We may seek your consent for some processing activities, for example for sending out invitations to you to training events and sending out material from other agencies. If you do not give consent for us to use your data for these purposes, we will not use your data for these purposes, but your data may still be retained by us and used by us for other processing activities based on the above lawful conditions for processing.</p>

        <h2>Information that we may send you</h2>
        <p>We may occasionally have to send you information from QCCP about matters of policy where those policy issues impact on education, training or other matters related to QCCP. </p>

        <h2>Transfers Abroad</h2>
        <p>The GDPR imposes restrictions on the transfer of personal data outside the European Union, to third countries or international organisations, in order to ensure that the level of protection of individuals afforded by the GDPR is not undermined.</p>
        <p>Your data may only be transferred abroad where QCCP is assured that a third country, a territory or one or more specific sectors in the third country, or an international organisation ensures an adequate level of protection.</p>

        <h2>How we protect your personal data</h2>
        <p>Our processing of all personal data complies with the General Data Protection Regulation principles in line with the QCCP registration held with the Information Commissioners Office.</p>
        <p>The personal data we hold may be held as an electronic record on data systems managed by QCCP or as a paper record. These records are only accessed, seen and used as required and/or permitted by law by staff who need access to personal data so they can do their jobs and other partner organisations under data sharing agreements.</p>
        <p>We make every effort to keep your personal information accurate and up to date, but in some cases we are reliant on you as the data subject to notify us of any necessary changes to your personal data. If you tell us of any changes in your circumstances, we can update the records with personal data you choose to share with us.</p>
        <p>We will keep personal data for no longer than necessary.</p>

        <h2>Sharing personal data</h2>
        <p>So we can provide the right services at the right level, we may share your personal data with other third party organisations such as the Department of Health, Higher Education Institutions, NHS Trusts, clinical placement providers, colleges, faculties, the GMC, NHS Trusts/Health Boards/Health and Social Care Trusts, approved academic researchers and other NHS and government agencies where necessary, to provide the best possible training and education. This will be on a legitimate need to know basis only.</p>
        <p>We may also share information, where necessary, to prevent, detect or assist in the investigation of fraud or criminal activity, to assist in the administration of justice, for the purposes of seeking legal advice or exercising or defending legal rights or as otherwise required by the law.</p>
        <p>Where the data is used for analysis and publication by a recipient or third party, any publication will be on an anonymous basis, and will not make it possible to identify any individual. This will mean that the data ceases to become personal data.</p>

        <h2>Open Data</h2>
        <p>QCCP’s policy is to observe the Cabinet Office transparency and accountability commitments towards more open use of public data in line with relevant and applicable UK and European legislation. QCCP would never share personal data through the Open Data facility. To this end, QCCP will implement Information Governance protocols that reflect Information Commissioner’s Office recommended best practice for record anonymisation, and Office of National Statistics guidance on publication of statistical information.</p>

        <h2>Your rights</h2>
        <h3>Right to rectification and erasure</h3>
        <p>The GDPR extends and strengthens your rights as a data subject. Under the GDPR you have the right to rectification of inaccurate personal data and the right to request the erasure of your personal data. However, the right to erasure is not an absolute right and it may be that it is necessary for QCCP to continue to process your personal data for a number of lawful and legitimate reasons.</p>
        <h3>Right to object</h3>
        <p>You have the right in certain circumstances to ask QCCP to stop processing your personal data in relation to any QCCP service. As set out above, you can decide that you do not wish to receive information from QCCP about matters of policy affecting education and training. However, the right to object is not an absolute right and it may be that it is necessary in certain circumstances for QCCP to continue to process your personal data for a number of lawful and legitimate reasons.</p>
        <p>If you object to the way in which QCCP is processing your personal information or if you wish to ask QCCP to stop processing your personal data by contacting us here: <a href='https://qccp.org.uk/contact-us' target='_blank' rel='noopener noreferrer'>https://qccp.org.uk/contact-us</a>.  However, if we do stop processing personal data about you, this may prevent QCCP from providing the best possible service to you.</p>

        <h3>Subject Access</h3>
        <p>You can access a copy of the information QCCP holds about you by contacting us here: <a href='https://qccp.org.uk/contact-us' target='_blank' rel='noopener noreferrer'>https://qccp.org.uk/contact-us</a>. This information is generally available to you free of charge subject to the receipt of appropriate identification.</p>
        <h3>Data Portability</h3>
        <p>The GDPR sets out the right for a data subject to have their personal data ported from one controller to another on request in certain circumstances. </p>
        <p>If you want to complain about how we have used your personal data or to know more about how your information will be used, please contact us here: <a href='https://qccp.org.uk/contact-us' target='_blank' rel='noopener noreferrer'>https://qccp.org.uk/contact-us</a>.</p>
        <p>You can also contact the Information Commissioner if you have a complaint about our processing of your personal data:</p>
        <p>The Office of the Information Commissioner<br />
        Wycliffe House<br />
        Water Lane<br />
        Wilmslow<br />
        Cheshire<br />
        SK9 5AF</p>
        <h3>Your responsibilities</h3>
        <p>It is important that you work with us to ensure that the information we hold about you is accurate and up to date so please inform QCCP if any of your personal data needs to be updated or corrected.</p>
        <p>All communications from QCCP will normally be by email. It is therefore essential for you to maintain an effective and secure email address or you may not receive information or other important news and information about your employment or training.</p>
    </>
};

export default PrivacyPolicyPage;