import { useHistory } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { API_URL } from '../config/constants.config';
import { getAssessmentSelectorFamily, progressAtomFamily } from '../recoil/assessments.recoil';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import useAssessmentId from '../hooks/useAssessmentId.hook';
import useStatusData from '../hooks/useStatusData.hook';
import { DELAY_RESPONSE, PROGRESS_FETCHING, PROGRESS_UPDATING } from '../config/loading.config';

const useProgressData = assessmentId => {
    const history = useHistory();
    const id = useAssessmentId(assessmentId);
    const [ loading, setLoading ] = useRecoilState(loadingAtomFamily(`${PROGRESS_FETCHING}:${id}`));
    const [ updating, setUpdating ] = useRecoilState(loadingAtomFamily(`${PROGRESS_UPDATING}:${id}`));
    const userToken = useRecoilValue(userTokenAtom);
    const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
    const [ progress, setProgress ] = useRecoilState(progressAtomFamily(assessmentId));
    const { getStatus } = useStatusData(assessment ? assessment.examId : null);

    const get = async () => {
        if (loading) return;
        setLoading(true);
        if (!userToken || !assessment) {
            DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
            return;
        };
        const ref = await fetch(`${API_URL}/assessments/${assessmentId}/get-responses`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        if (ref.status !== 200) {
            setProgress({
                assessmentId,
                completionPercentage: 0,
                expirationDate: null,
                lastAttemptedDate: null,
                questions: [],
                responses: new Array(assessment.questionCount).fill(null),
                status: 'notAttempted',
            });
        } else {
            const json = await ref.json();
            setProgress(json);
        }
        DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
    };

    const set = async (responses, redirect = '') => {
        if (progress.status === 'passed') return;
        if (updating) return;
        setUpdating(true);
        if (!userToken || !assessment) {
            DELAY_RESPONSE ? setTimeout(() => setUpdating(false), 1000) : setUpdating(false);
            return;
        };
        const ref = await fetch(`${API_URL}/assessments/${assessmentId}/set-responses`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify(responses),
        });
        if (ref.status === 200) {
            const json = await ref.json();
            setProgress(json);
            if (json.status !== progress.status)
                getStatus();
        }
        if (DELAY_RESPONSE) {
            setTimeout(() => {
                setUpdating(false);
                if (redirect) {
                    window.scrollTo(0, 0);
                    history.push(redirect);
                }
            }, 1000);
        }
        else {
            setUpdating(false);
            if (redirect) {
                window.scrollTo(0, 0);
                history.push(redirect);
            }
        }
    };

    return { getProgress: get, setProgress: set };
};

export default useProgressData;