import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { getAssessmentByExamIdSelectorFamily } from './assessments.recoil';

export const examinationsAtom = atom({
    key: 'examinations',
    default: [],
});

export const statusAtomFamily = atomFamily({
  key: 'status',
  default: {},
});

// get
export const getExaminationSelectorFamily = selectorFamily({
  key: 'getExamination',
  get: id => ({ get }) => get(examinationsAtom).find(_ => _.id === id),
});

export const getExaminationTitleSelectorFamily = selectorFamily({
  key: 'getExaminationTitle',
  get: id => ({ get }) => {
    const examination = get(getExaminationSelectorFamily(id));
    if (examination && examination.title)
      return examination.title;
    return '';
  },
});

export const getExaminationAttemptsRemainingSelectorFamily = selectorFamily({
  key: 'getExaminationAttemptsRemaining',
  get: id => ({ get }) => {
    const status = get(statusAtomFamily(id));
    if (status && status.attemptsRemaining)
      return status.attemptsRemaining;
    return '';
  },
});

export const getCorrespondingAssessmentSelectorFamily = selectorFamily({
  key: 'getCorrespondingAssessment',
  get: id => ({ get }) => get(getAssessmentByExamIdSelectorFamily(id)),
});

export const getSubmittedExaminationsSelector = selector({
  key: 'getPassedExaminations',
  get: ({ get }) => {
    const examinations = get(examinationsAtom);
    const submitted = examinations.reduce((arr, examination) => {
      const status = get(statusAtomFamily(examination.id));
      if (status.statusText === 'passed') {
        arr.push(examination);
      }
      return arr;
    }, []);
    return submitted;
  },
});

// conditionals
export const isExaminationSelectorFamily = selectorFamily({
  key: 'isExamination',
  get: id => ({ get }) => {
    if (get(getExaminationSelectorFamily(id)))
      return true;
    return false;
  },
});

export const isExaminationStatusSelectorFamily = selectorFamily({
  key: 'isExaminationStatus',
  get: id => ({ get }) => {
    const status = get(statusAtomFamily(id));
    if (status && Object.keys(status).length !== 0)
      return true;
    return false;
  },
});

export const isCorrespondingAssessmentSelectorFamily = selectorFamily({
  key: 'isCorrespondingAssessment',
  get: id => ({ get }) => get(getAssessmentByExamIdSelectorFamily(id)) ? true : false,
});

export const isAtLeastOneExaminationUnlockedSelector = selector({
    key: 'isAtLeastOneExaminationUnlocked',
    get: ({ get }) => {
        let found = false;
        const examinations = get(examinationsAtom);
        for (let examination of examinations) {
          const status = get(statusAtomFamily(examination.id));
          if (status.statusText === 'notAttempted' || 
              status.statusText === 'passed' || 
              status.statusText === 'failed' || 
              status.statusText === 'suspended') {
            found = true;
            break;
          }
        }
        return found;
    },
});

export const isAtLeastOneExaminationAttemptedSelector = selector({
    key: 'isAtLeastOneExaminationAttempted',
    get: ({ get }) => {
        let found = false;
        const examinations = get(examinationsAtom);
        for (let examination of examinations) {
          const status = get(statusAtomFamily(examination.id));
          if (status.statusText === 'passed' || 
              status.statusText === 'failed' || 
              status.statusText === 'suspended') {
            found = true;
            break;
          }
        }
        return found;
    },
});

export const isAtLeastOneExaminationSubmittedSelector = selector({
    key: 'isAtLeastOneExaminationSubmitted',
    get: ({ get }) => {
        let found = false;
        const examinations = get(examinationsAtom);
        for (let examination of examinations) {
          const status = get(statusAtomFamily(examination.id));
          if (status.statusText === 'passed') {
            found = true;
            break;
          }
        }
        return found;
    },
});