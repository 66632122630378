import React from 'react';
import { Link } from 'react-router-dom';
import { PageHeading } from '../styled/global.styled';

const EulaPage = () => {
    return <>
        <PageHeading>EULA</PageHeading>
        <Eula />
    </>
};

export const Eula = () => {
    return <>
        <h2>END USER LICENCE AGREEMENT<br />
        PLEASE READ THESE LICENCE TERMS CAREFULLY</h2>
        
        <p><strong>BY CLICKING ON THE "ACCEPT" BUTTON BELOW YOU AGREE TO THESE TERMS WHICH WILL BIND YOU.<br />
        IF YOU DO NOT AGREE TO THESE TERMS DO NOT CONTINUE WITH THE REGISTRATION PROCESS.</strong></p>

        <h3>Who we are and what this agreement does:</h3>
        <p>We <strong>The Qualification Council for Cosmetic Procedures</strong> of <strong>Pickering House, 40A York Place, Leeds LS1 2ED</strong> license you to use:</p>
        <ul>
            <li>The Aesthetic Medicine Self-Assessment and Examination (AMSAE) website and the content we provide to you through this website and any updates or supplements to it.</li>
            <li>The related online documentation (Documentation).</li>
        </ul>
        <p>as permitted in these terms.</p>

        <h3>Fees</h3>
        <p>The price for AMSAE will be the price indicated on the sign-up page when you register to use the Service.</p>

        <h3>Your privacy</h3>
        <p>We only use any personal data we collect through your use of AMSAE in the ways set out in our <Link to='/privacy-policy'>privacy policy</Link>.</p>

        <h3>Support for AMSAE and how to tell us about problems</h3>
        <p>If you want to learn more about AMSAE or have any problems using it please take a look at our support resources at <a href='https://qccp.org.uk/contact-us' target='_blank' rel='noopener noreferrer'>https://qccp.org.uk/contact-us</a>.</p>

        <h3>Contacting us (including with complaints)</h3>
        <p>If you think AMSAE is faulty or mis described or wish to contact us for any other reason, please use the contact form on the site <a href='https://qccp.org.uk/contact-us' target='_blank' rel='noopener noreferrer'>https://qccp.org.uk/contact-us</a>.</p>

        <h3>You may not transfer AMSAE to someone else</h3>
        <p>We are giving you personally the right to use AMSAE as set out above. You may not otherwise transfer AMSAE to someone else, whether for money, for anything else or for free.</p>

        <h3>Changes to these terms</h3>
        <p>We may need to change these terms to reflect changes in law or best practice or to deal with additional features which we introduce.</p>

        <h3>Update to AMSAE and changes to the Service</h3>
        <p>From time to time we may automatically update AMSAE to improve performance, enhance functionality, reflect changes to the operating system or address security issues.</p>

        <h3>We may collect technical data about your device</h3>
        <p>By using AMSAE, you agree to us collecting and using technical information about the devices you use AMSAE on and related software, hardware and peripherals to improve AMSAE and to provide any services to you.</p>

        <h3>We are not responsible for other websites you link to</h3>
        <p>AMSAE may contain links to other independent websites, which are not provided by us. Such independent sites are not under our control, and we are not responsible for and have not checked and approved their content or their privacy policies (if any).</p>
        <p>You will need to make your own independent judgement about whether to use any such independent sites, including whether to buy any products or services offered by them.</p>

        <h3>Licence restrictions</h3>
        <p>You agree that you will:</p>
        <ul>
            <li>not rent, lease, sub-license, loan, provide, or otherwise make available, AMSAE in any form, in whole or in part to any person without prior written consent from us;</li>
            <li>not copy AMSAE or Documentation, except as part of the normal use of AMSAE or where it is necessary for the purpose of back-up or operational security;</li>
            <li>not translate, merge, adapt, vary, alter or modify, the whole or any part of AMSAE or Documentation nor permit AMSAE or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use AMSAE on devices as permitted in these terms;</li>
            <li>not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of AMSAE nor attempt to do any such things, except to the extent that (by virtue of sections 50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to decompile AMSAE to obtain the information necessary to create an independent program that can be operated with AMSAE or with another program (Permitted Objective), and provided that the information obtained by you during such activities:</li>
            <li>is not disclosed or communicated without the Licensor's prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective; and</li>
            <li>is not used to create any software that is substantially similar in its expression to AMSAE;</li>
            <li>is kept secure; and</li>
            <li>is used only for the Permitted Objective;</li>
            <li>comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by AMSAE.</li>
        </ul>

        <h3>Acceptable use restrictions</h3>
        <p>You must:</p>
        <ul>
            <li>not be representing, consulting for, sub-contracted by, employed by, associated with or reporting to a competing organisation or business for any purposes whatsoever;</li>
            <li>agree that under no circumstances will you create any screen shots, downloads, video recordings, screen sharing (both synchronous and asynchronous), allow any other person to view AMSAE on your account, either directly or indirectly, or any other method that allows you to copy, share or view any AMSAE material in any manner with a third party for any purpose whatsoever;</li>
            <li>not use AMSAE in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into AMSAE or any operating system;</li>
            <li>not use AMSAE and then undertake a separate assessment;</li>
            <li>not infringe our intellectual property rights or those of any third party in relation to your use of AMSAE, including by the submission of any material (to the extent that such use is not licensed by these terms);</li>
            <li>not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of AMSAE;</li>
            <li>not use AMSAE in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and</li>
            <li>not collect or harvest any information or data from our systems or attempt to decipher any transmissions to or from the servers running any service.</li>
        </ul>

        <h3>Intellectual property rights</h3>
        <p>All intellectual property rights in AMSAE and the Documentation throughout the world belong to us and the rights in AMSAE are licensed (not sold) to you. You have no intellectual property rights in, or to, AMSAE or the Documentation other than the right to use them in accordance with these terms.</p>

        <h3>Our responsibility for loss or damage suffered by you</h3>
        <p><strong>We are responsible to you for foreseeable loss and damage caused by us.</strong> If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking these terms or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these terms, both we and you knew it might happen.</p>
        <p><strong>We do not exclude or limit in any way our liability to you where it would be unlawful to do so.</strong> This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors or for fraud or fraudulent misrepresentation.</p>
        <p><strong>When we are liable for damage to your property.</strong> If defective digital content that we have supplied damages a device or digital content belonging to you, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</p>
        <p><strong>We are not liable for business losses.</strong> If you use AMSAE for any commercial, business or resale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
        <p><strong>Limitations to AMSAE.</strong> AMSAE is provided for general training information and purposes only. Although we make reasonable efforts to update the information provided by AMSAE, we make no representations, warranties or guarantees, whether express or implied, that such information is accurate, complete or up to date.</p>
        <p><strong>Please back-up content and data used with AMSAE.</strong> We recommend that you back up any content and data used in connection with AMSAE, to protect yourself in case of problems with AMSAE.</p>
        <p><strong>Check that AMSAE is suitable for you.</strong> AMSAE has not been developed to meet your individual requirements. Please check that the facilities and functions of AMSAE meet your requirements.</p>
        <p><strong>We are not responsible for events outside our control.</strong> If our provision of the support for AMSAE is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event but if there is a risk of substantial delay you may contact us to end your contract with us and receive a refund for any services you have paid for but not received.</p>
        <p><strong>Liability.</strong> If you use this site in breach of these conditions you understand and agree to be held personally liable for breach of this agreement. If you are unclear as to whether you can use this site, please contact the AMSAE team through the contact form <a href='https://qccp.org.uk/contact-us' target='_blank' rel='noopener noreferrer'>https://qccp.org.uk/contact-us</a> and we will be happy to assist you further.</p>
        <p>If it is found that an organisation has encouraged or directed its staff or associates to register with the AMSAE in order to subsequently undertake a separate assessment, then we will be entitled to damages for each user access from the employing organisation in question, in addition to any and all associated investigative and legal costs, with no maximum limit. </p>
        <p>We may end your rights to use AMSAE at any time by contacting you if you have broken these terms. If what you have done can be put right, we will give you a reasonable opportunity to do so.</p>
        <p>If we end your rights to use AMSAE:</p>
        <ul>
            <li>You must stop all activities authorised by these terms, including your use of AMSAE.</li>
            <li>You must immediately destroy all copies of AMSAE which you have and confirm to us that you have done this.</li>
        </ul>
        <p>We may transfer this agreement to someone else. We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</p>
        <p>You need our consent to transfer your rights to someone else. You may only transfer your rights or your obligations under these terms to another person if we agree in writing.</p>
        <p>No rights for third parties. This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.</p>
        <p>If a court finds part of this contract illegal, the rest will continue in force. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
        <p>Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
        <p>Which laws apply to this contract and where you may bring legal proceedings. These terms are governed by English law and you can bring legal proceedings in respect of the products in the English courts. If you live in Scotland you can bring legal proceedings in respect of the products in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the products in either the Northern Irish or the English courts.</p>
    </>
};

export default EulaPage;