import React, { useReducer, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import formReducer from '../reducers/form.reducer';
import useExamUnlock from '../hooks/useExamUnlock.hook';
import { ButtonNext } from './button.component';
import { Text } from './forms.component';
import { FormError } from '../styled/forms.styled';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { EXAM_UNLOCKING_CODE, EXAM_UNLOCKING_STRIPE } from '../config/loading.config';
import { LoadingIcon } from '../components/loading.component';

const initialState = {
    unlockCode: '',
    errors: {},
};

const UnlockWithCode = () => {
    const { examId } = useParams();
    const loadingCode = useRecoilValue(loadingAtomFamily(`${EXAM_UNLOCKING_CODE}:${examId}`));
    const loadingStripe = useRecoilValue(loadingAtomFamily(`${EXAM_UNLOCKING_STRIPE}:${examId}`));
    const disabled = loadingCode || loadingStripe ? true : false;
    const history = useHistory();
    const [state, dispatch] = useReducer(formReducer, initialState);
    const { unlockExamWithCode } = useExamUnlock();
    const changeHandler = e => dispatch({ type: 'update', payload: e.target });
    const submitHandler = async e => {
        e.preventDefault();
        dispatch({ type: 'setErrors', payload: {} });
        const { unlockCode } = state;
        const errors = {};
        if (unlockCode === '')
            errors['unlockCode'] = 'Please enter a valid unlock code';
        if (Object.keys(errors).length) {
            dispatch({ type: 'setErrors', payload: errors });
        } else {
            const result = await unlockExamWithCode(unlockCode);
            if (!result.success) {
                errors['message'] = result.message;
                dispatch({ type: 'setErrors', payload: errors });
            } else {
                history.push(`/examinations/unlock/${examId}/code`);
            }
        }
    };
    useEffect(() => () => dispatch({ type: 'reset', payload: initialState }), [dispatch]);
    return <form onSubmit={submitHandler}>
        <div>
            <h3>Unlock With Code</h3>
            <p>If you have an unlock code, please enter it below and click submit.</p>
            {state.errors.message && <FormError>{state.errors.message}</FormError>}
            <Text name='unlockCode' error={state.errors.unlockCode} value={state.unlockCode} label='Unlock code' placeholder='Unlock code' onChange={changeHandler} />
        </div>
        <p>
            <ButtonNext disabled={disabled} type='submit'>
                {!loadingCode ? 'Unlock exam' : <LoadingIcon align='right'>Unlock exam</LoadingIcon>}
            </ButtonNext>
        </p>
    </form>
};

export default UnlockWithCode;