import React from 'react';
import { IsAssessmentSections, IsSectionsLoading, IsNotSectionsLoading, IsNotAssessmentsLoading, IsAssessmentsLoading } from '../components/conditionals.component';
import { LoadingIcon } from '../components/loading.component';
import { AssessmentsSection } from '../components/sections.component';
import Assessment from '../content/assessment.content';

const AssessmentPage = () => {
    return <AssessmentsSection>
        <IsAssessmentsLoading>
            <p><LoadingIcon>Loading assessment...</LoadingIcon></p>
        </IsAssessmentsLoading>
        <IsSectionsLoading>
            <p><LoadingIcon>Loading sections...</LoadingIcon></p>
        </IsSectionsLoading>
        <IsNotAssessmentsLoading>
        <IsNotSectionsLoading>
            <IsAssessmentSections>
                <Assessment />
            </IsAssessmentSections>
        </IsNotSectionsLoading>
        </IsNotAssessmentsLoading>
    </AssessmentsSection>
}

export default AssessmentPage;