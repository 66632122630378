import React from 'react';
import Assessments from '../pages/selfAssessments.page';
import Examinations from '../pages/examinations.page';
import Submissions from '../pages/submissions.page';
import SignInForm from '../components/signIn.component';
import { IsAuthenticated, IsNotAuthenticated } from '../components/conditionals.component';

const HomePage = () => {
  return <>
    <IsAuthenticated>
      <Assessments />
      <Examinations />
      <Submissions />
    </IsAuthenticated>
    <IsNotAuthenticated>
      <SignInForm />
    </IsNotAuthenticated>
  </>
};

export default HomePage;