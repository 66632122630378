import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IsExaminationsLoading, IsNotExaminationsLoading, IsNotStatusLoading, IsProfessionLocked, IsNotProfessionLocked, IsNotAtLeastOneAssessmentAttempted, IsNotAtLeastOneAssessmentPassed, IsAtLeastOneAssessmentPassed, IsAtLeastOneExaminationUnlocked, IsNotAtLeastOneExaminationUnlocked } from '../components/conditionals.component';
import { IsHome, IsNotHome } from '../components/conditionals.component';
import { IsAuthenticated, IsNotAuthenticated } from '../components/conditionals.component';
import { LoadingExaminations } from '../components/loading.component';
import { ProfessionLocked } from '../content/selfAssessments.content';
import { ExaminationsSection } from '../components/sections.component';
import { ResultsTable, ResultsList } from '../content/examinations.content';
import { ButtonNext } from '../components/button.component';
import { EXAMINATION_PASS_PERCENTAGE } from '../config/constants.config';

export const ExaminationsPage = () => {
  return <ExaminationsSection>
    <IsNotAuthenticated>
      <Unregistered />
    </IsNotAuthenticated>
    <IsAuthenticated>
      <IsExaminationsLoading>
        <LoadingExaminations />
      </IsExaminationsLoading>
      <IsNotExaminationsLoading>
        <IsNotStatusLoading>
          <Registered />
        </IsNotStatusLoading>
      </IsNotExaminationsLoading>
    </IsAuthenticated>
  </ExaminationsSection>
};
  
const Unregistered = () => {
  return <p>Take examinations online.</p>
};

const Registered = () => {
  return <>
    <IsProfessionLocked>
      <ProfessionLocked />
    </IsProfessionLocked>
    <IsNotProfessionLocked>
      <IsNotAtLeastOneAssessmentPassed>
        <NoAssessmentsPassed />
      </IsNotAtLeastOneAssessmentPassed>
      <IsAtLeastOneAssessmentPassed>
        <IsNotAtLeastOneExaminationUnlocked>
          <NoExaminationsUnlocked />
        </IsNotAtLeastOneExaminationUnlocked>
        <IsAtLeastOneExaminationUnlocked>
          <Instructions />
        </IsAtLeastOneExaminationUnlocked>
      </IsAtLeastOneAssessmentPassed>
      <IsHome>
        <IsAtLeastOneAssessmentPassed>
          <IsAtLeastOneExaminationUnlocked>
            <ResultsTable />
          </IsAtLeastOneExaminationUnlocked>
        </IsAtLeastOneAssessmentPassed>
        <IsNotAtLeastOneExaminationUnlocked>
          <IsAtLeastOneAssessmentPassed>
            <Button>Continue</Button>
          </IsAtLeastOneAssessmentPassed>
        </IsNotAtLeastOneExaminationUnlocked>
        <IsAtLeastOneExaminationUnlocked>
          <Button>Continue</Button>
        </IsAtLeastOneExaminationUnlocked>
      </IsHome>
      <IsNotHome>
        <ResultsList />
        <ReadingList />
      </IsNotHome>
    </IsNotProfessionLocked>
  </>
};

const NoAssessmentsPassed = () => {
  return <p>You must pass an assessment before you can take an examination.</p>
};

const NoExaminationsUnlocked = () => {
  return <p><strong>You must unlock an examination before you can proceed.</strong></p>
};

const Instructions = () => {
  return <>
    <p><strong>You must complete examinations with a score of greater than {EXAMINATION_PASS_PERCENTAGE * 100}% in each section in order to pass</strong></p>
    <p>These examinations are <strong>Medical Aesthetics Certificates</strong> regulated by the Royal Society for Public Health.</p>
  </>
};

const BoxoutPara = styled.div`
margin-bottom: 1rem;
padding: 0.5rem 1rem;
background-color: rgba(255,255,255,0.4);
`;

const ReadingList = () => {
  return <BoxoutPara>
    <p><strong>Reading List Overview</strong></p>
    <p>Part 1 papers test knowledge of the biochemistry, pharmacology and physics related to the treatment modality.  This level of knowledge is important in understanding and informing safe practice, underpinning much of the decisions we make. The detail is not necessarily exercised at this granular level in every-day practice and once learnt is rarely revisited. Therefore, a recommended reading list has been provided for the Part 1 papers only, to support directed revision.</p>
  </BoxoutPara>
};

const Button = ({ children }) => {
  return <p><ButtonNext as={Link} to="/examinations">{children}</ButtonNext></p>
};

export default ExaminationsPage;