import React from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IsNotAssessmentsLoading, IsAssessmentsLoading, IsSectionsLoading, IsNotSectionsLoading, IsProgressLoading, IsNotProgressLoading } from '../components/conditionals.component';
import { IsAuthenticated } from '../components/conditionals.component';
import { getAssessmentTitleSelectorFamily } from '../recoil/assessments.recoil';
import { AssessmentsSection } from '../components/sections.component';
import { LoadingIcon } from '../components/loading.component';
import { LearningNeeds } from '../content/learningNeeds.content';

const LearningNeedsPage = () => {
    return <AssessmentsSection>
        <IsAuthenticated>
            <IsAssessmentsLoading>
                <p><LoadingIcon>Loading assessment...</LoadingIcon></p>
            </IsAssessmentsLoading>
            <IsSectionsLoading>
                <p><LoadingIcon>Loading sections...</LoadingIcon></p>
            </IsSectionsLoading>
            <IsProgressLoading>
                <p><LoadingIcon>Loading progress...</LoadingIcon></p>
            </IsProgressLoading>
            <IsNotAssessmentsLoading>
            <IsNotSectionsLoading>
            <IsNotProgressLoading>
                <Registered />
            </IsNotProgressLoading>
            </IsNotSectionsLoading>
            </IsNotAssessmentsLoading>
        </IsAuthenticated>
    </AssessmentsSection>
};

const Unregistered = () => {
    return <Redirect to='/self-assessments' />
};

const Registered = () => {
    const { assessmentId } = useParams();
    const title = useRecoilValue(getAssessmentTitleSelectorFamily(assessmentId));
    return <>
        <h2>{title} Learning Needs</h2>
        <LearningNeeds />
        <p><Link to='/self-assessments'>&laquo; back</Link></p>
    </>
};

export default LearningNeedsPage;