import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { IsAuthenticated, IsNotAuthenticated, IsExaminationsLoading, IsNotExaminationsLoading } from '../components/conditionals.component';
import { LoadingIcon } from '../components/loading.component';
import { ExaminationsSection } from '../components/sections.component';
import Exam from '../content/exam.content';

const ExamPage = () => {
    const { examId } = useParams();
    return <>
        <IsNotAuthenticated>
            <Redirect to={`/examinations/exam/${examId}`} />
        </IsNotAuthenticated>
        <IsAuthenticated>
            <ExaminationsSection>
                <IsExaminationsLoading>
                    <p><LoadingIcon>Loading examination...</LoadingIcon></p>
                </IsExaminationsLoading>
                <IsNotExaminationsLoading>
                    <Exam />
                </IsNotExaminationsLoading>
            </ExaminationsSection>
        </IsAuthenticated>
    </>
}

export default ExamPage;