import React, { useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { API_URL } from '../config/constants.config';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import { assessmentsAtom, getAssessmentSelectorFamily, sectionsAtomFamily } from '../recoil/assessments.recoil';
import useAssessmentId from '../hooks/useAssessmentId.hook';
import { DELAY_RESPONSE, SECTIONS_FETCHING } from '../config/loading.config';

const SectionsSubscriber = ({ assessmentId }) => {
    const id = useAssessmentId(assessmentId);
    const [ loading, setLoading ] = useRecoilState(loadingAtomFamily(`${SECTIONS_FETCHING}:${id}`));
    const userToken = useRecoilValue(userTokenAtom);
    const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
    const setSections = useSetRecoilState(sectionsAtomFamily(assessmentId));

    const getSections = async () => {
        if (loading) return;
        setLoading(true);
        if (!userToken || !assessment) {
            DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
            return;
        };
        const ref = await fetch(`${API_URL}/assessments/${assessmentId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        if (ref.status === 200) {
            const json = await ref.json();
            setSections(json.sections);
        }
        DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
    };

    useEffect(() => {
        if (assessmentId && userToken && assessment) {
            // console.log('%cSectionsSubscriber', 'color: green;', assessmentId);
            getSections();
            // return () => console.log('%cSectionsSubscriber', 'color: red;', assessmentId);
        }
    }, [assessmentId, userToken, assessment]);
      
    return null;
};

const Subscribers = () => {
    const assessments = useRecoilValue(assessmentsAtom);
    if (assessments)
        return assessments.map(({ id }) => 
            <SectionsSubscriber key={`SectionsSubscriber/${id}`} assessmentId={id} />)
    else
        return null;
};

export default Subscribers;