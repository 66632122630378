import React from 'react';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faLock, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { LoadingIcon } from '../components/loading.component';
import { IsStatusLoading, IsNotStatusLoading, IsNotExaminationStoredResponses, IsExaminationStoredResponses, IsNotExaminationUnavailable, IsExaminationLocked, IsExaminationPassed, IsExaminationSuspended, IsExaminationUnavailable, IsExaminationNotAttempted, IsExaminationFailed } from '../components/conditionals.component';
import { examinationsAtom, statusAtomFamily, getExaminationSelectorFamily, getCorrespondingAssessmentSelectorFamily, getExaminationAttemptsRemainingSelectorFamily } from '../recoil/examinations.recoil';
import { Table } from '../styled/global.styled';
import { List, ListItem, ListLink, ListDetails, ListTitle, ListDates, ListProgress, ListStatus, ListActionIcon, ListAdditionalLink } from '../styled/list.styled';
import { ButtonNext } from '../components/button.component';
import { formatDate } from '../utils/date.utils';
import { formatCompletionPercentage } from '../utils/number.utils';
import useStoredResponses from '../hooks/useStoredResponses.hook';
import { READING_LIST_ZIPS } from '../config/constants.config';

// table
export const ResultsTable = () => {
  const examinations = useRecoilValue(examinationsAtom);
  return <Table>
    <tbody>
      {examinations.map(({ id }) => 
        <TableRow key={`ExaminationsResultsTable/${id}`} examId={id} />)}
    </tbody>
  </Table>
};

const TableRow = ({ examId }) => {
  const examination = useRecoilValue(getExaminationSelectorFamily(examId));
  const title = examination.qualificationTitle ? examination.qualificationTitle : examination.title;
  return <IsNotExaminationUnavailable examId={examId}>
    <tr>
        <th>{title}</th>
        <td>
          <IsStatusLoading examId={examId}>
            <LoadingIcon>fetching...</LoadingIcon>
          </IsStatusLoading>
          <IsNotStatusLoading examId={examId}>
            <RowStatus examId={examId} />
          </IsNotStatusLoading>
        </td>
    </tr>
  </IsNotExaminationUnavailable>
};

const RowStatus = ({ examId }) => {
  const status = useRecoilValue(statusAtomFamily(examId));
  if (status && status.statusText)
    return <>
      <IsExaminationNotAttempted examId={examId}>
        <Unlocked examId={examId} />
      </IsExaminationNotAttempted>
      <IsExaminationLocked examId={examId}>
        <Locked examId={examId} />
      </IsExaminationLocked>
      <IsExaminationFailed examId={examId}>
        <LastAttempted examId={examId} />
      </IsExaminationFailed>
      <IsExaminationPassed examId={examId}>
        <Passed examId={examId} />
      </IsExaminationPassed>
      <IsExaminationSuspended examId={examId}>
        <Suspended examId={examId} />
      </IsExaminationSuspended>
    </>
  else
    return null;
};

// list
export const ResultsList = () => {
  const examinations = useRecoilValue(examinationsAtom);
  return <List>
    {examinations.map(({ id }) => 
        <Item key={`ExaminationsResultsList/${id}`} examId={id} />)}
  </List>
};

const Item = ({ examId }) => {
  const examination = useRecoilValue(getExaminationSelectorFamily(examId));
  const status = useRecoilValue(statusAtomFamily(examId));
  const unavailable = status && (status.statusText === 'unavailable' || status.statusText === 'passed' || status.statusText === 'suspended') ? true : false;
  const { isStoredResponses } = useStoredResponses(examId);
  let link;
  if (status.statusText === 'locked')
    link = `/examinations/unlock/${examId}`;
  else if (!isStoredResponses() && status.attemptsRemaining > 0 && (status.statusText === 'notAttempted' || status.statusText === 'failed'))
    link = `/examinations/exam/${examId}`;
  else
    link = '#';
  return <ListItem>
    <ListLink to={link} unavailable={unavailable}>
      <ListDetails>
        <div>
          <ListTitle>{examination.title}</ListTitle>
          <Dates examId={examId} />
        </div>
      </ListDetails>
      <IsStatusLoading examId={examId}>
          <LoadingIcon>fetching...</LoadingIcon>
      </IsStatusLoading>
      <IsNotStatusLoading examId={examId}>
          <ItemStatus examId={examId} />
      </IsNotStatusLoading>
      {status.statusText !== 'passed' && <ListActionIcon>
        <p>
          <IsExaminationStoredResponses examId={examId}>
            <FontAwesomeIcon icon={faLock} />
          </IsExaminationStoredResponses>
          <IsNotExaminationStoredResponses examId={examId}>
            <IsExaminationUnavailable examId={examId}>
              <FontAwesomeIcon icon={faLock} />
            </IsExaminationUnavailable>
            <IsExaminationLocked examId={examId}>
              <FontAwesomeIcon icon={faLock} />
            </IsExaminationLocked>
            <IsExaminationNotAttempted examId={examId}>
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </IsExaminationNotAttempted>
            <IsExaminationFailed examId={examId}>
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </IsExaminationFailed>
            <IsExaminationSuspended examId={examId}>
              <FontAwesomeIcon icon={faLock} />
            </IsExaminationSuspended>
          </IsNotExaminationStoredResponses>
        </p>
      </ListActionIcon>}
    </ListLink>
    <ListAdditionalLink as='a' target='_blank' rel='noopener noreferrer' href={READING_LIST_ZIPS[examId]}><FontAwesomeIcon icon={faArrowCircleRight} /> Download Reading List</ListAdditionalLink>
  </ListItem>
};

const Dates = ({ examId }) => {
  return <>
    <IsExaminationUnavailable examId={examId}>
      <ListDates><AssessmentNotPassed examId={examId} /></ListDates>
    </IsExaminationUnavailable>
    <IsExaminationStoredResponses examId={examId}>
      <ListDates><StoredResponses examId={examId} /></ListDates>
    </IsExaminationStoredResponses>
    <IsNotExaminationStoredResponses examId={examId}>
      <IsExaminationNotAttempted examId={examId}>
        <ListDates><Unlocked examId={examId} /></ListDates>
      </IsExaminationNotAttempted>
      <IsExaminationFailed examId={examId}>
        <ListDates><LastAttempted examId={examId} /></ListDates>
      </IsExaminationFailed>
      <IsExaminationPassed examId={examId}>
        <ListDates><Passed examId={examId} /></ListDates>
      </IsExaminationPassed>
      <IsExaminationSuspended examId={examId}>
        <ListDates><Suspended examId={examId} /></ListDates>
      </IsExaminationSuspended>
    </IsNotExaminationStoredResponses>
  </>
};

const ItemStatus = ({ examId }) => {
  const status = useRecoilValue(statusAtomFamily(examId));
  return <ListStatus status={status.statusText}>
    <IsNotExaminationStoredResponses examId={examId}>
      <IsExaminationNotAttempted examId={examId}>
        Begin&nbsp;
      </IsExaminationNotAttempted>
      <IsExaminationLocked examId={examId}>
        <ButtonNext as='span'>Unlock</ButtonNext>
      </IsExaminationLocked>
      <IsExaminationFailed examId={examId}>
        <ListProgress>
          {/* <span>{formatCompletionPercentage(status.totalScore)}%</span> */}
          <span>Failed</span>
          <FontAwesomeIcon icon={faTimesCircle} />
        </ListProgress>
      </IsExaminationFailed>
      <IsExaminationPassed examId={examId}>
        <ListProgress status={status.statusText}>
          {/* <span>{formatCompletionPercentage(status.totalScore)}%</span> */}
          <span>Passed</span>
          <FontAwesomeIcon icon={faCheckCircle} />
        </ListProgress>
      </IsExaminationPassed>
    </IsNotExaminationStoredResponses>
  </ListStatus>
};

// misc
const StoredResponses = ({ examId }) => {
  return <><strong>There was a delay updating the examination.</strong><br />Responses will be submitted when the connection is restored.</>
};

const AssessmentNotPassed = ({ examId }) => {
  const assessment = useRecoilValue(getCorrespondingAssessmentSelectorFamily(examId));
  return <>Pass the {assessment.title} assessment to proceed</>
}

const Unlocked = ({ examId }) => {
  const status = useRecoilValue(statusAtomFamily(examId));
  return <>Unlocked: {formatDate(status.unlockDate)}</>
}

const Locked = ({ examId }) => {
  return 'Locked';
};

const LastAttempted = ({ examId }) => {
  const status = useRecoilValue(statusAtomFamily(examId));
  const attemptsRemaining = useRecoilValue(getExaminationAttemptsRemainingSelectorFamily(examId));
  let date = 'n/a';
  if (status.lastAttemptedDate !== null)
    date = formatDate(status.lastAttemptedDate);
  return <>Last attempted: {date}<br />
  <strong>You have {attemptsRemaining} attempt{attemptsRemaining > 1 ? 's' : ''} remaining</strong></>
}

const Passed = ({ examId }) => {
  const status = useRecoilValue(statusAtomFamily(examId));
  return <>Submitted: {formatDate(status.passedDate)}</>
}

const Suspended = ({ examId }) => {
  const status = useRecoilValue(statusAtomFamily(examId));
  let date = 'n/a';
  if (status.suspendedDate !== null)
    date = formatDate(status.lastAttemptedDate);
  else if (status.lastAttemptedDate !== null)
    date = formatDate(status.lastAttemptedDate);
  return <>Suspended: {date}<br />
  <strong>You have failed this exam 3 times - please contact the QCCP or your training provider</strong></>
}