import styled from 'styled-components';
import { COLORS } from '../config/constants.config';

export const EssayPlaceholder = styled.span`
min-width: 100px;
min-height: 1em;
padding: 2px 10px;
color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
background-color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
line-height: 1.4;
text-align: center;
display: inline-block;
user-select: none;
&:hover,
&:focus,
&:active {
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
}
${props => props.droppable ? `
color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
outline: 3px solid rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
cursor: pointer;
&:hover,
&:focus,
&:active {
    color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
    background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
}
` : ``}
${props => props.response ? `
min-width: auto;
color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
cursor: pointer;
&:hover,
&:focus,
&:active {
    color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
    background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
}
` : ``}
`;

export const EssayWords = styled.div`
width: 100%;
padding: 5px;
display: flex;
background-color: rgb(${COLORS.backgroundColorAlt.r}, ${COLORS.backgroundColorAlt.g}, ${COLORS.backgroundColorAlt.b});
flex-wrap: wrap;
align-content: flex-start;
span {
    margin: 2px;
}
`;

export const EssayWordsSearch = styled.input`
width: 100%;
margin-bottom: 3px;
`;

export const EssayWord = styled.span`
padding: 3px 10px;
color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
background-color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
cursor: pointer;
${props => props.selected ? `
color: rgb(${COLORS.secondary.r}, ${COLORS.secondary.g}, ${COLORS.secondary.b});
background-color: rgb(${COLORS.primary.r}, ${COLORS.primary.g}, ${COLORS.primary.b});
` : ``}
`;