import { useParams } from 'react-router-dom';

const useAssessmentId = assessmentId => {
    const params = useParams();
    let id = assessmentId;
    if (!id) {
        const { assessmentId: matchAssessmentId } = params;
        id = matchAssessmentId;
    }
    return id;
};

export default useAssessmentId;