import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { trainingProvidersAtom } from '../recoil/user.recoil';
import { firestore } from '../utils/firebase.utils';

const TrainingProvidersSubscriber = () => {
    const setTrainingProviders = useSetRecoilState(trainingProvidersAtom);

    const getTrainingProviders = async () => {
        const trainingProviders = [];
        const collection = await firestore.collection('providers')
            .orderBy('name', 'asc')
            .get();
        collection.forEach(doc => trainingProviders.push({
            id: doc.id,
            details: doc.data(),
        }));
        setTrainingProviders(trainingProviders);
    };

    useEffect(() => {
        getTrainingProviders();
    }, []);
      
    return null;
};

export default TrainingProvidersSubscriber;