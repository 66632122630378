import React from 'react';
import { Route } from 'react-router-dom';
import HomePage from '../pages/home.page';
import SignUpPage from '../pages/signUp.page';
import ResetPasswordPage from '../pages/resetPassword.page';
import EulaPage from '../pages/eula.page';
import PrivacyPolicyPage from '../pages/privacyPolicy.page';
import AssessmentsPage from '../pages/selfAssessments.page';
import LearningNeedsPage from '../pages/learningNeeds.page';
import AssessmentPage from '../pages/assessment.page';
import ExaminationsPage from '../pages/examinations.page';
import UnlockPage from '../pages/unlock.page';
import ExamPage from '../pages/exam.page';
import SubmissionsPage from '../pages/submissions.page';
import ContactPage from '../pages/contact.page';

const Routes = () => {
    return <>
        <Route exact path='/' component={HomePage}/>
        <Route exact path='/contact' component={ContactPage}/>
        <Route exact path='/sign-up' component={SignUpPage}/>
        <Route exact path='/reset-password' component={ResetPasswordPage}/>
        <Route exact path='/self-assessments' component={AssessmentsPage}/>
        <Route exact path='/self-assessments/assessment/:assessmentId' render={() => <AssessmentPage />} />
        <Route exact path='/self-assessments/assessment/:assessmentId/:questionId' render={() => <AssessmentPage />} />
        <Route exact path='/self-assessments/learning-needs/:assessmentId' render={() => <LearningNeedsPage />} />
        <Route exact path='/examinations' component={ExaminationsPage}/>
        <Route exact path='/examinations/unlock/:examId' render={() => <UnlockPage />} />
        <Route exact path='/examinations/unlock/:examId/:unlockType' render={() => <UnlockPage />} />
        <Route exact path='/examinations/unlock/:examId/:unlockType/:status' render={() => <UnlockPage />} />
        <Route exact path='/examinations/exam/:examId' render={() => <ExamPage />} />
        <Route exact path='/examinations/exam/:examId/:sectionId' render={() => <ExamPage />} />
        <Route exact path='/submissions' component={SubmissionsPage}/>
        <Route exact path='/eula' component={EulaPage}/>
        <Route exact path='/privacy-policy' component={PrivacyPolicyPage}/>
    </>
};

export default Routes;