import React from 'react';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IsProgressLoading, IsNotProgressLoading, IsNotAssessmentAttempted, IsAssessmentAttempted } from '../components/conditionals.component';
import { IsAssessmentIncomplete, IsNotAssessmentIncomplete, IsAssessmentFailed, IsAssessmentPassed } from '../components/conditionals.component';
import { assessmentsAtom, getAssessmentSelectorFamily, progressAtomFamily } from '../recoil/assessments.recoil';
import { formatCompletionPercentage } from '../utils/number.utils';
import { formatDate } from '../utils/date.utils';
import { LoadingIcon } from '../components/loading.component';
import { Table } from '../styled/global.styled';
import { List, ListItem, ListLink, ListDetails, ListTitle, ListDates, ListFetching, ListProgress, ListActionIcon, ListAdditionalLink } from '../styled/list.styled';
import { COMPETENCY_PDFS } from '../config/constants.config';

// table
export const ResultsTable = () => {
  const assessments = useRecoilValue(assessmentsAtom);
  return <Table>
    <tbody>
      {assessments.map(({ id }) => 
        <Row key={`AssessmentsResultsTable/${id}`} assessmentId={id} />)}
    </tbody>
  </Table>
};

const Row = ({ assessmentId }) => {
  const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
  return <tr>
      <th>{assessment.title}</th>
      <td>
        <IsProgressLoading assessmentId={assessmentId}>
          <LoadingIcon>fetching...</LoadingIcon>
        </IsProgressLoading>
        <IsNotProgressLoading assessmentId={assessmentId}>
          <RowProgress assessmentId={assessmentId} />
        </IsNotProgressLoading>
      </td>
  </tr>
};

const RowProgress = ({ assessmentId }) => {
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  if (progress && progress.status)
    return <>
      <IsNotAssessmentAttempted assessmentId={assessmentId}>
        Not attempted
      </IsNotAssessmentAttempted>
      <IsAssessmentIncomplete assessmentId={assessmentId}>
        <LastAttempted assessmentId={assessmentId} />
      </IsAssessmentIncomplete>
      <IsAssessmentFailed assessmentId={assessmentId}>
        <LastAttempted assessmentId={assessmentId} />
      </IsAssessmentFailed>
      <IsAssessmentPassed assessmentId={assessmentId}>
        <Expires assessmentId={assessmentId} />
      </IsAssessmentPassed>
    </>
  else
    return null;
};

// list
export const ResultsList = () => {
  const assessments = useRecoilValue(assessmentsAtom);
  return <List>
    {assessments.map(({ id }) => 
      <Item key={`AssessmentsResultsList/${id}`} assessmentId={id} />)}
    </List>
};

const Item = ({ assessmentId }) => {
  const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  const status = progress.status;
  const responses = progress.responses;
  const link = status === 'incomplete' && responses ? `/self-assessments/assessment/${assessmentId}/${responses.findIndex(_ => _ === null) + 1}` : `/self-assessments/assessment/${assessmentId}`;
  return <ListItem>
    <ListLink to={link}>
      <ListDetails>
        <div>
          <ListTitle>{assessment.title}</ListTitle>
          <Dates assessmentId={assessmentId} />
        </div>
      </ListDetails>
      <IsProgressLoading assessmentId={assessmentId}>
          <ListFetching><LoadingIcon>fetching...</LoadingIcon></ListFetching>
      </IsProgressLoading>
      <IsNotProgressLoading assessmentId={assessmentId}>
          <ItemProgress assessmentId={assessmentId} />
      </IsNotProgressLoading>
      <ListActionIcon>
        <p>
          <IsAssessmentIncomplete assessmentId={assessmentId}>
            Continue&nbsp;
          </IsAssessmentIncomplete>
          <FontAwesomeIcon icon={faArrowCircleRight} />
        </p>
      </ListActionIcon>
    </ListLink>
    <ListAdditionalLink as='a' target='_blank' rel='noopener noreferrer' href={COMPETENCY_PDFS[assessmentId]}><FontAwesomeIcon icon={faArrowCircleRight} /> View competency standard</ListAdditionalLink>
    <IsAssessmentFailed assessmentId={assessmentId}>
      <ListAdditionalLink to={`/self-assessments/learning-needs/${assessmentId}`}><FontAwesomeIcon icon={faArrowCircleRight} /> View learning needs</ListAdditionalLink>
    </IsAssessmentFailed>
  </ListItem>
};

const Dates = ({ assessmentId }) => {
  return <>
    <IsAssessmentIncomplete assessmentId={assessmentId}>
      <ListDates><Incomplete assessmentId={assessmentId} /></ListDates>
    </IsAssessmentIncomplete>
    <IsAssessmentFailed assessmentId={assessmentId}>
      <ListDates><LastAttempted assessmentId={assessmentId} /></ListDates>
    </IsAssessmentFailed>
    <IsAssessmentPassed assessmentId={assessmentId}>
      <ListDates><Expires assessmentId={assessmentId} /></ListDates>
    </IsAssessmentPassed>
  </>
};

const ItemProgress = ({ assessmentId }) => {
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  return <>
    <IsNotAssessmentAttempted assessmentId={assessmentId}>
      Begin&nbsp;
    </IsNotAssessmentAttempted>
    <IsAssessmentAttempted assessmentId={assessmentId}>
    <IsNotAssessmentIncomplete assessmentId={assessmentId}>
      <ListProgress>
        <span>{formatCompletionPercentage(progress.completionPercentage)}%</span>
        <FontAwesomeIcon icon={progress.completionPercentage === 1 ? faCheckCircle : faTimesCircle} />
      </ListProgress>
    </IsNotAssessmentIncomplete>
    </IsAssessmentAttempted>
  </>
};

// misc
const Incomplete = ({ assessmentId }) => {
  return <>Incomplete</>
};

const LastAttempted = ({ assessmentId }) => {
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  return <>Last attempted: {formatDate(progress.lastAttemptedDate)}</>
};

const Expires = ({ assessmentId }) => {
  const progress = useRecoilValue(progressAtomFamily(assessmentId));
  return <>Expires: {formatDate(progress.expirationDate)}</>
};

export const ProfessionLocked = () => {
  return <p><strong>You cannot take the examination because your profession is not in the list of allowed types.</strong></p>
};