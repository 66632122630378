import qccpLogo from '../img/qccp-logo.png';
import rsphLogo from '../img/rsph-logo.png';
import sectionImage1 from '../img/section-1.jpg';
import sectionImage2 from '../img/section-2.jpg';
import sectionImage3 from '../img/section-3.jpg';
import stripeLogoSlate from '../img/stripe-logo-slate.svg';
import stripeLogoWhite from '../img/stripe-logo-white.svg';

import assessmentGuideScreenshot1 from '../img/assessment-guide-1.png';
import assessmentGuideScreenshot2 from '../img/assessment-guide-2.png';
import assessmentGuideScreenshot3 from '../img/assessment-guide-3.png';
import assessmentGuideScreenshot4 from '../img/assessment-guide-4.png';
import assessmentGuideScreenshot5 from '../img/assessment-guide-5.png';
import assessmentGuideScreenshot6 from '../img/assessment-guide-6.png';

import examCompetency1 from '../pdfs/Competency Standard - Medical Aesthetics Certificate - Understanding the Safe Use of Botulinum Toxin in Cosmetic Procedures.pdf';
import examCompetency2 from '../pdfs/Competency Standard - Medical Aesthetics Certificate - Understanding the Safe Use of Dermal Fillers in Cosmetic Procedures.pdf';

import BotulinumToxinReadingList from '../pdfs/Botulinum_Toxin_Reading_List.zip';
import DermalFillersReadingList from '../pdfs/Dermal_Fillers_Reading_List.zip';

export const TITLE = 'QCCP';
export const QCCP_LOGO = qccpLogo;
export const RSPH_LOGO = rsphLogo;
export const SECTION_IMAGE_1 = sectionImage1;
export const SECTION_IMAGE_2 = sectionImage2;
export const SECTION_IMAGE_3 = sectionImage3;
export const STRIPE_LOGO_SLATE = stripeLogoSlate;
export const STRIPE_LOGO_WHITE = stripeLogoWhite;
export const COPYRIGHT = '© QCCP 2020';

export const ASSESSMENT_GUIDE_SCREENSHOTS = {
    1: assessmentGuideScreenshot1,
    2: assessmentGuideScreenshot2,
    3: assessmentGuideScreenshot3,
    4: assessmentGuideScreenshot4,
    5: assessmentGuideScreenshot5,
    6: assessmentGuideScreenshot6,
};

export const COMPETENCY_PDFS = {
    1: examCompetency1,
    2: examCompetency2,
};

export const READING_LIST_ZIPS = {
    bont1: BotulinumToxinReadingList,
    fillers1: DermalFillersReadingList,
};

export const COLORS = {
    primary:            { r: 7, g: 53, b: 91 },
    primaryContrast:    { r: 255, g: 255, b: 255 },
    secondary:          { r: 151, g: 217, b: 180 },
    text:               { r: 78, g: 81, b: 81 },
    backgroundColor:    { r: 255, g: 255, b: 255 },
    backgroundColorAlt: { r: 236, g: 236, b: 236 },
};

export const API_URL = 'https://api-saveface.joltrouter.net';

// export const STRIPE_KEY = 'pk_test_51GvMsvHcfLwEywigI7dEueHDcmyxW0vzoBnGu5Fdr5h0Pe0nSvobcu2wm1KQP90dH7vEAS6hh5WJmar3CfwA2C5e00IUE5bDK8';
export const STRIPE_KEY = 'pk_live_mpk7u8qtaHNUtveBF4eaQSi800dD7So9kY';

export const EXAMINATION_PASS_PERCENTAGE = 0.65;