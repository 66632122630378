import { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { API_URL } from '../config/constants.config';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import { examinationsAtom } from '../recoil/examinations.recoil';
import { DELAY_RESPONSE, EXAMINATIONS_FETCHING } from '../config/loading.config';

const ExaminationsSubscriber = () => {
    const setLoading = useSetRecoilState(loadingAtomFamily(EXAMINATIONS_FETCHING));
    const userToken = useRecoilValue(userTokenAtom);
    const setExaminations = useSetRecoilState(examinationsAtom);

    const getExaminations = async () => {
        setLoading(true);
        if (!userToken) {
            DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
            return;
        }
        const ref = await fetch(`${API_URL}/exams`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        if (ref.status === 200) {
            const json = await ref.json();
            setExaminations(json.exams);
        }
        DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
    };
    
    useEffect(() => {
        // console.log('%cExaminationsSubscriber', 'color: green;');
        getExaminations();
        // return () => console.log('%cExaminationsSubscriber', 'color: red;');
    }, [userToken]);
      
    return null;
};

export default ExaminationsSubscriber;