import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from '../styled/sections.styled';

export const List = styled.ul`
margin: 0 0 15px 0;
padding: 0;
list-style: none;
`;

export const ListItem = styled.li`
margin: 10px 0;
display: flex;
flex-direction: column;
`;

export const ListLink = styled(Link).withConfig({
    shouldForwardProp: prop => prop !== 'unavailable',
})`
min-height: calc(1.5em + 14px + 24px);
padding: 5px 15px;
text-decoration: none;
display: flex;
align-items: center;
${Container} & {
    ${props => props.theme.list}
}
${props => props.unavailable ? `
cursor: default;
pointer-events: none;
` : ``}
`;

export const ListDetails = styled.div`
padding-right: 10px;
display: flex;
flex-direction: row;
flex-grow: 1;
align-items: center;
`;

export const ListTitle = styled.h2`
margin: 0;
padding: 0;
`;

export const ListDates = styled.p`
margin: 0;
padding: 0;
${props => props.theme.dates}
${ListLink}:hover & {
    ${props => props.theme.datesHover}
}
`;

export const ListAvailabilityStatus = styled.div`
p {
    margin: 0;
    padding: 0;
}
`;

export const ListStatus = styled.div`
margin: 0;
padding: 0 10px;
display: flex;
align-items: center;
${props => props.status === 'passed' ? `
padding: 0 0 0 10px;
` : ``}
`;

export const ListScore = styled.span`
margin: 0;
padding: 0;
`;

export const ListProgress = styled.div`
margin: 0;
padding: 0 10px;
display: flex;
align-items: center;
span {
    padding: 0 10px 0 0;
    font-size: 1.4rem;
}
svg {
    font-size: 2.4rem;
}
${props => props.status === 'passed' ? `
padding: 0 0 0 10px;
` : ``}
`;

export const ListFetching = styled.p`
`;

export const ListActionIcon = styled.div`
margin: 0;
padding: 0 0 0 10px;
display: flex;
align-items: center;
svg {
    font-size: 2.4rem;
}
p {
    margin: 10px 0;
    padding: 0;
    display: flex;
    align-items: center;
    }
`;

export const ListAdditionalLink = styled(Link)`
margin-bottom: 1px;
padding: 5px 15px;
${Container} & {
    ${props => props.theme.additional}
}
`;