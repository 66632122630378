import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { examAtomFamily, questionsResponsesAtomFamily, getMcqTotalSelectorFamily, getMcqCompletionSelectorFamily, getExamResponseSelectorFamily } from '../recoil/exam.recoil';
import { InputCheckbox, InputRadioButton, FormError } from '../styled/forms.styled';
import { ExamSubmitButton } from '../styled/exam.styled';
import { McqSection, McqContent, McqQuestion, McqQuestionNum, McqQuestionText, McqResponses, McqResponse } from '../styled/mcq.styled';
import { McqControl } from '../components/forms.component';
import { Container, Article, Navigation, Buttons, Timer, ProgressBar } from './exam.content';
import useExamsData from '../hooks/useExamsData.hook';
import { LoadingIcon } from '../components/loading.component';
import { EXAM_UPDATING } from '../config/loading.config';

const Mcq = () => {
    return <Container>
        <Article>
            <Content />
        </Article>
        <Navigation>
            <Timer />
            <SectionProgress />
            <Buttons>
                <SubmitButton />
            </Buttons>
        </Navigation>
    </Container>
};

const Content = () => {
    // showOther    false
    // type         multichoice
    const { examId } = useParams();
    const exam = useRecoilValue(examAtomFamily(examId));
    if (exam)
        return <McqContent>
            {Object.entries(exam.questionSections).map(([section, questions], sectionIdx) => 
            <McqSection key={`ExamMcqSections/${sectionIdx}`}>
                <h3>{sectionIdx + 1}. {section}</h3>
                {Object.entries(questions).map(([id, props], questionIdx) => 
                    <Question key={`Exam/${examId}/Mcq/${questionIdx}`} section={section} id={id} questionIdx={questionIdx} {...props} />)}
            </McqSection>)}
        </McqContent>
    else
        return null;
};

const SectionProgress = () => {
    const { examId } = useParams();
    const total = useRecoilValue(getMcqTotalSelectorFamily(examId));
    const completion = useRecoilValue(getMcqCompletionSelectorFamily(examId));
    return <ProgressBar value={completion} total={total} />
};

const Question = ({ section, id, questionIdx, questionText, ...props }) => {
    const { examId } = useParams();
    const setResponses = useSetRecoilState(questionsResponsesAtomFamily(examId));

    useEffect(() => {
        setResponses(responses => {
            const current = responses[section] || {};
            return { ...responses, [section]: { ...current, [id]: [] } }
        });
    }, []);

    return <McqQuestion>
        <McqQuestionNum>Question {questionIdx + 1}</McqQuestionNum>
        <McqQuestionText>
            {parse(questionText)}
        </McqQuestionText>
        <Responses section={section} id={id} {...props} />
    </McqQuestion>
};

const Responses = ({ section, id, answers, ...props }) => {
    return <McqResponses>
        {answers.map((answer, idx) => 
            <Response 
                key={`Section/${section}/Question/${id}/Response/${idx}`} 
                id={id} 
                section={section} 
                idx={idx} 
                answer={answer} 
                {...props} />)}
    </McqResponses>
};

const Response = ({ section, id, idx, answer, hasMultipleCorrectAnswers }) => {
    const { examId } = useParams();
    const [ responses, setResponses ] = useRecoilState(questionsResponsesAtomFamily(examId));
    const response = responses[section] ? responses[section][id] : [];
    const checked = response.filter(_ => _ === idx).length ? true : false;
    const changeHandler = () => {
        if (!hasMultipleCorrectAnswers)
            setResponses(responses => ({ ...responses, [section]: { ...responses[section], [id]: [idx] } }));
        else
            if (checked)
                setResponses(responses => ({ ...responses, [section]: { ...responses[section], [id]: [...responses[section][id].filter(_ => _ !== idx)] } }));
            else
                setResponses(responses => ({ ...responses, [section]: { ...responses[section], [id]: [...responses[section][id], idx] } }));
    };
    return <McqResponse checked={checked}>
        {hasMultipleCorrectAnswers && <Checkbox onChange={changeHandler} checked={checked} name={`${section}:${id}`} label={answer} />}
        {!hasMultipleCorrectAnswers && <RadioButton onChange={changeHandler} checked={checked} name={`${section}:${id}`} label={answer} />}
    </McqResponse>
};

const Checkbox = ({ error, ...props }) => {
    return <>
      {error && <FormError>{error}</FormError>}
      <McqControl {...props}>
        <InputCheckbox type='checkbox' {...props} />
      </McqControl>
    </>
};
  
const RadioButton = ({ error, ...props }) => {
    return <>
      {error && <FormError>{error}</FormError>}
      <McqControl {...props}>
        <InputRadioButton type='radio' {...props} />
      </McqControl>
    </>
};
  
const SubmitButton = () => {
    const { examId, sectionId = 0 } = useParams();
    const total = useRecoilValue(getMcqTotalSelectorFamily(examId));
    const completion = useRecoilValue(getMcqCompletionSelectorFamily(examId));
    const disabled = completion !== total;
    const updating = useRecoilValue(loadingAtomFamily(EXAM_UPDATING));
    const responses = useRecoilValue(getExamResponseSelectorFamily(examId));
    const { setExam } = useExamsData();
    const clickHandler = e => {
        setExam(
            responses,
            `/examinations/exam/${examId}/${parseInt(sectionId) + 1}`
        );
    };
    if (!disabled)
        return <ExamSubmitButton disabled={updating} onClick={clickHandler}>
            <FontAwesomeIcon icon={faArrowCircleRight} /> 
            <span>{!updating ? 'Submit' : <LoadingIcon align='right'>Submit</LoadingIcon>}</span>
        </ExamSubmitButton>
    else
        return null;
};

export default Mcq;