import { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { API_URL } from '../config/constants.config';
import { loadingAtomFamily } from '../recoil/loading.recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import { assessmentsAtom } from '../recoil/assessments.recoil';
import { DELAY_RESPONSE, ASSESSMENTS_FETCHING } from '../config/loading.config';

const AssessmentsSubscriber = () => {
    const setLoading = useSetRecoilState(loadingAtomFamily(ASSESSMENTS_FETCHING));
    const userToken = useRecoilValue(userTokenAtom);
    const setAssessments = useSetRecoilState(assessmentsAtom);

    const getAssessments = async () => {
        setLoading(true);
        if (!userToken) {
            DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
            return;
        }
        const ref = await fetch(`${API_URL}/assessments`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        if (ref.status === 200) {
            const json = await ref.json();
            setAssessments(json.assessments);
        }
        DELAY_RESPONSE ? setTimeout(() => setLoading(false), 1000) : setLoading(false);
    };
    
    useEffect(() => {
        // console.log('%cAssessmentsSubscriber', 'color: green;');
        getAssessments();
        // return () => console.log('%cAssessmentsSubscriber', 'color: red;');
    }, [userToken]);
      
    return null;
};

export default AssessmentsSubscriber;