import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import { examinationsAtom, getExaminationSelectorFamily } from '../recoil/examinations.recoil';
import { getAssessmentByExamIdSelectorFamily } from '../recoil/assessments.recoil';
import useStatusData from '../hooks/useStatusData.hook';

export const StatusSubscriber = ({ examId }) => {
    const userToken = useRecoilValue(userTokenAtom);
    const examination = useRecoilValue(getExaminationSelectorFamily(examId));
    const assessment = useRecoilValue(getAssessmentByExamIdSelectorFamily(examId));
    const { getStatus } = useStatusData(examId);

    // get assessment progress
    // add to dependencies

    useEffect(() => {
        if (examId && userToken) {
            // console.log('%cStatusSubscriber', 'color: green;', examId);
            getStatus();
            // return () => console.log('%cStatusSubscriber', 'color: red;', examId);
        }
    }, [examId, userToken, examination, assessment]);
      
    return null;
};

const Subscribers = () => {
    return useRecoilValue(examinationsAtom).map(({ id }) => 
        <StatusSubscriber key={`StatusSubscriber/${id}`} examId={id} />);
};

export default Subscribers;