import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { assessmentsAtom, getAssessmentSelectorFamily } from '../recoil/assessments.recoil';
import { userTokenAtom } from '../recoil/user.recoil';
import useProgressData from '../hooks/useProgressData.hook';

export const ProgressSubscriber = ({ assessmentId }) => {
    const userToken = useRecoilValue(userTokenAtom);
    const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
    const { getProgress } = useProgressData(assessmentId);

    useEffect(() => {
        if (assessmentId && userToken && assessment) {
            // console.log('%cProgressSubscriber', 'color: green;', assessmentId);
            getProgress();
            // return () => console.log('%cProgressSubscriber', 'color: red;', assessmentId);
        }
    }, [assessmentId, userToken, assessment]);
      
    return null;
};

const Subscribers = () => {
    const assessments = useRecoilValue(assessmentsAtom);
    if (assessments)
        return assessments.map(({ id }) => 
            <ProgressSubscriber key={`ProgressSubscriber/${id}`} assessmentId={id} />)
    else
        return null;
};

export default Subscribers;