import { selector, atom } from 'recoil';
import { userIdAtom, userTokenAtom, userDataAtom } from './user.recoil';
import { assessmentsAtom, progressAtomFamily, sectionsAtomFamily } from './assessments.recoil';
import { examinationsAtom, statusAtomFamily } from './examinations.recoil';

export const loggingAtom = atom({
    key: 'logging',
    default: false,
});

export const logAtom = selector({
    key: 'log',
    get: ({ get }) => {
        const userId = get(userIdAtom);
        const userToken = get(userTokenAtom);
        const userData = get(userDataAtom);
        const assessments = get(assessmentsAtom);
        const examinations = get(examinationsAtom);
        const sections = [];
        const progress = [];
        const status = [];
        for (let assessment of assessments) {
            sections.push(get(sectionsAtomFamily(assessment.id)));
            progress.push(get(progressAtomFamily(assessment.id)));
        }
        for (let examination of examinations)
            status.push(get(statusAtomFamily(examination.id)));
        return { userId, userToken, userData, assessments, examinations, sections, progress, status };
    },
});