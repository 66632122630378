import { useEffect } from 'react';
import useUserData from '../hooks/useUserData.hook';

const REFRESH_INTERVAL = 30 * 60 * 1000;

const UserSubscriber = () => {
    const { getUserData, refreshUserData } = useUserData();

    useEffect(() => {
        // console.log('%cUserSubscriber', 'color: green;');
        getUserData();
        // return () => console.log('%cUserSubscriber', 'color: red;');

        const interval = setInterval(() => refreshUserData(), REFRESH_INTERVAL);
        return () => clearInterval(interval);
    }, []);
      
    return null;
};

export default UserSubscriber;