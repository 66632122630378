import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { examAtomFamily, getEssayTotalSelectorFamily, getEssayCompletionSelectorFamily, getEssayWordsSelectorFamily, selectedWordAtomFamily, searchWordAtomFamily, placeholderResponsesAtomFamily, examHelpAtom } from '../recoil/exam.recoil';
import { ExamSubmitButton } from '../styled/exam.styled';
import { EssayPlaceholder, EssayWords, EssayWordsSearch, EssayWord } from '../styled/essay.styled';
import { Container, Article, Navigation, Buttons, Timer, ProgressBar } from './exam.content';

const Essay = () => {
    return <Container>
        <Article>
            <Content />
            <Help />
        </Article>
        <Navigation>
            <Timer />
            <SectionProgress />
            <Words />
            <Buttons>
                <SubmitButton />
                <HelpButton />
            </Buttons>
        </Navigation>
    </Container>
};

const Content = () => {
    const { examId, sectionId = 0 } = useParams();
    const exam = useRecoilValue(examAtomFamily(examId));
    const helpVisible = useRecoilValue(examHelpAtom);
    const style = { display: !helpVisible ? 'block' : 'none', userSelect: 'none' };
    if (exam)
        return <div style={style}>
            {parse(exam.contents[sectionId - 1], {
                replace: domNode => {
                    if (domNode.name === 'placeholder')
                        return <Placeholder placeholderId={domNode.attribs.placeholderid}  />
                },
            })}
        </div>
    else
        return null;
};

const Help = () => {
    const [ helpVisible, setHelpVisible ] = useRecoilState(examHelpAtom);
    const clickHandler = e => {
        e.preventDefault();
        window.scrollTo(0, 0);
        setHelpVisible(false);
    };
    if (helpVisible)
        return <div>
            <h2>Help</h2>
            <p>A number of words in the essay have been replaced with placeholders. Choose the most appropriate words from the available selection to fill the placeholders.</p>
            <p>Click on a word to select it, then click on a placeholder to fill it. Click a filled placeholder to remove the word, or select another from the selection and click the placeholder.</p>
            <p>You must fill all placeholders to complete the section.</p>
            <p><ExamSubmitButton onClick={clickHandler}>
                <FontAwesomeIcon icon={faArrowCircleRight} /> 
                <span>Continue</span>
            </ExamSubmitButton></p>
        </div>
    else
        return null;
};

const SectionProgress = () => {
    const { examId, sectionId = 0 } = useParams();
    const total = useRecoilValue(getEssayTotalSelectorFamily({ examId, sectionId }));
    const completion = useRecoilValue(getEssayCompletionSelectorFamily({ examId, sectionId }));
    return <ProgressBar value={completion} total={total} />
};

const Words = () => {
    const { examId, sectionId = 0 } = useParams();
    const words = useRecoilValue(getEssayWordsSelectorFamily({ examId, sectionId }));
    const search = useRecoilValue(searchWordAtomFamily(examId));
    return <EssayWords>
        <WordsSearch />
        {words.filter(word => !Array.isArray(word)).map((word, idx) => 
            word.toLowerCase().startsWith(search)
                ? <Word key={`Exam/${examId}/Essay/${sectionId}/Word/${idx}`} word={word} />
                : null)}
    </EssayWords>
};

const WordsSearch = () => {
    const { examId, sectionId = 0 } = useParams();
    const [ search, setSearch ] = useRecoilState(searchWordAtomFamily(examId));
    const changeHandler = e => setSearch(e.target.value.toLowerCase());
    return <EssayWordsSearch type='search' placeholder='Search words...' value={search} onChange={changeHandler} />
};

const Word = ({ word }) => {
    const { examId, sectionId = 0 } = useParams();
    const [ selected, setSelected ] = useRecoilState(selectedWordAtomFamily(`${examId}:${sectionId}`));
    const responses = useRecoilValue(placeholderResponsesAtomFamily(`${examId}:${sectionId}`));
    const inUse = Object.values(responses).filter(_ => _ === word).length ? true : false;
    const clickHandler = () => selected === word ? setSelected(null) : setSelected(word);
    if (!inUse)
        return <EssayWord onClick={clickHandler} selected={selected === word}>{word}</EssayWord>
    else
        return null;
};

const Placeholder = ({ placeholderId }) => {
    const { examId, sectionId = 0 } = useParams();
    const [ selected, setSelected ] = useRecoilState(selectedWordAtomFamily(`${examId}:${sectionId}`));
    const setSearch = useSetRecoilState(searchWordAtomFamily(examId));
    const [ responses, setResponses ] = useRecoilState(placeholderResponsesAtomFamily(`${examId}:${sectionId}`));
    const response = responses[placeholderId];
    const clickHandler = () => {
        if (response) {
            if (!selected) {
                setResponses(responses => ({ ...responses, [placeholderId]: null }));
            } else {
                setSelected(null);
                setSearch('');
                setResponses(responses => ({ ...responses, [placeholderId]: selected }));
            }
        } else {
            if (!selected) return;
            setSelected(null);
            setSearch('');
            setResponses(responses => ({ ...responses, [placeholderId]: selected }));
        }
    };
    return <EssayPlaceholder data-placeholderId={placeholderId} placeholderId={placeholderId} droppable={selected !== null} response={response ? true : false} onClick={clickHandler}>{response ? response : 'placeholder'}</EssayPlaceholder>
};

const SubmitButton = () => {
    const history = useHistory();
    const { examId, sectionId } = useParams();
    const setSearch = useSetRecoilState(searchWordAtomFamily(examId));
    const total = useRecoilValue(getEssayTotalSelectorFamily({ examId, sectionId }));
    const completion = useRecoilValue(getEssayCompletionSelectorFamily({ examId, sectionId }));
    const disabled = completion !== total;
    const clickHandler = e => {
        setSearch('');
        window.scrollTo(0, 0);
        history.push(`/examinations/exam/${examId}/${parseInt(sectionId) + 1}`);
    };
    if (!disabled)
        return <ExamSubmitButton onClick={clickHandler}>
            <FontAwesomeIcon icon={faArrowCircleRight} /> 
            <span>Submit</span>
        </ExamSubmitButton>
    else
        return null;
};

const HelpButton = () => {
    const { examId, sectionId } = useParams();
    const [ helpVisible, setHelpVisible ] = useRecoilState(examHelpAtom);
    const total = useRecoilValue(getEssayTotalSelectorFamily({ examId, sectionId }));
    const completion = useRecoilValue(getEssayCompletionSelectorFamily({ examId, sectionId }));
    const disabled = completion >= total;
    const clickHandler = e => {
        e.preventDefault();
        window.scrollTo(0, 0);
        setHelpVisible(true);
    };
    if (!disabled && !helpVisible)
        return <>
            <ExamSubmitButton onClick={clickHandler}>
                <FontAwesomeIcon icon={faQuestionCircle} /> 
            </ExamSubmitButton>
        </>
    else
        return null;
};

export default Essay;