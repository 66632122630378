import React from 'react';
import { ThemeProvider } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faList, faAward } from '@fortawesome/free-solid-svg-icons';
import { defaultTheme, assessmentTheme, examinationTheme, Container } from '../styled/sections.styled';
import { PageHeading, PageContent } from '../styled/global.styled';
import { LoggingControls } from '../components/logging.component';

const Heading = ({ icon, children }) => {
    return <>
        <LoggingControls />
        <PageHeading><FontAwesomeIcon icon={icon} /> {children}</PageHeading>
    </>
};

export const AssessmentsSection = ({ children }) => {
    return <ThemeProvider theme={assessmentTheme}>
        <Container>
            <Heading icon={faUser}>Self-Assessments</Heading>
            <PageContent>
                {children}
            </PageContent>
        </Container>
    </ThemeProvider>
};

export const ExaminationsSection = ({ children }) => {
    return <ThemeProvider theme={examinationTheme}>
        <Container>
            <Heading icon={faList}>Examinations</Heading>
            <PageContent>
                {children}
            </PageContent>
        </Container>
    </ThemeProvider>
};

export const SubmissionsSection = ({ children }) => {
    return <ThemeProvider theme={defaultTheme}>
        <Container>
            <Heading icon={faAward}>Submissions</Heading>
            <PageContent>
                {children}
            </PageContent>
        </Container>
    </ThemeProvider>
};