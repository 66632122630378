import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { loggingAtom, logAtom } from '../recoil/logging.recoil';
import { getAssessmentSelectorFamily, progressAtomFamily } from '../recoil/assessments.recoil';
import { examAtomFamily, placeholderResponsesAtomFamily, questionsResponsesAtomFamily } from '../recoil/exam.recoil';
import useProgressData from '../hooks/useProgressData.hook';

export const LoggingControls = () => {
    const style = { position: 'absolute', top: '5px', right: '5px', display: 'flex', flexDirection: 'column' };
    if (useRecoilValue(loggingAtom))
    return <div style={style}>
            <ClearLogButton />
            <DumpStateButton />
            <DumpExamButton />
            <CheatAssessment />
            <CheatExamFail />
            <CheatExamPass />
        </div>
    else
        return null;
};

export const ClearLogButton = () => {
    const style = { cursor: 'pointer' };
    return <button style={style} onClick={() => console.clear()}>clear console</button>
};

export const DumpStateButton = () => {
    const log = useRecoilValue(logAtom);
    const style = { cursor: 'pointer' };
    const clickHandler = () => {
        console.group('DumpState');
        Object.entries(log).map(([key, val]) => console.log(key, val));
        console.groupEnd();
    };
    return <button style={style} onClick={clickHandler}>dump state to console</button>
};

export const DumpExamButton = () => {
    const { examId } = useParams();
    const exam = useRecoilValue(examAtomFamily(examId));
    const placeholder1 = useRecoilValue(placeholderResponsesAtomFamily(`${examId}:1`));
    const placeholder2 = useRecoilValue(placeholderResponsesAtomFamily(`${examId}:2`));
    const placeholder3 = useRecoilValue(placeholderResponsesAtomFamily(`${examId}:3`));
    const placeholderResponses = [placeholder1, placeholder2, placeholder3];
    const questionsResponses = useRecoilValue(questionsResponsesAtomFamily(examId));
    const response = { placeholderResponses, questionsResponses };

    const style = { cursor: 'pointer' };
  
    const clickHandler = () => {
        console.group('DumpExam');
        console.log('exam', exam);
        console.log('response', response);
        console.groupEnd();
    };
    return <button style={style} onClick={clickHandler}>dump exam to console</button>
};

export const CheatAssessment = () => {
    const { assessmentId } = useParams();
    const assessment = useRecoilValue(getAssessmentSelectorFamily(assessmentId));
    const progress = useRecoilValue(progressAtomFamily(assessmentId));
    const { setProgress } = useProgressData(assessmentId);

    const style = { cursor: 'pointer' };

    const clickHandler = () => {
        setProgress(
            new Array(assessment.questionCount).fill('yes'), 
            `/self-assessments/assessment/${assessmentId}/${assessment.questionCount + 1}`
        );
    };
    if (assessmentId && progress && progress.status !== 'passed')
        return <button style={style} onClick={clickHandler}>cheat assessment</button>
    else
        return null;
};

export const CheatExamFail = () => {
    const { examId, sectionId = 0 } = useParams();
    const exam = useRecoilValue(examAtomFamily(examId));
    const [ placeholders, setPlaceholders ] = useRecoilState(placeholderResponsesAtomFamily(`${examId}:${sectionId}`));
    const [ questions, setQuestions ] = useRecoilState(questionsResponsesAtomFamily(examId));

    const style = { cursor: 'pointer' };

    const clickHandler = () => {
        if (exam && parseInt(sectionId) >= 1 && parseInt(sectionId) <= exam.contents.length) {
            const words = exam.placeholderSelections[sectionId - 1];
            const responses = [...document.body.querySelectorAll('span[data-placeholderId]')].map(placeholder => placeholder.getAttribute('data-placeholderId')).reduce((responses, key, idx) => {
                responses[key] = words[idx];
                return responses;
            }, {});
            setPlaceholders(responses);
        }
        else if (exam && parseInt(sectionId) === exam.contents.length + 1) {
            const responses = Object.entries(questions).reduce((responses, [key, val]) => {
                responses[key] = Object.keys(val).reduce((response, id) => {
                    response[id] = [0];
                    return response;
                }, {});
                return responses;
            }, {});
            setQuestions(responses);
        }
    };
    if (examId)
        return <button style={style} onClick={clickHandler}>cheat exam (fail)</button>
    else
        return null;
};

export const CheatExamPass = () => {
    const { examId, sectionId = 0 } = useParams();
    const exam = useRecoilValue(examAtomFamily(examId));
    const [ placeholders, setPlaceholders ] = useRecoilState(placeholderResponsesAtomFamily(`${examId}:${sectionId}`));
    const [ questions, setQuestions ] = useRecoilState(questionsResponsesAtomFamily(examId));

    const style = { cursor: 'pointer' };

    const clickHandler = () => {
        if (exam && parseInt(sectionId) >= 1 && parseInt(sectionId) <= exam.contents.length) {
            const answers = exam.__placeholderAnswers[sectionId - 1];
            const responses = [...document.body.querySelectorAll('span[data-placeholderId]')].map(placeholder => placeholder.getAttribute('data-placeholderId')).reduce((responses, key, idx) => {
                responses[key] = answers[key];
                return responses;
            }, {});
            setPlaceholders(responses);
        }
        else if (exam && parseInt(sectionId) === exam.contents.length + 1) {
            const responses = Object.entries(exam.questionSections).reduce((output, [sectionTitle, sectionObj], sectionIdx) => {
                output[sectionTitle] = Object.entries(sectionObj).reduce((output, [questionTitle]) => {
                    const correctAnswers = exam.__mcqAnswers[sectionIdx][questionTitle];
                    const answers = exam.questionSections[sectionTitle][questionTitle].answers;
                    const answerIdxs = correctAnswers.reduce((arr, correctAnswer) => {
                        arr.push(answers.findIndex(answer => answer === correctAnswer));
                        return arr;
                    }, []);
                    output[questionTitle] = answerIdxs;
                    return output;
                }, {});
                return output;
            }, {});
            setQuestions(responses);
        }
    };
    if (examId)
        return <button style={style} onClick={clickHandler}>cheat exam (pass)</button>
    else
        return null;
};