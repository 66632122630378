export const toDateTime = secs => {
    var t = new Date(1970, 0, 1);
    t.setSeconds(secs);
    return t.toDateString();
};

export const formatDate = string => {
    if (string === null)
        return null;
    return new Date(string).toDateString();
};

export const convertMilliseconds = milliseconds => {
    let days, hours, minutes, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    days = Math.floor(hours / 24);
    hours = hours % 24;
    return { days, hours, minutes, seconds };
};